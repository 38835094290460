<template>
  <div class="container w-80" style="margin-top: 80px;">
    <div class="top">
      <h6>Messages envoyés</h6>
      <div class="message-controls">


      </div>
    </div>




    <ul class="message-list mt-3">
      <li v-for="message in messages" :key="message.id" class="message-item" @mouseover="showActions(message.id)"
        @mouseleave="hideActions(message.id)">
        <div class="message-content">

          <span class="message-subject">{{ message.subject }}</span>
          <span class="message-preview">{{ message.preview }}</span>
        </div>
        <div class="message-date" v-show="!message.showActions">
          {{ message.date }}
        </div>
        <div class="message-actions" v-show="message.showActions">
           
           
            <i data-bs-toggle="modal" data-bs-target="#exampleModal34" class="action-btn fas fa-eye"></i>
           
           
        </div>
      </li>
    </ul>
  </div>


  <!-- Modal -->
  <div class="modal fade" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header border-0">
            <h5 class="modal-title" id="exampleModalLabel"></h5>

          </div>
          <div class="modal-body">
            <h6>Destinataire</h6>
            <p id="text-modal-objet">
              ---------
            </p>
            <h6>Message</h6>
            <p id="text-modal">
              Ex turba vero imae sortis et paupertinae in tabernis aliqui pernoctant vinariis,
              non nulli velariis umbraculorum theatralium latent, quae Campanam imitatus lasciviam
              Catulus in aedilitate sua suspendit omnium primus; aut pugnaciter aleis certant turpi
              sono fragosis naribus introrsum reducto spiritu concrepantes; aut quod est studiorum
              omnium maximum ab ortu lucis ad vesperam sole fatiscunt vel
              pluviis, per minutias aurigarum equorumque praecipua vel delicta scrutantes.
            </p>

            <div class="document" style="margin-top: 40px;">
              <span>Pièce jointe :</span> <a id="docs" href="">Document.pdf</a>
            </div>

          </div>
          <div class="modal-footer  border-0">

            <button type="button" class="btn btn-danger " data-bs-dismiss="modal">{{ $t("button.close") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListMessage_Vue",
  data() {
    return {
      messages: [
        {
          id: 1,
          subject: "Réunion demain",
          preview: "Bonjour, n'oubliez pas la réunion...",
          date: "28 juin",
          showActions: false,
        },
        {
          id: 2,
          subject: "Rapport mensuel",
          preview: "Ci-joint le rapport du mois...",
          date: "27 juin",
          showActions: false,
        },
        {
          id: 3,
          subject: "Invitation",
          preview: "Vous êtes cordialement invité à...",
          date: "26 juin",
          showActions: false,
        },
      ],
      selectedMessages: [],
    };
  },
  methods: {
    showActions(id) {
      const message = this.messages.find((m) => m.id === id);
      if (message) {
        message.showActions = true;
      }
    },
    hideActions(id) {
      const message = this.messages.find((m) => m.id === id);
      if (message) {
        message.showActions = false;
      }
    },
    deleteMessage(id) {
      console.log("Supprimer le message", id);
      this.messages = this.messages.filter((m) => m.id !== id);
    },
    viewDetails(id) {
      console.log("Voir les détails du message", id);
    },
    resendMessage(id) {
      console.log("Renvoyer le message", id);
    },
    deleteSelectedMessages() {
      this.selectedMessages.forEach((id) => {
        this.deleteMessage(id);
      });
      this.selectedMessages = [];
    },
  },
};
</script>

<style scoped>
.top {
  display: flex;
  justify-content: space-between;
}

.top h5 {
  transform: translateY(15px);
}

.message-controls {
  margin-bottom: 20px;
}

.new-message-btn,
.delete-selected-btn {
  background-color: #fff;
  color: #5f6368;
  border: 1px solid #dadce0;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

 

.message-list {
  list-style-type: none;
  padding: 0;
}

.message-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid rgba(250, 250, 250, 0.2);
  transition: background-color 0.3s;
}

.message-content {
  display: flex;
  flex-grow: 1;
  gap: 20px;
}

.select-message {
  margin-right: 10px;
}

.message-subject {
  width: 300px;
  color: #ffffff;
  opacity: 0.8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-preview {
  color: #ffffff;
  opacity: 0.8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.message-date {
  color: #ffffff;
  opacity: 0.8;
  width: 80px;
  text-align: right;
}

.message-actions {
  display: flex;

  width: 80px;
  justify-content: flex-end;
}

.action-btn {
  background: none;
  border: none;
  color: green;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  transition: color 0.3s;
}

.action-btn:hover {
  color: green;
}

#text-modal {
  border: 1px solid rgba(250, 250, 250, 0.6);
  padding: 15px 10px;
  border-radius: 10px;
  text-align: justify;
  overflow-y: scroll;
  max-height: 400px;

}

#text-modal-objet {
  border: 1px solid rgba(250, 250, 250, 0.6);
  padding: 15px 10px;
  border-radius: 10px;
  text-align: justify;
  margin-bottom: 40px;


}

#docs {
  border: 1px solid rgba(250, 250, 250, 0.6);
  padding: 10px 15px;

}
</style>