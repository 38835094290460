<template>
  <div class="p-5 container-fluid" style="margin-bottom: 120px">
    <div class="description">
      <div class="student-form-title">
        <h4>{{ $t("updatepayment.group") }} {{ configdetails.grade_code }}</h4>
      </div>
      <div class="student-form">
        <form class="form col-xs-7 col-md-6 col-lg-7">
          <div class="mb-4" v-if="GRADE_CODE.length">
            <p class="small">{{ $t("updatepayment.grade") }}</p>
            <select class="form-control form-select" v-model="configdetails.grade_code" style="height: 50px">
              <option v-for="item in GRADE_CODE" :key="item.code" :value="item.name">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="mb-4" v-if="SPECIALITY_CODE.length">
            <p class="small">{{ $t("updatepayment.speciality") }}</p>
            <select class="form-control form-select" v-model="configdetails.speciality_code" style="height: 50px">
              <option v-for="item in SPECIALITY_CODE" :key="item.code" :value="item.short_name">
                {{ item.short_name }} - {{ item.title }}
              </option>
              <option> {{ $t("updatepayment.no_speciality") }}</option>
            </select>
          </div>

          <div class="container" style="margin-bottom: 100px">
            <div class="installment">
              <div class="row">
                <form class="form col-md-7 mt-6" v-for="(installment, index) in installments" :key="index">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label>
                          {{ $t("account.installment_number") }}
                          <em style="color: #f60000">*</em>
                        </label>
                        <argon-input :placeholder="$t('account.installment_number')" is-required
                          v-model="installment.number"></argon-input>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label>
                          {{ $t("account.installment_amount") }}
                          <em style="color: #f60000">*</em>
                        </label>
                        <argon-input :placeholder="$t('account.installment_amount')" is-required
                          v-model="installment.amount"></argon-input>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label>
                          {{ $t("account.due_date") }}
                          <em style="color: #f60000">*</em>
                        </label>
                        <argon-input is-required type="date" v-model="installment.due_date"></argon-input>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div>
                        <label>
                          {{ $t("account.installment_label") }}
                          <em style="color: #f60000">*</em>
                        </label>
                        <textarea class="form-control row-3" v-model="installment.label" rows="3"
                          :placeholder="$t('account.installment_label')"></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="btn border border-danger detail" title="Supprimer l'installment" v-if="index !== 0"
                    @click="removeInstallment(index)">
                    <i class="i fa fa-times"></i>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="row p-1 mt-5 g-3">
             
            <div class="col-lg-6">
              <argon-button data-bs-toggle="modal" data-bs-target="#staticBackdrop4" size="lg" color="success"
                full-width>
                <span>{{ $t("button.save") }}</span>
              </argon-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!--========================= modal modify =================================== -->

  <div class="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel4" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
            <h5 class="modal-title" id="staticBackdropLabel4"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center pt-4 pb-4">
            <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
            <h6> {{ $t("updatepayment.modal_title") }}</h6>
            <table class="table " style="
             width: 60%;
             margin:50px auto;
            " >
              <thead>
                <tr>
                  <th style="border: 1px solid rgba(255, 255, 255, 0.4)" scope="col">{{ $t("updatepayment.grade") }} </th>
                  <th style="border: 1px solid rgba(255, 255, 255, 0.4)" scope="col">{{ $t("updatepayment.speciality") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>

                  <td style="border: 1px solid rgba(255, 255, 255, 0.4)">{{this.grade_code}} </td>
                  <td style="border: 1px solid rgba(255, 255, 255, 0.4)">{{this.speciality_code}}</td>

                </tr>
                


              </tbody>
            </table>


          </div>
          <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
            <argon-button type="button"   data-bs-dismiss="modal" class="bg-transparent"
              style="width: 150px; height: 50px; padding-top: 15px; border:1px solid rgba(255, 255, 255, 0.4)">
              {{ $t("button.cancel") }}
            </argon-button>

            <argon-button :loading="loading" :active="buttonActive || showConfirmation" class="btn mt-1 btn-danger"  type="button"
               style="width: 150px; height: 50px; padding-top: 15px" @click="updateConfig">
              {{ $t("button.confirm") }}
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
import {
  updateConfig,
  getGradeCode,
  getSpeciality,
  getconfig,
} from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "ConfigForm",
  components: { ArgonButton },
  data() {
    return {
      speciality_code: "",
      grade_code: "",
      loading: false,
      GRADE_CODE: [],
      SPECIALITY_CODE: [],
      configData: null,
      installments: [],
      buttonActive: false,
      showConfirmation: false,
      configdetails: {
        grade_code: "",
        speciality_code: "",
      },
    };
  },

  methods: {
    gotogroup() {
      this.$router.push({
        path: "/payment-config",
        query: { forceReload: true },
      });
    },

    resetForm() {
      this.grade_code = this.configData.grade_code;
      this.speciality_code = this.configData.speciality_code;
    },

    async loadConfigDetails() {
      try {
        const configId = this.$route.params.id;
        const configdetails = await getconfig(configId);

        this.configdetails = {
          id: configdetails.id,
          grade_code: configdetails.grade_code,
          speciality_code: configdetails.speciality_code,
        };
      } catch (error) {
        console.error(
          "Erreur lors du chargement des détails de la config:",
          error
        );
      }
    },

    async getData() {
      try {
        let grades = await getGradeCode();
        this.GRADE_CODE = grades.data;
        let specialities = await getSpeciality();
        this.SPECIALITY_CODE = specialities.data;

        if (this.configData) {
          this.grade_code = this.configData.grade_code;
          this.speciality_code = this.configData.speciality_code;
        } else {
          this.grade_code =
            this.GRADE_CODE.length > 0 ? this.GRADE_CODE[0].name : "";
          this.speciality_code =
            this.SPECIALITY_CODE.length > 0
              ? this.SPECIALITY_CODE[0].short_name
              : "";
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async updateConfig() {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true; // Activer le chargement
        this.button = "disabled";
        this.buttonActive = true;

        const configId = this.configdetails.id;
        const data = {
          grade_code: this.configdetails.grade_code,
          speciality_code: this.configdetails.speciality_code,
        };

        let res = await updateConfig(configId, data);



        if (!navigator.onLine) {
          const message = this.$t("message.notinternet");
          toast.error(message, {
            autoClose: 3000,
          });
        } else if (res.status === 200) {
          if (!this.showConfirmation) {
            this.showConfirmation = true;
            const message = this.$t("updatepayment.message");
            toast.success(message, {
              autoClose: 3000,
            });

            document.body.classList.remove("modal-open");
            const modalBackdrops =
              document.getElementsByClassName("modal-backdrop");
            for (const backdrop of modalBackdrops) {
              backdrop.parentNode.removeChild(backdrop);
            }

            setTimeout(() => {
              this.showConfirmation = false;
              this.$router.push({
                path: "/payment-config",
                query: { forceReload: true },
              });
            }, 4000);
          }
        } else {
          // Handle other status codes or errors
          // ...
        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          toast.error("User not found. Please check the provided information", {
            autoClose: 3000,
          });
        } else {
          toast.error("An error occurred during configuration update", {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
        this.buttonActive = false;
      }
    },
  },

  created() {
    this.confiId = this.$route.params.id;
    
    this.loadConfigDetails();
  },

  beforeMount() {
    const configDataParam = this.$route.query.configData;
    if (configDataParam) {
      try {
        this.configData = JSON.parse(configDataParam);
        this.getInstallments(this.configData.id);
      } catch (error) {
        console.error("Error parsing configData:", error);
      }
    }
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.student-form {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

.student-form-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  color: #dc3545;
  font-size: 14px;
  margin-left: 5px;
}

.required {
  border-color: #f60000 !important;
}
</style>
