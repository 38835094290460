<template>
  <div class="container-fluid" style="margin-top:80px;">
    <!-- Modal -->
    <div class="modal fade" id="exampleModal40" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="card">
            <div class="modal-header" style="border-bottom: 1px solid rgba(250,250, 250, 0.4);">
              <h5 class="modal-title" id="exampleModalLabel">Api key</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="api-key mt-5 mb-5 d-flex align-items-center mx-auto" style="width: max-content;">
                <p class="mx-2 py-2 px-3" style="border: 1px solid rgba(250, 250, 250, 0.6);">{{ apiKey }}</p>
                <button @click="copyToClipboard" class="btn "
                  style="background: #75acff; height: 45px; color: white; border-radius: 0; ">
                  Copier
                </button>
              </div>
            </div>
            <div class="modal-footer" style="border-top: 1px solid rgba(250,250, 250, 0.5);">
              <argon-button type="button" class="btn" color="danger" variant="gradient" data-bs-dismiss="modal"
                style="width: 150px; height: 50px; padding-top: 15px">
                {{ $t("button.close") }}
              </argon-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-api mt-4 mb-6  px-3"
      style="border: 1px solid rgba(250,250, 250, 0.5); border-radius: 5px; background: #111c44;">
      <div class="card-api-content d-flex justify-content-between">
        <div class=" d-flex mt-3 pt-1 ">
          <div class="key pe-2">
            <i style="color: #75acff" class="fs-4 mt-3 fa fa-key"></i>
          </div>
          <div class="text mx-3">
            <h6 style="font-size: 14px;">Access grades using API</h6>
            <p style="font-size: 14px;">From your school software, you can access grades submited with Eska, using the
              Api key</p>
            <p style="font-size: 14px; margin-top: -10px;"> <a style="color:#4f61c4 !important;" target="_blank"
                href="#"> See documentation</a></p>
          </div>
        </div>
        <div class="button" style="align-items: center">
          <argon-button data-bs-toggle="modal" data-bs-target="#exampleModal40" color="success" size="xl"
            style="background: #75acff; width: 150px; margin-top: 20px;">
            Show key
          </argon-button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="filters">
        <div class="dropdown">
          <button class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ selectedClass || 'Classes' }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li v-for="classItem in uniqueClasses" :key="classItem" @click="selectClass(classItem)">
              <a class="dropdown-item" href="#">{{ classItem }}</a>
            </li>
          </ul>
        </div>

        <div class="dropdown mx-3">
          <button class="dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ selectedSubject || 'Matières' }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
            <li v-for="subject in uniqueSubjects" :key="subject" @click="selectSubject(subject)">
              <a class="dropdown-item" href="#">{{ subject }}</a>
            </li>
          </ul>
        </div>

        <div class="dropdown mx-3">
          <button class="dropdown-toggle" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ selectedSequence || 'Séquences' }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
            <li v-for="sequence in uniqueSequences" :key="sequence" @click="selectSequence(sequence)">
              <a class="dropdown-item" href="#">{{ sequence }}</a>
            </li>
          </ul>
        </div>
        <button title="Exporter les notes en fichier Excel" type="button" class="btn text-light" style="height: 45px; margin-top: -10px; border-radius: 5px; background: #75acff;">
          <i class="fas fa-download"></i>  
        </button>
      </div>
    </div>

    <div class="student_number">
      <p>Nombre d'élève : 40 000</p>
    </div>

    <div class="card mt-5 pb-8">
      <!-- Tableau des notes -->
      <table class="students-table mt-7">
        <thead>
          <tr>
            <th class="ps-4 pb-2">Classe</th>
            <th class="ps-4 pb-2">Matricule</th>

            <th class="ps-4 pb-2">Matière</th>
            <th class="ps-4 pb-2">Séquence</th>
            <th class="ps-4 pb-2">Nom</th>
            <th class="text-end pe-4">Note</th>
          </tr>
        </thead>
        <tbody>
          <tr style="border-bottom: 1px solid rgba(250, 250, 250, 0.6);" v-for="(student, index) in filteredStudents"
            :key="student.id" :class="{ odd: index % 2 !== 0, even: index % 2 === 0 }">
            <td class="ps-4 opacity-25">{{ student.class }}</td>
            <td class="ps-4 opacity-25">152NHR</td>

            <td class="ps-4 opacity-25">{{ selectedSubject || 'N/A' }}</td>
            <td class="ps-4 opacity-25">{{ selectedSequence || 'N/A' }}</td>
            <td class="ps-4 opacity-25">{{ student.name }}</td>
            <td class="text-end pe-4 opacity-25">
              {{ getGrade(student, selectedSubject, selectedSequence) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";

export default {
  name: "Note_Vue",
  components: {
    ArgonButton
  },
  data() {
    return {
      searchQuery: '',
      selectedClass: '',
      selectedSubject: '',
      selectedSequence: '',
      apiKey: '146jjvjkuygjbhjjvgkjbkjhyfdxck;mlojijn gcgloyutxx',
      students: [
        {
          id: 1,
          name: 'Alice Dupont',
          class: '6ème',
          subjects: [
            { subject: 'Mathématiques', sequence: 'Séquence 1', grade: 15 },
            { subject: 'Physique', sequence: 'Séquence 2', grade: 12 }
          ]
        },
        {
          id: 2,
          name: 'Bob Martin',
          class: '5ème',
          subjects: [
            { subject: 'Physique', sequence: 'Séquence 2', grade: 12 },
            { subject: 'Chimie', sequence: 'Séquence 3', grade: 18 }
          ]
        },
        // Ajoutez les autres élèves ici de la même manière
      ]
    };
  },
  computed: {
    uniqueClasses() {
      return [...new Set(this.students.map(student => student.class))];
    },
    uniqueSubjects() {
      return [...new Set(this.students.flatMap(student => student.subjects.map(sub => sub.subject)))];
    },
    uniqueSequences() {
      return [...new Set(this.students.flatMap(student => student.subjects.map(sub => sub.sequence)))];
    },
    filteredStudents() {
      return this.students.filter(student => {
        return (
          student.name.toLowerCase().includes(this.searchQuery.toLowerCase()) &&
          (this.selectedClass === '' || student.class === this.selectedClass) &&
          student.subjects.some(sub => (
            (this.selectedSubject === '' || sub.subject === this.selectedSubject) &&
            (this.selectedSequence === '' || sub.sequence === this.selectedSequence)
          ))
        );
      });
    }
  },
  methods: {
    selectClass(classItem) {
      this.selectedClass = classItem;
    },
    selectSubject(subject) {
      this.selectedSubject = subject;
    },
    selectSequence(sequence) {
      this.selectedSequence = sequence;
    },
    getGrade(student, subject, sequence) {
      const sub = student.subjects.find(sub => sub.subject === subject && sub.sequence === sequence);
      return sub ? sub.grade : 'N/A';
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.apiKey).then(() => {
        alert('Clé API copiée dans le presse-papiers !');
      }).catch(err => {
        alert('Erreur lors de la copie de la clé API : ' + err);
      });
    }
  }
};
</script>

<style scoped>
.filters {
  display: flex;
  margin-bottom: 40px;

}

.filters .dropdown .dropdown-toggle {
  width: 150px;
  color: white;
  margin-top: -40px;
}

.dropdown-menu .dropdown-item {
  height: 45px;
  color: white;
  border-bottom: 1px solid rgba(250, 250, 250, 0.6);
  padding-left: 10px;
  padding-top: 10px;
  transition: 0.4s;
}

.dropdown-menu .dropdown-item:hover {
  color: black;
  font-weight: bold;
}

.dropdown {
  margin-right: 10px;
}

.dropdown-toggle {
  background-color: transparent;
  border: 1px solid rgba(250, 250, 250, 0.6);
  color: #333;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-menu {

  border-radius: 4px;
  width: 100%;
  max-width: 200px;
}

.dropdown-item {
  cursor: pointer;
}



.search-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.search-bar input {
  background-color: transparent;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  margin-right: 5px;
}

.search-bar i {
  cursor: pointer;
}

.students-table {
  width: 100%;
  border-collapse: collapse;
}

.students-table th {
  border-bottom: 1px solid rgba(250, 250, 250, 0.6);
}

.students-table td {

  padding: 10px;
  text-align: left;
}
</style>
