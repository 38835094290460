<template>
    <div class="spinner-container">
    <div class="spinner">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    </div>
  </template>

  <script>
   export default {
    name: "Spinner_Vue"
   }
  </script>
  
  <style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh; /* Ajustez selon vos besoins */
}

.spinner {
  font-size: 40px;
  color: #3498db; /* Couleur du spinner */
}
</style>
  