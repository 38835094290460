<template>
   <h1>Profil</h1>     
</template>
  
  <script>
  export default {
    name: "Profil-Vue",
    
    data() {
      return {};
    },
    
  };
  </script>
  
   