<template>
  <div class="form-group">
    <div :class="hasIcon(icon)">
      <span v-if="iconDir === 'left'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
      <input
        :type="type"
        class="form-control"
        :class="getClasses(size, valid)"
        :name="name"
        :id="id"
        :value="modelValue"
        :placeholder="placeholder"
        :isRequired="isRequired"
        v-on:input="$emit('update:modelValue', $event.target.value)"
      />
      <span v-if="iconDir === 'right'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'argon-input',
  props: {
    size: {
      type: String,
      default: 'lg'
    },
    required: {
      type: String,
      default: 'required'
    },
    valid: {
      type: Boolean,
      default: false
    },
    icon: String,
    iconDir: String,
    name: String,
    id: String,
    value: String,
    modelValue: String,
    placeholder: String,
    type: String,
    isRequired: Boolean
  },
  emits: ["update:modelValue"],
  methods: {
    getClasses: (size, valid) => {
      let sizeValue = 0
      let isValidValue = 0

      sizeValue = size ? `form-control-${size}` : null

      isValidValue = valid ? `${valid}` : 'invalid'

      return `${sizeValue} ${isValidValue}`
    },
    getIcon: (icon) => (icon || null),
    hasIcon: (icon) => (icon ? 'input-group' : null)
  }
}
</script>
