<template>
    <div class="modal-dialog">
        <div class="card">
            <div class="modal-content">
                <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
                    <h5 class="modal-title" id="staticBackdropLabel10"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="close1"></button>
                </div>
                <div class="modal-body text-center pt-4 pb-4">

                    <div class="container mt-4 mb-4">
                        <h6>Suspension temporaire des cours.</h6>
                        <form class="mx-auto mt-5" style="width:91%;">

                            <div class="mb-3 text-start ">
                                <label for="matieres " style="font-size:14px" class="form-label ">
                                    Sélectionnez la ou les classes concernées.<em style="color: #f60000">*</em>
                                </label>

                                <div class="row mt-3 ps-3">

                                    <div v-for="matiere in matieres" :key="matiere.id" class="form-check  col-3 mt-2 ">
                                        <input class="form-check-input" type="checkbox" :id="`matiere-${matiere.id}`"
                                            :value="matiere.name" v-model="selectedMatieres">
                                        <label class="form-check-label" :for="`matiere-${matiere.id}`">
                                            {{ matiere.name }}
                                        </label>
                                    </div>

                                </div>

                                <!-- Liste de matières avec des checkbox -->

                            </div>
                            <div class="row">
                                <div class="mb-3 col-6 text-start">
                                    <label for="dateDebut" class="form-label">Date de Début <em
                                            style="color: #f60000">*</em></label>
                                    <input style="height:50px;" type="date" class="form-control" id="dateDebut"
                                        aria-describedby="datestartHelp">
                                    <div id="datestartHelp" class="form-text">Elle doit être inférieur a la date de fin
                                    </div>
                                </div>
                                <div class="mb-3 col-6 text-start">
                                    <label for="dateFin" class="form-label">Date de Fin <em
                                            style="color: #f60000">*</em></label>
                                    <input style="height:50px;" type="date" class="form-control" id="dateFin"
                                        aria-describedby="dateendHelp">
                                    <div id="dateendHelp" class="form-text">Elle doit être suppérieur a la date de début
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3 text-start">
                                <label for="motif" class="form-label">Motif <em style="color: #f60000">*</em></label>
                                <textarea class="form-control" id="motif" rows="3"
                                    placeholder="Ex: Grève, vacances..."></textarea>
                            </div>

                        </form>
                    </div>


                </div>
                <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
                    <argon-button type="button" class="btn" title="Fermer" data-bs-dismiss="modal" style="width: 200px; height: 50px; padding-top: 15px; background: none;
                        border: 1px solid rgba(255, 255, 255, 0.4);">
                        <span>
                            {{ $t("button.cancel") }}
                        </span>
                    </argon-button>
                    <argon-button type="button" class="btn me-5 " color="success" variant="gradient"
                        style="width: 200px; height: 50px; padding-top: 15px" :disabled="!isChoiceMade">
                        {{ $t("button.send") }}
                    </argon-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";

export default {
    name: 'Stop-Course-Modal',
    components: {
        ArgonButton
    },
    props: {
        amount: {
            type: Number,
            required: true
        },
        installmentNumber: {
            type: Number,
            required: true
        },
        due_date: {
            type: String,
            required: true
        },
        updatestudentstatut: {
            type: Function,
            required: true
        },
        updatestatutsettled: {
            type: Function,
            required: true,
        }
    },
    data() {
        return {
            showPending: false,
            showSettled: false,
            selectedStatus: '',
            isChoiceMade: false,
            loading: false,
            classroomId: null,
            showAlert: false,
            periods: [
                { id: 1, name: 10, amount: "Juin" },
                { id: 2, name: 10, amount: "Juin" },
                { id: 3, name: 10, amount: "Juin" },
            ],
            matieres: [
                { id: 1, name: "6ème A" },
                { id: 2, name: "6ème B" },
                { id: 3, name: "5ème A" },
                { id: 4, name: "5ème B" },
                { id: 5, name: "4ème A" },
                { id: 6, name: "4ème B" },
                { id: 7, name: "3ème A" },
                { id: 8, name: "3ème B" },
                { id: 9, name: "2nde A" },
                { id: 10, name: "2nde B" },
                { id: 11, name: "1ère A" },
                { id: 12, name: "1ère B" },
                { id: 13, name: "Tle A" },
                { id: 14, name: "Tle B" }
            ],

            selectedPeriods: []
        };
    },
    beforeMount() {
        this.classroomId = this.$route.params.classId;
    },
    methods: {
        confirmAction() {
            if (this.isChoiceMade) {
                this.showAlert = false;
                if (this.selectedStatus === 'pending') {
                    this.ParentUpdatestudentstatut();
                } else if (this.selectedStatus === 'settled') {
                    this.Parentupdatestatutsettled();
                }
            } else {
                this.showAlert = true;
            }
        },
        annuler() {
            this.showPending = false;
            this.showSettled = false;
            this.$refs.pendingRadio.checked = false;
            this.$refs.settledRadio.checked = false;
            this.isChoiceMade = false;
            this.showAlert = false;
        },
        togglePending() {
            this.showPending = true;
            this.showSettled = false;
            this.selectedStatus = 'pending';
            this.isChoiceMade = true;
            this.showAlert = false;
        },
        toggleSettled() {
            this.showPending = false;
            this.showSettled = true;
            this.selectedStatus = 'settled';
            this.isChoiceMade = true;
            this.showAlert = false;
        },
        ParentUpdatestudentstatut() {
            this.loading = true;
            this.updatestudentstatut();
        },
        Parentupdatestatutsettled() {
            this.updatestatutsettled();
            this.loading = true;
        }
    }
}
</script>


<style>
#pending,
#settled {
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    border: 1px solid green;
}

#alert-modal {
    border: 1px solid #83454d;
    color: #83454d;
    background: rgba(0, 0, 0, 0.6)
}
</style>