<template>
  <div class="container-fluid ">
    <div class="content text-center">

      <img class="image mb-5" src="@/assets/img/ima1.png" alt="" />

      <h5 class="fw-bold" >Envoi des notes à partir d'Eska </h5>

      <p class="w-70 mx-auto mt-5 mb-5" >
        Donnez la possibilité à vos enseignants d'envoyer les notes
        d'élèves à partir de l'application Eska. Vous pouvez exporter ces notes en
        fichier Excel ou les recevoir directement dans votre logiciel de bulletin.
      </p>

      <button type="button" class="btn btn-lg bg-gradient-success"  >
         <a target="_blank" href="#">Je prends l'abonnement</a>
      </button>

    </div>
  </div>


</template>

<script>
export default {
  name: "Sponsoring_Vue",

  data() {
    return {};
  },
};
</script>

<style>
/**=========body-card============ */
</style>