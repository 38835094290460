<template>

  <div class="container-fluid mt-7">
    <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
      <div>
        <span class="mt-4">
          <Spinner></Spinner>
        </span>
      </div>
    </spinner>
    <div class="body" v-else-if="table.length > 0">


      <!-- Aujourd'hui -->
      <div v-if="groupedNotifications.today.length > 0">
      

        <div class="message actif text-justify mt-2" v-for="list in groupedNotifications.today" :key="list.id">
          <div class="row">
            <div class="col-md-1 profil">
                <img class="mt-2" src="@/assets/img/logo-ct.png" alt="" />
            </div>
            <div class="col-md-10">
              <div class="title-notif mt-2">
                <strong>{{ list.title }}</strong>
              </div>

              <span :class="{ 'new-notification': !isRead(list.id), 'read-notification': isRead(list.id) }"></span>
              <div class="text mt-4 mb-0">
                {{ list.content }} <strong>{{ list.username }}</strong>
              </div>
              <div class="video p-0 mt-0 " v-if="list_video">
                <video width="100%" controls>
                  <source src="@/assets/img/video.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div class="image mt-2" v-if="list.image">
                <img :src="list.image" alt="" />
              </div>

              <div class="button d-flex p-0 ">
                <div class="time">{{ formatDate(list.created_on) }}</div>
                <button v-if="list.button" class="fw-bold opacity-9">Classroom</button>
              </div>
            </div>
          </div>
        </div>

      </div>






      <div v-if="groupedNotifications.today.length > 0">
       



        <div class="row px-4">
          <div class="col-md-9 actif notif" v-for="list in groupedNotifications.today" :key="list.id">
            <div class="row">
              <div class="col-md-1 profil">
                <img class="mt-2" src="@/assets/img/logo-ct.png" alt="" />
              </div>
              <div class="col-md-11 text-notif">
                <div class="title-notif mt-2">
                  <strong style="color:white">{{ list.title }} </strong>
                </div>
                <div class="time">{{ formatDate(list.created_on) }}</div>
                <span :class="list.read ? 'indicator read' : 'indicator new'"></span>
                <div class="text mt-5">
                  {{ list.content }} <strong>{{ list.username }}</strong>
                </div>
                <div v-if="list.video" class="video mt-3" style="background: black">
                  <video width="100%" height="360" controls>
                    <source src="@/assets/img/video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div class="image mt-2" v-if="list.image">
                  <img :src="list.image" alt="" />
                </div>
                <div class="button text-end p-0">
                  <button v-if="list.button" class="fw-bold opacity-9">Notifications</button>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>




      <div v-if="groupedNotifications.today.length > 0">
         
        <div class="row px-4">
          <div class="col-md-9 actif notif" v-for="list in groupedNotifications.today" :key="list.id">
            <div class="row">
              <div class="col-md-1 profil">
                <img class="mt-2" src="@/assets/img/logo-ct.png" alt="" />
              </div>
              <div class="col-md-11 text-notif">
                <div class="title-notif mt-2">
                  <strong style="color:white">{{ list.title }} </strong>
                </div>
                <div class="time">{{ formatDate(list.created_on) }}</div>
                <span :class="list.read ? 'indicator read' : 'indicator new'"></span>
                <div class="text mt-5">
                  {{ list.content }} <strong>{{ list.username }}</strong>
                </div>
                <div v-if="list.video" class="video mt-3" style="background: black">
                  <video width="100%" height="360" controls>
                    <source src="@/assets/img/video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div class="image mt-2" v-if="list.image">
                  <img :src="list.image" alt="" />
                </div>
                <div class="button text-end p-0">
                  <button v-if="list.button" class="fw-bold opacity-9">Notifications</button>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>



      <div v-if="groupedNotifications.lastFourDays.length > 0">
      
        <div class="row px-4">
          <div class="col-md-9 actif notif" v-for="list in groupedNotifications.lastFourDays" :key="list.id">
            <div class="row">
              <div class="col-md-1 profil">
                <img class="mt-2" src="@/assets/img/logo-ct.png" alt="" />
              </div>
              <div class="col-md-11 text-notif">
                <div class="title-notif mt-2">
                  <strong style="color:white">{{ list.title }} </strong>
                </div>
                <div class="time">{{ formatDate(list.created_on) }}</div>
                <span :class="list.read ? 'indicator read' : 'indicator new'"></span>
                <div class="text mt-5">
                  {{ list.content }} <strong>{{ list.username }}</strong>
                </div>
                <div v-if="list.video" class="video mt-3" style="background: black">
                  <video width="100%" height="360" controls>
                    <source src="@/assets/img/video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div class="image border-0 mt-2" v-if="list.image">
                  <img :src="list.image" alt="" />
                </div>
                <div class="button text-end p-0">
                  <button v-if="list.button" class="fw-bold opacity-9">Notifications</button>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>


      <div v-if="groupedNotifications.older.length > 0">
       
        <div class="row px-4">
          <div class="col-md-9 actif notif" v-for="list in groupedNotifications.older" :key="list.id">
            <div class="row">
              <div class="col-md-1 profil">
                <img class="mt-2" src="@/assets/img/logo-ct.png" alt="" />
              </div>
              <div class="col-md-11 text-notif">
                <div class="title-notif mt-2">
                  <strong style="color:white">{{ list.title }} </strong>
                </div>
                <div class="time">{{ formatDate(list.created_on) }}</div>
                <span :class="list.read ? 'indicator read' : 'indicator new'"></span>
                <div class="text mt-5">
                  {{ list.content }} <strong>{{ list.username }}</strong>
                </div>
                <div v-if="list.video" class="video mt-3" style="background: black">
                  <video width="100%" height="360" controls>
                    <source src="@/assets/img/video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div class="image mt-2" v-if="list.image">
                  <img :src="list.image" alt="" />
                </div>
                <div class="button text-end p-0">
                  <button v-if="list.button" class="fw-bold opacity-9">Notifications</button>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>


      <div class="container-fluid">

      </div>
    </div>
    <div class="no-notif" v-else>
      <div class="container-fluid">
        <div class="content text-center ">
          <img class="image" src="@/assets/img/notiff.png" alt="" />
          <h5 style="margin-top:-50px;">Aucune notification</h5>
           
        </div>
      </div>
    </div>


  </div>
  <div class=" mt-3" v-if="table.length > 0">
    <nav aria-label="...">
      <ul id="pagination" class=" w-80 mx-auto">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <button class="page-link" @click="prevPage" :disabled="currentPage === 1"
            style="background: none; color: white">
            <i class="bi bi-chevron-left"></i>
          </button>
        </li>
        <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: currentPage === page }">
          <button class="page-link fw-bold" style="background: none" @click="() => setCurrentPage(page)">
            {{ page }}
          </button>
        </li>

        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <button class="page-link" @click="nextPage" :disabled="currentPage === totalPages" style="
                    background: none;
                    color: white;
                  ">
            <i class="bi bi-chevron-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { getnotifications } from "@/utils/services";
import Spinner from "../components/Spinner.vue";
import { format, isToday, isYesterday, subDays, isAfter } from 'date-fns';

export default {
  name: "Notification_Vue",
  components: { Spinner },
  data() {
    return {
      table: [],
      loading: true,
      currentPage: 1,
      itemsPerPage: 50,
      readNotifications: new Set(),
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.table.length / this.itemsPerPage);
    },
    paginatedtable() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.table.slice(startIndex, endIndex);
    },
    groupedNotifications() {
      return this.groupNotifications();
    },
    ///===========filtre ========
    pendingNotificationsCount() {
      return this.table.filter(notification =>
        notification.status === "Pending" && !this.readNotifications.has(notification.id)
      ).length;
    },
  },
  methods: {


    isRead(notificationId) {
      return this.readNotifications.has(notificationId);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },

    groupNotifications() {
      const today = new Date();

      const fourDaysAgo = subDays(today, 4);

      const grouped = {
        today: [],
        yesterday: [],
        lastFourDays: [],
        older: []
      };

      this.table.forEach(notification => {
        const notifDate = new Date(notification.created_on);
        if (isToday(notifDate)) {
          grouped.today.push(notification);
        } else if (isYesterday(notifDate)) {
          grouped.yesterday.push(notification);
        } else if (isAfter(notifDate, fourDaysAgo)) {
          grouped.lastFourDays.push(notification);
        } else {
          grouped.older.push(notification);
        }
      });

      return grouped;
    },

    async fetchnotifications() {
      try {
        this.loading = true;
        let notifications = await getnotifications();

        this.table = notifications.sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
      } catch (error) {
        console.error("Error fetching notifications data:", error);
      } finally {
        this.loading = false;
      }
    },
    formatDate(dateString) {
      return format(new Date(dateString), 'dd/MM/yyyy HH:mm');
    },
    markAsRead(notificationId) {
      this.readNotifications.add(notificationId);
      this.saveReadNotifications();
    },

    markAllAsRead() {
      this.table.forEach(notification => {
        if (notification.status === "Pending") {
          this.readNotifications.add(notification.id);
        }
      });
      this.saveReadNotifications();
    },

    saveReadNotifications() {
      localStorage.setItem('readNotifications', JSON.stringify([...this.readNotifications]));
    },

    loadReadNotifications() {
      const saved = localStorage.getItem('readNotifications');
      if (saved) {
        this.readNotifications = new Set(JSON.parse(saved));
      }

    },

  },
  mounted() {
    this.fetchnotifications();
    this.groupNotifications();
  }
};
</script>

<style>
.notif {
  background-color: #111c44;
  border-radius: 0px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgba(250, 250, 250, 0.2);
  border-radius: 3px;
  transition: 0.3s;
}

.notif:hover {
  transform: translateY(-1px);
}

.notif .text-notif {
  color: white;
  padding-top: 25px;
  padding-bottom: 15px;
  text-align: justify;
  font-size: 15px;
  padding-left: 30px;
  position: relative;
}

.notif .text-notif .text {
  color: #ffffff;
  font-weight: 500;
}

.notif .text-notif .button button {
  border: none;
  height: 40px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 5px;
  margin-top: 30px;
  font-size: 15px;
  font-weight: 600;
  opacity: 0.7;
  transition: 0.3s;
  background: white;
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.notif .text-notif .button button:hover {
  transform: translateY(-3px);
}

.notif .text-notif .indicator {
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
}

.notif .text-notif .indicator.new {
  background: gray;
}

.notif .text-notif .indicator.read {
  background: gray;
}

.notif .text-notif .time {
  position: absolute;
  right: 35px;
  top: 5px;
  opacity: 0.6;
}

.notif .text-notif .image img {
  width: 50%;
  height: 100%;
  object-fit: contain;
}

.notif .text-notif .title-notif {
  position: absolute;
  top: 18px;
  color: #454545;
}

.notif .text-notif .video {
  width: 100%;
}

.notif .profil img {
  width: 65px;
  border-radius: 50%;
}

#pagination {

  display: flex;
  list-style: none;
}

#pagination button {
  margin: 0 5px;
  cursor: pointer;
  background-color: #081739;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 20px;
}

#pagination button:hover {
  background-color: #0056b3;
}

#pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
