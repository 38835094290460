<template>
  <div
      @dragenter.prevent="toggleActive"
      @dragleave.prevent="toggleActive"
      @dragover.prevent
      @drop.prevent="toggleActive"
      :class="{'active-dropzone': active}"
      class="drop-zone">
   
    <input type="file" id="dropzoneFile" class="dropzoneFile" ref="fileInput" @change="handleFileUpload">
   
    <img v-if="imageUrl" :src="imageUrl" alt="Uploaded Image" style="max-width: 100%; max-height: 100%; border-radius: 100%; ">
    <label v-else for="dropzoneFile" class="bi bi-file-image" style="font-size: 67px;"></label>
  </div>
  
</template>

<script>
import { ref } from "vue";

export default {
  name: "image-upload",
  setup() {
    const active = ref(false);
    const imageUrl = ref(null);
    const fileInput = ref(null);

    const toggleActive = () => {
      active.value = !active.value;
    }

    const handleFileUpload = () => {
      const file = fileInput.value.files[0];
      if (file) {
        // Create a FileReader object
        const reader = new FileReader();

        // Define a callback function to handle the file loading
        reader.onload = (e) => {
          imageUrl.value = e.target.result;
        };

        // Read the file as a Data URL (base64 encoded)
        reader.readAsDataURL(file);
      }
    }

    return { active, toggleActive, handleFileUpload, imageUrl, fileInput };
  }
}
</script>

<style scoped lang="scss">
.drop-zone {
  width: 220px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  border: 2px dashed #41b883;
  background-color: #1a2035;
  transition: 0.3s ease all;
  border-radius: 50%;

  label {
    color: #1a2035;
    transition: .3s ease all;
    cursor: pointer;
  }

  input {
    display: none;
  }
}

.active-dropzone {
  color: #ffffff;
  border-color: #ffffff;
  background-color: #41b883;

  label {
    background-color: #41b883;
  }
}
</style>
