<template>
  <div class="card">
    <div class="card-header py-3 px-4 mb-3" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
      <h6> {{ $t("paymentpending.title_modal_reject") }}</h6>
      
    </div>

    <div class="card-body ">
       
      <textarea 
       v-model="message" 
      
        :class="{ 'border-red': !isMessageValid }"
       :placeholder="$t('paymentpending.modal_reject_motif')"></textarea>
    </div>

    <div class="card-footer mt-3 py-3" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
      <div class="row">
        <div class="col-lg-12 d-flex justify-content-end">

          <argon-button    class="btn  mt-1 bg-transparent "
            type="button"   data-bs-dismiss="modal" aria-label="Close"
            @click="Cancel" style="
              width: 150px;
              height: 50px;
              padding-top: 15px;
              cursor: pointer;
              margin-right: 15px;
              border:1px solid rgba(255, 255, 255, 0.4);
            ">
            {{ $t("button.cancel") }}
          </argon-button>
          <argon-button :loading="loading" :active="buttonActive || showConfirmation"   color="danger" variant="gradient"
             type="button"   @click="validateAndSubmit" class="mt-1" style="
              width: 150px;
              height: 50px;
              padding-top: 15px;
              cursor: pointer;
            ">
            {{ $t("button.confirm") }}
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { postreceiptverified } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  name: "Refuse-Payment",
  data() {
    return {
      message: "",
      verification_status: "Accepted",
      buttonActive: false,
      showConfirmation: false,
      isMessageValid:true

    }
  },
  components: {
    ArgonButton,
  },

  methods: {
    Cancel() {
      this.$emit("cancel")
       
    },

    validateAndSubmit() {
      this.isMessageValid = this.message.length >= 1;

      if (!this.isMessageValid) {
        
        return;
      }

      this.fetchConfirmreceipt(this.selectedPayment.payment.id);
    },

    async fetchConfirmreceipt(payment_id) {

      if (this.loading) {

        return;
      }
      this.loading = true;
      this.button = "disabled";
 

      let data = {
        payment_id: payment_id,
        verification_status: this.verification_status,
        message: this.message

      }
      

      try {

        let response = await postreceiptverified(data);

        if (response.exist === false) {
          // Bloc intentionnellement vide, rien à faire ici pour l'instant
        } else {
          this.showConfirmation = true;
          const message = "Paymenet Rejeté avec succès";
          toast.success(message, {
            autoClose: 3000,
          });

          setTimeout(() => {
            window.location.reload();
            this.showConfirmation = false;
          }, 3000);

        }
      } catch (error) {
        console.error("Erreur:", error);
        this.failedMessage = "Une erreur s'est produite lors  du rejet du payement.";
      } finally {
        this.loading = false;
      }
    },
  },
  props: {
    selectedPayment: Object,
    payment: {
      type: String,
    },
  }



};
</script>

<style scoped>
.card-header {
  padding: 0;
}

.card .card-body textarea {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  padding: 20px;
  opacity: 0.8;
}
.border-red {
  border: 2px solid red;
}
</style>
