<template>


<div class="card text-center">

<img   style="width:20%; margin-top: 8%; margin-left: 50% ; margin-right:auto" src="@/assets/img/wifi.png" alt="">
<h6 style="margin-left: 20% ">Vous êtes hors ligne.  <br> Veuillez vérifier votre connexion Internet.</h6>
</div>



</template>


<script>
 
 export default {
    name: " No-Online",
 };

</script>

<style>
 

 .card{
   height: 100%;
   width: 100%;
   
  

 }

</style>