<template>
  <div class="card">
    <div class="p-3 mx-4 text-center card-header">
      <div
        :class="[icon_bg, card_bg]"
        class="icon icon-shape icon-lg shadow text-center border-radius-lg"
      >
        <i class="opacity-10" :class="classIcon" aria-hidden="true"></i>
      </div>
    </div>
    <div class="p-3 pt-0 text-center card-body">
      <h6 class="mb-0 text-center">{{ title }}</h6>
      
       
      <h6 class="mb-3 mt-3"><span >{{ CURRENCY }}</span>&nbsp;{{ price }}</h6>
      <span title="Nombre d'élève" style="cursor: pointer;" class="text-xl">{{ desc }}</span>
    </div>
  </div>
</template>

<script>
import api_config from "@/config";

export default {
  name: 'default-info-card',
  props: {
    card_bg: {
      type: String,
      default: 'bg-gradient-warning'
    },
    CURRENCY: {
      type: String,
      default: api_config.CURRENCY
    },
    icon_bg: {
      type: String,
      default: 'bg-warning'
    },
    classIcon: {
      type: String,
      required: true
    },
    title: String,
    desc: String,
    price: String
  }
}
</script>
