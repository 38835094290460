<template>
    
 

  <main>
    <!--========================= modal delete =================================== -->

    <div
      class="modal fade"
      id="staticBackdrop13"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="card">
          <div class="modal-content">
            <div
              class="modal-header"
              style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)"
            >
              <h5 class="modal-title" id="staticBackdropLabel13"></h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body text-center pt-4 pb-4">
              <div
                class="content"
                v-if="
                  studentDetails.payments &&
                  studentDetails.payments.length > 0 &&
                  studentDetails.payments[0].status == 'Settled'
                "
              >
                <i
                  class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"
                ></i>
                <h6 v-if="studentDetails">
                  {{ $t("students_detail.modal_no_delete_title") }}
                  {{ studentDetails.last_name }} {{ studentDetails.first_name }}
                </h6>
                <p class=" text-center ">
                  {{ $t("students_detail.modal_no_delete_text") }}
                </p>
              </div>

              <div class="content" v-else>
                <i
                  class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"
                ></i>
                <h6>
                  {{ $t("students_detail.modal_delete_title") }}
                  {{ studentDetails.last_name }}
                  {{ studentDetails.first_name }} ?
                </h6>
                <p class="text-center px-6">
                  {{ $t("students_detail.modal_delete_text") }}
                </p>
              </div>
            </div>
            <div
              class="modal-footer"
              style="border-top: 1px solid rgba(255, 255, 255, 0.4)"
            >
              <argon-button
             
                type="button"
                 class="btn bg-transparent "
                data-bs-dismiss="modal"
                style="width: 150px; height: 50px; padding-top: 15px; border: 1px solid rgba(255, 255, 255, 0.4)"
              >
                {{ $t("button.cancel") }}
              </argon-button>

              <argon-button
              v-if="
                  studentDetails.payments &&
                  studentDetails.payments.length > 0 &&
                  studentDetails.payments[0].status !== 'Settled'
                "
                :loading="loading"
                :active="buttonActive || showConfirmation"
                color="danger" variant="gradient"
                class="mt-1"
                style="width: 150px; height: 50px; padding-top: 15px"
                @click="deleteStudent"
                
              >
                {{ $t("button.confirm") }}
              </argon-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div
        class="page-header min-height-300"
        style="
          background-image: url(&quot;https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80&quot;);
          margin-right: -24px;
          margin-left: -34%;
        "
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>

      <div class="card shadow-lg header-profile mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-3">
              <div class="">
                <a href="javascript:;">
                  <img
                    v-if="studentDetails.photo_url"
                    width="300"
                    src="@/assets/img/team-2.jpg"
                    class="rounded-circle img-fluid border border-2 border-white"
                  />
                  <img
                    v-else
                    width="300"
                    src="@/assets/img/no_body.jpg"
                    class="rounded-circle img-fluid border border-2 border-white"
                  />
                </a>
              </div>
            </div>

            <div class="col-9 pt-7">
              <div class="row">
                <div class="col-6">
                  <h6 class="text-uppercase text-sm mt-4">
                    {{ studentDetails.first_name }}
                    {{ studentDetails.last_name }}
                  </h6>
                </div>
                <div class="col-6 text-end px-0">
                   <argon-button
                    color="danger"
                     variant="gradient"
                    :clickEvent="button"
                    style="height: 50px; padding-top: 15px; width: 150px"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop13"
                  >
                    {{ $t("button.delete") }}
                  </argon-button>
                </div>
              </div>
            </div>

            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                ></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-8">
          <div class="card pb-4">
            <div class="card-header pb-0"></div>
            <div class="card-body">
              <spinner
                class="text-center mt-8 mb-8"
                v-if="loading"
                style="height: 200px"
              >
                <div>
                  <span class="mt-4"><Spinner></Spinner></span>
                </div>
              </spinner>
              <div class="row" v-else-if="studentDetails">
                <div class="col-md-6">
                  <label class="form-control-label">{{ $t("students_detail.number") }}</label>
                  <argon-input
                    type="text"
                    v-model="studentDetails.registration_number"
                  />
                </div>
                <div class="col-md-6">
                  <label class="form-control-label">{{ $t("students_detail.last_name") }}</label>
                  <argon-input type="text" v-model="studentDetails.last_name" />
                </div>
                <div class="col-md-6">
                  <label class="form-control-label">{{ $t("students_detail.first_name") }}</label>
                  <argon-input
                    type="email"
                    v-model="studentDetails.first_name"
                  />
                </div>

                <div class="col-md-6">
                  <label class="form-control-label"
                    >{{ $t("students_detail.phone") }}</label
                  >
                  <br />
                  <argon-input
                    type="text"
                    class="mb-0"
                    v-model="phoneListString"
                  />
                  <span style="font-size: 12px"
                    >{{ $t("students_detail.phone_text") }}
                  </span>
                </div>

                <div class="col-md-6 mt-3">
                  <label class="form-control-label">{{ $t("students_detail.place_birth") }}</label>
                  <argon-input type="text" v-model="studentDetails.pob" />
                </div>
                <div class="col-md-6 mt-3">
                  <label class="form-control-label">{{ $t("students_detail.date") }}</label>
                  <argon-input
                    is-required
                    type="date"
                    v-model="studentDetails.dob"
                  ></argon-input>
                </div>
                <div class="col-md-6">
                  <label class="form-control-label">{{ $t("students_detail.sexe") }}</label>
                  <div class="d-flex">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        value="M"
                        v-model="studentDetails.gender"
                        id="maleRadio"
                      />
                      <label class="form-check-label" for="maleRadio">
                        {{ $t("students_detail.M") }}
                      </label>
                    </div>
                    <div class="form-check mx-4">
                      <input
                        class="form-check-input"
                        type="radio"
                        value="F"
                        v-model="studentDetails.gender"
                        id="femaleRadio"
                      />
                      <label class="form-check-label" for="femaleRadio">
                        {{ $t("students_detail.F") }}
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row p-0">
                   

                  <div class="col-6 mt-6">
                    <argon-button
                      :loading="loading"
                      :active="buttonActive || showConfirmation"
                      color="success"
                      full-width
                      @click="saveChanges"
                      :clickEvent="button"
                      style="height: 50px; padding-top: 15px"
                    >
                      {{ $t("button.save") }}
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <todo-list-card
            :total-due="studentDetails.total_due"
            :current_payment="studentDetails.current_payment"
            :payment_status="studentDetails.payment_status"
            :students="studentDetails.payments"
            :studentDetails="studentDetails"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import ArgonButton from "@/components/ArgonButton";
import { deleteStudent, updateStudent, getStudent } from "@/utils/services"; // Importez la fonction de suppression
import ArgonInput from "@/components/ArgonInput.vue";
import TodoListCard from "@/views/components/TodoListCard";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "../components/Spinner.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "student-profile",
  data() {
    return {
      showMenu: false,
      status: null,
      studentDetails: {},
      phoneList: "",
      buttonActive: false,
      showConfirmation: false,
      loading: false,
      editedPhoneList: [],
    };
  },

  components: { TodoListCard, ArgonInput, ArgonButton, Spinner },

  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
  computed: {
    phoneListString: {
      get() {
        return this.editedPhoneList.join(", ");
      },
      set(newValue) {
        this.editedPhoneList = newValue.split(",").map((num) => num.trim());
      },
    },
  },

  created() {
    this.studentid = this.$route.params.id;
    // Chargement des détails de l'étudiant
    this.loadStudentDetails();
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },

  methods: {
    backtostudentlist() {
      const classroomId = this.$route.params.classroom_id;
      this.$router.push({
        name: "class-details",
        params: { classId: classroomId },
      });
    },

    async loadStudentDetails() {
      try {
        this.loading = true;
        const studentId = this.$route.params.id;
        this.studentDetails = await getStudent(studentId);
        const status = this.studentDetails.payments[0].status;
        console.log("Détail de lélève",this.studentDetails);
        console.log("Statut :", status);

        const phoneList = JSON.parse(
          this.studentDetails.phone_list.replace(/'/g, '"')
        );

        this.editedPhoneList = [...phoneList];
      } catch (error) {
        console.error(
          "Erreur lors du chargement des détails de l'étudiant:",
          error
        );
      } finally {
        this.loading = false;
      }
    },

    async saveChanges() {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        this.buttonActive = true;
        const studentId = this.studentDetails.id;
        const updatedData = {
          registration_number: this.studentDetails.registration_number,
          last_name: this.studentDetails.last_name,
          first_name: this.studentDetails.first_name,
          phone_list: JSON.stringify(this.editedPhoneList),
          gender: this.studentDetails.gender,
          pob: this.studentDetails.pob,
          dob: this.studentDetails.dob,
          total_due: this.studentDetails.total_due,
          photo_url: this.studentDetails.photo_url,
          current_payment: this.studentDetails.current_payment,
        };

        const response = await updateStudent(studentId, updatedData);
        console.log(response);

        if (response) {
          this.showConfirmation = true;
          const classroomId = this.$route.params.classroom_id;
          const message = "Informations modifiées avec succès";
          toast.success(message, {
            autoClose: 3000,
          });

          document.body.classList.remove("modal-open");
          const modalBackdrops =
            document.getElementsByClassName("modal-backdrop");
          for (const backdrop of modalBackdrops) {
            backdrop.parentNode.removeChild(backdrop);
          }

          setTimeout(() => {
            this.showConfirmation = false;
            // Rediriger vers la vue class-details avec le paramètre classId
            this.$router.push({
              name: "class-details",
              params: { classId: classroomId },
            });
          }, 3000);
        } else {
          console.error("Échec de la mise à jour des données de l'étudiant.");
        }
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour des données de l'étudiant :",
          error
        );
      } finally {
        this.loading = false;
        this.buttonActive = false;
      }
    },

    async deleteStudent() {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        this.buttonActive = true;
        const studentId = this.studentDetails.id;
        const response = await deleteStudent(studentId);
        if (response) {
          const classroomId = this.$route.params.classroom_id;
          console.log("classe", classroomId);

          this.showConfirmation = true;
          const message = "Élève supprimé avec succès";
          toast.success(message, {
            autoClose: 3000,
          });

          document.body.classList.remove("modal-open");
          const modalBackdrops =
            document.getElementsByClassName("modal-backdrop");
          for (const backdrop of modalBackdrops) {
            backdrop.parentNode.removeChild(backdrop);
          }

          setTimeout(() => {
            this.showConfirmation = false;
            // Rediriger vers la vue class-details avec le paramètre classId
            this.$router.push({
              name: "class-details",
              params: { classId: classroomId },
            });
          }, 3000);
        }
      } catch (error) {
        const message =
          "Vous ne pouvez pas supprimer cet élève car il a des paiments rélgés";
        toast.error(message, {
          autoClose: 3000,
        });
      } finally {
        this.loading = false;
        this.buttonActive = false;
      }
    },
  },
};
</script>

<style>
.header-profile {
  background: transparent !important;
}
</style>
