<template>
 


  <!-- Alerte de copie -->
  <div v-if="showAlert" class="alert alert-info mx-auto w-60" role="alert" style="">
    {{$t("dashboard.success_copy_link")}}
  </div>
  <!---modal detail payment-->


  <div class="modal fade" id="deletepaymentModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div v-if="show_detail_payment">
          <PaymentPendingDetail @delete="rejected" @continue="confirm" :selectedPayment="selectedPayment" />
          <div v-if="selectedPayment"></div>
        </div>
        <div class="col-md-12" v-if="show_raison_delete">
          <RefusePayment @Cancel="cancel" :selectedPayment="selectedPayment" />
        </div>

        <div class="col-md-12" v-if="show_confirmation">
          <ConfirmPayment @Cancel="cancel" :selectedPayment="selectedPayment" />
        </div>
      </div>
    </div>
  </div>

  <!---modal detail payment-->



  <div class="py-2 container-fluid  ">

    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12" :title="$t('dashboard.stats.money.title_card')">


            <card :dashboardData="dashboardData" style="height: 115px" :title="$t('dashboard.stats.money.title')"
              :number_students="stats.money.number_students" :value="stats.money.value"
              :percentage="stats.money.percentage" :iconClass="stats.money.iconClass"
              :iconBackground="stats.money.iconBackground" :detail="stats.money.detail"
              :Colorspan="stats.money.Colorspan" directionReverse></card>
          </div>

          <div class="col-lg-3 col-md-6 col-12" :title="$t('dashboard.stats.clients.title_card')">

            <router-link to="/classes-students-payment-pending">
              <card :dashboardData="dashboardData" style="height: 115px" :title="$t('dashboard.stats.clients.title')"
                :value="stats.clients.value" :number_students="stats.clients.number_students"
                :iconClass="stats.clients.iconClass" :iconBackground="stats.clients.iconBackground"
                :percentageColor="stats.clients.percentageColor" :Colorspan="stats.clients.Colorspan" directionReverse>
              </card>
            </router-link>
          </div>

          <div class="col-lg-3 col-md-6 col-12" :title="$t('dashboard.stats.users.title_card')">


            <card :dashboardData="dashboardData" style="height: 115px" :title="$t('dashboard.stats.users.title')"
              :value="stats.users.value" :number_students="stats.users.number_students"
              :percentage="stats.users.percentage" :iconClass="stats.users.iconClass"
              :iconBackground="stats.users.iconBackground" :detail="stats.users.detail"
              :Colorspan="stats.users.Colorspan" directionReverse></card>

          </div>

          <div class="col-lg-3 col-md-6 col-12" :title="$t('dashboard.stats.sales.title_card')">

            <router-link to="/classes-students-payment-settled">
              <card :dashboardData="dashboardData" style="height: 115px" :title="$t('dashboard.stats.sales.title')"
                :value="stats.sales.value" :number_students="stats.sales.number_students"
                :iconClass="stats.sales.iconClass" :iconBackground="stats.sales.iconBackground"
                :Colorspan="stats.sales.Colorspan" directionReverse></card>
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5">
            <carousel />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 mb-lg-0">
            <!--------tablae ------->

            <div class="card px-0">
              <div class="card-body px-0" style="height: 500px">
                <div class="row">
                  <div class="col-md-7" style="padding-left: 35px">
                    <h6>{{ $t("dashboard.title-card-payemnt") }}</h6>
                  </div>
                  <div class="col-md-5 text-end" style="padding-right: 30px" v-if="waitingConfirmation.length > 0">
                    <RouterLink to="/list-payment">
                      <argon-button color="success" size="sm" variant="outline">
                        {{ $t("button.See-more") }}
                      </argon-button>
                    </RouterLink>
                  </div>
                </div>

                <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
                  <div>
                    <span class="mt-4">
                      <Spinner></Spinner>
                    </span>
                  </div>
                </spinner>

                <table class="table px-0 mt-6 mb-6" v-else-if="waitingConfirmation.length > 0">
                  <thead>
                    <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">
                      <th scope="col" class="border-0">
                        {{ $t("dashboard.name") }}
                      </th>
                      <th scope="col" class="border-0">
                        {{ $t("dashboard.grade") }}
                      </th>
                      <th scope="col" class="border-0">
                        {{ $t("dashboard.receipt") }}
                      </th>
                      <th scope="col" class="border-0 text-end">
                        {{ $t("dashboard.amount") }}
                      </th>
                    </tr>
                  </thead>


                 
                    <div v-if="loading" class="pt-6" style="width: 150%; height: 250px; margin-left: 100%">
                      <Spinner></Spinner>
                    </div>
                  
                  <tbody>
                    <tr v-for="(list, index) in waitingConfirmation.slice(-6)" :key="index"
                      style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">
                      <td scope="row" class="px-4 border-0" style="font-size: 0.85rem">
                        {{ list.first_name }} <br />
                        {{ list.last_name }}
                      </td>

                      <td class="px-4 border-0" style="font-size: 0.85rem; transform: translateY(10px)">
                        {{ list.classroom_grade }} -
                        {{ list.classroom_speciality }}
                        {{ list.classroom_number }}
                      </td>
                      <td class="px-4 border-0" style="padding-top: 22px">
                        <a title="Cliquer pour visualier le reçu" target="_blank" :href="list.receipt_url"
                          style="font-size: 0.85rem">
                          <i class="fas fa-file-pdf" aria-hidden="true"></i>
                          {{ $t("dashboard.receipt") }}</a>
                      </td>
                      <td class="text-end px-4 position-relative border-0" style="padding-top: 22px">
                        <span style="font-size: 0.85rem" class="amount">
                          XAF {{ Number(list.amount_paid).toLocaleString() }}
                        </span>
                        <div class="action position-absolute top-0 mx-4">
                          <!--
                  <router-link
                    :to="{
                      name: 'detail-config',
                      params: { id: list.id },
                    }"
                  >
                    <button
                      class="btn btn-link text-secondary mb-0 text-xl"
                      title="Créer des tranches"
                    >
                      <i class="fa fa-plus text-success" aria-hidden="true"></i>
                    </button>
                  </router-link>
                  -->

                          <button class="btn btn-link text-secondary mb-0" title="Detail du paiement"
                            data-bs-toggle="modal" data-bs-target="#deletepaymentModal" @click="openModal(list)">
                            <i class="fa fa-eye text-success" style="
                                font-size: 18px;
                                transform: translateY(18px);
                              " aria-hidden="true"></i>
                          </button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>

                <div class="else text-center pb-5" v-else>
                  <div class="container">
                    <div class="text mt-3">
                      <img class="image" src="@/assets/img/paiementlist.png" alt="" />
                      <h5 class="mt-4">
                        {{ $t("dashboard.title-no-payment") }}
                      </h5>
                      <p class="mt-4">{{ $t("dashboard.text-no-payment") }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--------tablae ------->
          </div>
          <div class="col-lg-5">
            <div class="card eska-parent mb-2">
              <h6>{{ $t("dashboard.parents_number") }}</h6>

              <div class="row">
                <div class="content-1 col-md-6">

                  <div class="number mt-3">{{ parentsCount }}</div>

                </div>
                <!-- Bouton déroulant -->
                <div class="dropdown mt-5  col-md-6 text-end ">
                  <button
                    style="background:transparent; border:1px solid rgba(255, 255, 255, 0.4); margin-top: -25px;height: 50px;"
                    class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    {{ $t("dashboard.share_link") }}
                  </button>
                  <ul class="dropdown-menu" id="toggle-btn" aria-labelledby="dropdownMenuButton">
                    <li>
                      <a class="dropdown-item" :title="$t('dashboard.facebook')"  @click="shareOn('facebook')" href="#">
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a :title="$t('dashboard.x')" class="dropdown-item" @click="shareOn('x')" href="#">
                        X
                      </a>
                    </li>
                    <li>
                      <a :title="$t('dashboard.linkedin')" class="dropdown-item" @click="shareOn('linkedin')" href="https://www.eskalearning.com/download?src=sk">
                        LinkedIn
                      </a>
                    </li>
                    <li class="border-0">
                      <a :title="$t('dashboard.copy')" class="dropdown-item" @click="copyLink" href="#">
                        {{ $t("dashboard.copy_link") }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>


            </div>
            <Carousel />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/widget/Cards/Card.vue";
import Carousel from "./components/Carousel.vue";
import RefusePayment from "./components/RefusePayment.vue";
import ConfirmPayment from "./components/ConfirmPayment.vue";
import PaymentPendingDetail from "./components/PaymentPendingDetail.vue";
import { getdashboardreport, getParentsnumber } from "@/utils/services";
import Spinner from "../components/Spinner.vue";


import api_config from "@/config";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "dashboard-default",

  props: {
    url: {
      type: String,
      required: true,
    },
    navText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show_detail_payment: true,
      show_raison_delete: false,
      show_confirmation: false,
      parentsCount: 0,
      dashboardData: null,
      loading: false,
      infoschool: null,
      school_id: '',
      yellow: "#e1b12c",
      name: "",
      grade: "",
      amount: "",
      waitingConfirmation: [],
      selectedPayment: null,
      isOffline: !navigator.onLine,
      showAlert: false,
      linkToShare:'https://www.eskalearning.com/download?src=sk',
      stats: {
        money: {
          title: "",
          number_students: "",
          value: "",
          iconClass: "ni ni-money-coins",
          iconBackground: "bg-gradient-success",
          Colorspan: "text-white",
        },
        users: {
          title: "",
          value: "",
          number_students: "",
          iconClass: "ni ni-money-coins",
          iconBackground: "bg-gradient-danger",
          valueColor: "bg-gradient-success",
          Colorspan: "text-white",
        },
        clients: {
          title: "",
          value: "",
          number_students: "",
          percentage: "-2%",
          iconClass: "fas fa-money-check-alt",
          iconBackground: "bg-gradient-warning",
          detail: "since last quarter",
          Colorspan: "text-white",
        },
        sales: {
          title: "",
          number_students: "",
          value: "",
          iconClass: "fas fa-exclamation",
          iconBackground: "bg-y-gradient",
          detail: "than last month",
          Colorspan: "text-white",
        },
      },
    };
  },



  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    
    this.fetchDashboardData();

    this.school_id = localStorage.getItem("schoolId");
    this.fetchgetparentsnumber();


  },


  methods: {

 





    shareOn(platform) {
  let url = '';
  const link = encodeURIComponent(this.linkToShare);

  switch (platform) {
    case 'facebook':
      url = `https://www.facebook.com/sharer/sharer.php?u=${link}`;
      break;
    case 'x':
      url = `https://twitter.com/intent/tweet?url=${link}`;
      break;
    case 'linkedin':
      url = `https://www.linkedin.com/shareArticle?mini=true&url=${link}`;
      break;
  }

     window.open(url, '_blank');
    },
    copyLink() {
      navigator.clipboard.writeText('https://www.eskalearning.com/download?src=sk').then(() => {
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 5000); // 5 secondes avant de cacher l'alerte
      });
    },

    async fetchgetparentsnumber() {
      const school_id = this.school_id;
      console.log("Fetching parents for school:", school_id);
      
      try {
        let response = await getParentsnumber(school_id);
        console.log("Parents API Response:", response);
        this.parentsCount = response.parent_count;
        
     
      
       
      } catch (error) {
        console.error("Error during getconnection call:", error);
      }  
    },

    openModal(list) {
      this.show_detail_payment = true;
      this.show_raison_delete = false;
      this.show_confirmation = false;

      this.selectedPayment = list;
    },

    rejected() {
      this.show_detail_payment = false;
      this.show_raison_delete = true;
    },

    cancel() {
      this.show_detail_payment = true;
      this.show_raison_delete = false;
    },   
     confirm() {
      this.show_detail_payment = false;
      this.show_raison_delete = false;
      this.show_confirmation = true;
    },

    async fetchDashboardData() {
      try {
        this.loading = true;
        this.dashboardData = await getdashboardreport();


        if (this.dashboardData) {
          this.waitingConfirmation = this.dashboardData.waiting_confirmation;


          // Le reste de votre code...

          // Total_paid
          this.stats.money.value = `${api_config.CURRENCY} ${this.dashboardData.total_paid &&
            this.dashboardData.total_paid.amount
            ? Number(this.dashboardData.total_paid.amount).toLocaleString()
            : "0"
            }`;

          this.stats.money.number_students = `${this.dashboardData.total_paid &&
            this.dashboardData.total_paid.amount
            ? Number(this.dashboardData.total_paid.count).toLocaleString()
            : "0"
            }`;

          // Due_date
          this.stats.users.value = `${api_config.CURRENCY} ${this.dashboardData.payment_overdue &&
            this.dashboardData.payment_overdue.amount !== undefined
            ? Number(this.dashboardData.payment_overdue.amount).toLocaleString()
            : "0"
            }`;

          this.stats.users.number_students = `${this.dashboardData.payment_overdue &&
            this.dashboardData.payment_overdue.count !== undefined
            ? Number(this.dashboardData.payment_overdue.count).toLocaleString()
            : "0"
            }`;

          // Pending
          this.stats.clients.value = `${api_config.CURRENCY} ${this.dashboardData.pending &&
            this.dashboardData.pending.amount !== undefined
            ? Number(this.dashboardData.pending.amount).toLocaleString()
            : "0"
            }`;

          this.stats.clients.number_students = `${this.dashboardData.pending &&
            this.dashboardData.pending.count !== undefined
            ? Number(this.dashboardData.pending.count).toLocaleString()
            : "0"
            }`;




          // No receipt confirmation
          this.stats.sales.number_students = `${this.dashboardData.no_receipt_confirmation &&
            this.dashboardData.no_receipt_confirmation !== undefined
            ? Number(
              this.dashboardData.no_receipt_confirmation.count
            ).toLocaleString()
            : "0"
            }`;

          this.stats.sales.value = `${api_config.CURRENCY} ${this.dashboardData.pending &&
            this.dashboardData.no_receipt_confirmation.amount !== undefined
            ? Number(
              this.dashboardData.no_receipt_confirmation.amount
            ).toLocaleString()
            : "0"
            }`;
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        this.loading = false;
      }
    },
  },

  components: {
    Card,
    Carousel,
    ArgonButton,
    RefusePayment,
    PaymentPendingDetail,
    Spinner,
    ConfirmPayment,

  },
};
</script>

<style scoped>
#toggle-btn {
  padding: 0;
  border-radius: 0px;
  position: absolute;
  border: 1px solid rgba(250, 250, 250, 0.4);
  right: -70px;
}

#toggle-btn li {
  border-bottom: 1px solid rgba(250, 250, 250, 0.4);
  height: 50px;
  padding-top: 10px;
  transition: 0.3s;
}

#toggle-btn li:hover {
  background: rgba(0, 0, 0, 0.3);
}

#toggle-btn li .dropdown-item {
  color: white;
}

#toggle-btn li .dropdown-item:hover {
  background: transparent;
}

.eska-parent {
  height: 30%;
  padding: 20px 20px;
}

.eska-parent .number {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  border-radius: 100%;
  padding: 7px 10px;

}

.bg-custom-yellow {
  background-color: var(--ma-couleur-jaune);
}

.table tbody tr td {
  position: relative;
}

.table tbody tr td .button {
  position: absolute;
  right: 15px;
  height: 100%;
  top: 0;
  display: none;
}

.table tbody tr {
  transition: 0.7s;
}

.table tbody tr:hover {
  background: rgba(0, 0, 0, 0.3);
}

.table tbody tr:hover td .button {
  display: block;
}

.table tbody tr:hover td .amount p {
  filter: blur(2px);
}

.modal .modal-dialog {
  max-width: 70%;
  width: 70%;
  margin-left: 23%;
  height: 60vh;
}

.modal .modal-dialog .modal-content {
  background: transparent;
}

@media (max-width: 767px) {
  .table-responsive {
    overflow-x: auto;
  }

  .card .t-btn {
    text-align: start;
  }

  .modal .modal-dialog {
    max-width: 90%;
    width: 90%;
    margin-left: 5%;
    height: 80%;
  }
}

.card-icon {
  background: linear-gradient(to bottom, #ffcd66, #fbad41);
}

@media (min-width: 768px) {
  .table-responsive {
    overflow-x: hidden;
  }
}
</style>
