<!-- Logout.vue -->
<template>
  <div>
    <p>Vous êtes déconnecté. Redirection vers la page de connexion...</p>
  </div>
</template>

<script>
import { logoutUser } from "@/utils/auth";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "Logout_vue",
  created() {
    this.logout();
  },
  methods: {
    logout() {
      logoutUser();

      document.body.classList.remove("modal-open");
          const modalBackdrops =
            document.getElementsByClassName("modal-backdrop");
          for (const backdrop of modalBackdrops) {
            backdrop.parentNode.removeChild(backdrop);
          }

      const message = this.$t("logout.success");
      toast.success(message, {
        autoClose: 3000,
       });


     
        


     
  
     
    },
  },
};
</script>
