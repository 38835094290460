<template>

 
  <nav aria-label="breadcrumb" style="transform: translateY(20px)">
    <h5
      v-if="currentPage === 'welcome'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
      {{ $t("dashboard.welcome") }}
    </h5>

    <h5
      v-if="currentPage === 'class-details'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
      {{ $t("classdetails.page") }}
    </h5>
    <h5
      v-if="currentPage === 'classrooms'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
      {{ $t("classroom.page") }}
    </h5>
    <h5
      v-if="currentPage === 'payment-config'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
      {{ $t("payment.page") }}
    </h5>
     
    <h5
      v-if="currentPage === 'accounts'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    {{ $t("accounts.page") }}
    </h5>
    <h5
      v-if="currentPage === 'new-account'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
     {{ $t("newaccounts.page") }}
    </h5>
    <h5
      v-if="currentPage === 'create-payment-config'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
      {{ $t("newpayment.page") }}
    </h5>
    <h5
      v-if="currentPage === 'detail-config'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
      <span>
        <router-link :to="{ name: 'payment-config' }">{{ $t("installment.group") }} </router-link> /
      </span>
      <span>{{ $t("installment.page") }} </span>
    </h5>

    <h5
      v-if="currentPage === 'update-config'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
      <span>
        <router-link :to="{ name: 'payment-config' }">{{ $t("updatepayment.group") }} </router-link> /
      </span>
      <span>{{ $t("updatepayment.page") }}</span>
    </h5>

    <h5
      v-if="currentPage === 'new-class'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
       
    {{ $t("new_class.page") }}
    </h5>
    <h5
      v-if="currentPage === 'new-student'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    {{ $t("new_student.page") }}
    </h5>

    <h5
      v-if="currentPage === 'list-payment'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
      {{ $t("paymentpending.page") }}
    </h5>

    <h5
      v-if="currentPage === 'settled-students'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    {{ $t("no_bill_payment.page") }}
    </h5>
    <h5
      v-if="currentPage === 'classroom-receipts'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
     {{ $t("receipt_list.page") }}
    </h5>

    <h5
      v-if="currentPage === 'classes-students-payment-settled'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    {{$t("title.page_noreceipt")}}
    </h5>

    <h5
      v-if="currentPage === 'classes-students-payment-overdue'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    {{$t("title.page_overdue")}}
    </h5>

    <h5
      v-if="currentPage === 'classes-students-payment-pending'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
       {{ $t('title.page_pending') }}
    </h5>


    <h5
      v-if="currentPage === 'historique-transaction'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    {{$t("transaction.page")}}
    </h5>

    <h5
      v-if="currentPage === 'only-payment-form'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    {{ $t("single_payment.page") }}
    </h5>
    <h5
      v-if="currentPage === 'notes'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    Notes des élèves
    </h5>

    <h5
      v-if="currentPage === 'sendnote'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    Notes des élèves
    </h5>

    <h5
      v-if="currentPage === 'transfer'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    {{ $t("transfer.page") }}
    </h5>


    <h5
      v-if="currentPage === 'recurent-payment-form'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    {{ $t("recurring_payment.page") }}
    </h5>

    <h5
      v-if="currentPage === 'update-only-payment'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    {{ $t("single_payment.page") }}
    </h5>


    <h5
      v-if="currentPage === 'update-recurent-payment'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    {{ $t("recurring_payment.page") }}
    </h5>

    <h5
      v-if="currentPage === 'message'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
     Messagerie enrichie
    </h5>

    <h5
      v-if="currentPage === 'start-message'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
     Messagerie enrichie
    </h5>



     

    <h5
      v-if="currentPage === 'update-class'"
       
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
      <span>
        <router-link :to="{ name: 'classrooms' }"> {{ $t("classroom.page") }} </router-link> /
      </span>
      <span> {{ $t("update_class.page") }} </span>
    </h5>

    <h5
      v-if="currentPage === 'import-students'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    {{ $t("import_student.page") }}
    </h5>

    <h5
      v-if="currentPage === 'pending-students'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
     {{ $t("payment_pending.page") }}
    </h5>

    <h5
      v-if="currentPage === 'overdue-students'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
    {{ $t("duedate_payment.page") }}
    </h5>

    <h5
      v-if="currentPage === 'other-payment'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
      {{ $t("other_payment.page") }}
    </h5>

    <h5
      v-if="currentPage === 'notifications'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
       Notifications
    </h5>

    <h5
      v-if="currentPage === 'teachers-list'"
      class="mb-0 font-weight-bolder"
      :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
    >
       Enseignants
    </h5>

     


  </nav>
</template>

<script>
import api_config from "@/config";

export default {
  name: "bread-crumbs",
  
  props: {
    currentPage: {
      required: true,
    },
    textWhite: {
      type: String,
    },
    serviceName: {
      type: String,
      default: api_config.SERVICE_NAME,
    },
  },

  mounted() {
    // Accédez aux paramètres de l'URL pour récupérer la valeur de grade_code
    this.grade_code = this.$route.params.id;
  },
  methods: {
    handleGradeCodeUpdate() {
      // Faites quelque chose lorsque l'utilisateur clique sur grade_code
      console.log(`ID de la configuration: ${this.grade_code}`);
      // Vous pouvez également utiliser cette valeur pour mettre à jour le titre, etc.
      document.title = `Détail de la configuration - ID ${this.grade_code}`;
    },
  },
};
</script>
