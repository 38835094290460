<template>
    <div class=" pt-0 pb-4  container-fluid">
      <div class="row  py-0">
        <div class="col-lg-12 pt-4 ">
          
          <div class="row  ">
            <classes-list-card2 />

          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ClassesListCard2 from "@/views/Classroom/ClassListCard2";
  
  export default {
    name: "school-classes-Students-Pending",
    data() {
      return {
        stats: {
          money: {
            title: "Today s Money",
            value: "$53,000",
            percentage: "+55%",
            iconClass: "ni ni-money-coins",
            detail: "since yesterday",
            iconBackground: "bg-gradient-primary",
          },
          users: {
            title: "Today s Users",
            value: "2,300",
            percentage: "+3%",
            iconClass: "ni ni-world",
            iconBackground: "bg-gradient-danger",
            detail: "since last week",
          },
          clients: {
            title: "New Clients",
            value: "+3,462",
            percentage: "-2%",
            iconClass: "ni ni-paper-diploma",
            percentageColor: "text-danger",
            iconBackground: "bg-gradient-success",
            detail: "since last quarter",
          },
          sales: {
            title: "paiement approuvés Sans confirmation de facture",
            detail: "Sans confirmation de facture",
            value: "40",
            percentage: "+5%",
            iconClass: "fas fa-money-check-alt",
            iconBackground: "bg-gradient-warning",
          },
        },
      };
    },
    components: {
      ClassesListCard2,
    },
  };
  </script>
  
  <style scoped>
  .items {
    margin-top: 10vh;
  }
  </style>
  