<template>
  <div class="p-5 container-fluid">
    <div class="description">
      <div class="student-form-title">
        <h2>Edit  user</h2>
      </div>
      <div class="student-form">
        <form class="form col-xs-7 col-md-6 col-lg-7">
           
          <div class="mb-3">
            <label> Phone number ( used on Eska ) <em style="color: #f60000">*</em></label>
            <argon-input class="p-1" placeholder="Phone number"></argon-input>
          </div>

          <div class="mb-3">
            <label>Rôle <em style="color: #f60000">*</em></label>
            <div class="check d-flex">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  User
                </label>
              </div>
              <div class="form-check mx-4 ">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                 
                />
                <label class="form-check-label" for="flexCheckChecked">
                  Admin
                </label>
              </div>
            </div>
          </div>

          <div class="row p-1 mt-5 g-3">
            <div class="col-lg-6">
              <argon-button size="lg" color="warning" full-width>Cancel</argon-button>
            </div>
            <div class="col-lg-6">
              <argon-button size="lg" color="success" full-width>Save</argon-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script>

import ArgonInput from "@/components/ArgonInput";
import ArgonButton from "@/components/ArgonButton";
 
export default {
  name: "New-Account",
     components: { ArgonButton, ArgonInput},
  }

</script>



<style scoped lang="scss">
.student-form {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

.student-form-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}



</style>