<template>

 
  <!-- Modal -->
  <div class="modal fade" id="exampleModal46" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="card">
          <div class="modal-header" style="border-bottom: 1px solid rgba(250,250, 250, 0.4);">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button type="button" @click="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" style="margin-top:40px; margin-bottom: 60px;">
            <div class="text text-center">
              <h5>{{ $t("import_student.title_modal") }}</h5>
              <p class="mt-4">{{ $t("import_student.text_modal") }}</p>
              <div class="mt-4 p-0 mx-auto" style="width:600px">
                <div class="d-flex" style="justify-content: space-between;">
                  <div class="">
                    <button class="generate" @click="showgenerate">
                      <span style="color: white; font-weight: 600;">{{ $t("import_student.btn1") }}</span>
                    </button>
                  </div>
                  <div class="ms-4">
                    <button class="generate">
                      <a :title="$t('import_student.title_download')"
                        href="https://static.ikwen.com/scolarfleet/sample_students_tnp.xlsx" @click="close" download>
                        <span style="color: white; font-weight: 600;">{{ $t("import_student.btn2") }}</span>
                      </a>
                    </button>
                  </div>
                </div>

                <div class="mx-auto text-start p-0 mt-4" style="width:max-content; width:600px;" v-if="show_generate">
                  <label for="number">{{ $t("import_student.label") }}<em style="color: #f60000">*</em></label>
                  <div class="input-group w-100 mb-3">
                    <input type="number" v-model="count" class="form-control" style="height: 50px"
                      :placeholder="$t('import_student.placeholder')" :class="{
                        required: requiredFields.count,
                        'required-thick': requiredFields.count,
                      }" />
                    <div class="dropdown" id="dropdownMenuButton1" :title="$t('import_student.title_number')">
                      <button class="btn bg-success text-light fw-bold dropdown-toggle" type="button"
                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ $t("new_student.gendered") }}
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li class="nav-link text-light fw-bold"
                          style="border-bottom: 1px solid rgba(250, 250, 250, 0.6)"
                          @click="fetchAndDownloadRegistrationNumbers(false)">{{ $t("import_student.old_student") }}
                        </li>
                        <li class="nav-link text-light fw-bold" @click="fetchAndDownloadRegistrationNumbers(true)">{{
                          $t("import_student.new_student") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="d-flex ms-2" v-if="show_generate && downloadLink && !isGenerating"
                  style="margin-top: -25px;">
                  <p class="mt-2">  {{ $t("import_student.text_upload") }}</p>
                  <a :href="downloadLink" download="matricules.xlsx" style="background: #75acff;"
                    class=" btn mx-3" :title="$t('import_student.title_download')"><i style="color:white;" class="fa fa-download"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" style="border-top: 1px solid rgba(250,250, 250, 0.5);">
            <argon-button type="button" class="btn mx-5" color="danger" variant="gradient" data-bs-dismiss="modal"
              style="width: 150px; height: 50px; padding-top: 15px" @click="close">
              {{ $t("button.close") }}
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Fin Modal -->




  <div class="p-4 container-fluid mt-6">
    <div class="row">
      <div class="left col-6">
        <div class="description mb-5">
          <p class="w-80 text-justify">
           {{ $t("import_student.top_text") }}
          </p>
          <argon-button data-bs-toggle="modal" class="px-5" data-bs-target="#exampleModal46" color="success" size="xl"
            style="background: #75acff; margin-top: 20px; height: 50px; padding-top:14px;">
            {{ $t("import_student.btn_model") }}
          </argon-button>

        </div>
      </div>
    </div>

    <!-- Section pour afficher les erreurs -->
    <div class="custom-alert mt-4 mb-4" v-if="errorMessages.length">
      <ul>
        <li v-for="(error, index) in errorMessages" :key="index">{{ error }}</li>
      </ul>
    </div>

    <drop-zone @change="selectedFile" @drop.prevent="drop"></drop-zone>
    <div class="title-info">
      <h6 v-if="dropzoneFile">
        File:&nbsp; <span class="opacity-6 heart-animation">{{ dropzoneFile.name }}</span>
      </h6>
      <h6 v-else>
        File:&nbsp; <span class="opacity-6">_ _ _ _ _ _ _ _ _ _ </span>
      </h6>
      <br />
      <span class="text-warning">{{ validFile }}</span>
    </div>
    <div v-if="isValidFile" id="import" ref="buttonsSection">
      <div class="import-student">
        <div class="row">
          <div class="col-lg-6">
            <argon-button click-event="" color="warning" @click="resetFile" full-width size="lg"
              style="height: 50px; padding-top: 14px; font-size: 15px">{{ $t("button.cancel") }}
            </argon-button>
          </div>
          <div class="col-lg-6">
            <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="success" full-width
              size="lg" :clickEvent="button" @click="importStudent"
              style="height: 50px; padding-top: 14px; font-size: 15px">
             
                
                {{ $t("button.save") }}
              
               
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import DropZone from "@/components/DropZone";
import { nextTick } from "vue";
import ArgonButton from "@/components/ArgonButton";
import { batchCreateStudent, uploadFile, getregisternumberslist } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import * as XLSX from 'xlsx';

const body = document.getElementsByTagName("body")[0];

export default {
  name: "import-student",
  components: { ArgonButton, DropZone },
  data() {
    return {
      button: "",
      loading: false,
      classroom_id: this.$route.params.classroomId,
      school_year: new Date().getFullYear(),
      buttonActive: false,
      showConfirmation: false,
      errorMessages: [],
      paymentType: '',
      count: '',
      isGenerating: false,
      show_generate: false,
      downloadLink: "",
      isNewStudent: "",
      requiredFields: {
        count: false,
      },
      fileURL: "",
      fileType: "",
      dropzoneFile: null,
      validFile: "",
    };
  },
  computed: {
    isValidFile() {
      return this.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
  },
  mounted() {
    this.loadPaymentType();
  },
  methods: {
    
    close() {
      this.show_generate = false;
      this.downloadLink = false;
      document.body.classList.remove("modal-open");
      const modalBackdrops = document.getElementsByClassName("modal-backdrop");
      for (const backdrop of modalBackdrops) {
        backdrop.parentNode.removeChild(backdrop);
      }
    },

    showgenerate() {
      this.show_generate = true;
    },

    loadPaymentType() {
      this.paymentType = localStorage.getItem('payment_type');
    },

    resetFile() {
      this.dropzoneFile = null;
      this.fileURL = "";
      this.fileType = "";
      this.validFile = "";
      window.location.reload();
    },

    scrollToButtons() {
      nextTick(() => {
        if (this.$refs.buttonsSection) {
          this.$refs.buttonsSection.scrollIntoView({ behavior: "smooth" });
        }
      });
    },

    async drop(e) {
      this.dropzoneFile = e.dataTransfer.files[0];
      this.fileType = this.dropzoneFile.type;
      if (!this.isValidFile) {
        this.validFile = "Le fichier doit être au format xlsx uniquement.";
      } else {
        this.validFile = "";
      }
      let res = await uploadFile(this.dropzoneFile);
      this.fileURL = res.path;
      if (this.isValidFile) {
        this.scrollToButtons();
      }
    },

    async selectedFile() {
      this.dropzoneFile = document.querySelector(".dropzoneFile").files[0];
      this.fileType = this.dropzoneFile.type;
      if (!this.isValidFile) {
        this.validFile = "Le fichier doit être au format xlsx uniquement.";
      } else {
        this.validFile = "";
      }
      let res = await uploadFile(this.dropzoneFile);
      this.fileURL = res.path;
      if (this.isValidFile) {
        this.scrollToButtons();
      }
    },

    async importStudent() {
      this.loading = true;

      let data = {};
      this.button = "disabled";
      data["classroom_id"] = this.classroom_id;
      data["school_year"] = this.school_year;
      data["file"] = this.fileURL;
      data["payment_type"] = this.paymentType;

      try {
        let res = await batchCreateStudent(data);

        if (res.status === 201) {
          if (res.data.errors && res.data.errors.length) {
            this.errorMessages = res.data.errors;
          } else {
            this.errorMessages = [];
            if (!this.showConfirmation) {
              this.showConfirmation = true;
              const message = "Liste des élèves ajoutés avec succès";
              toast.success(message, {
                autoClose: 3000,
              });
              setTimeout(() => {
                this.showConfirmation = false;
                this.$router.push({
                  name: "class-details",
                  params: { classId: this.$route.params.classroomId },
                });
              }, 3000);
            }
          }
        }
      } catch (error) {
        this.errorMessages = [];
        if (error.response && error.response.data && error.response.data.errors) {
          this.errorMessages = error.response.data.errors;
        } else {
          this.errorMessages.push("Erreur lors de l'importation des étudiants. Veuillez réessayer.");
        }
      } finally {
        this.loading = false;
        this.button = "";
        this.buttonActive = false;
      }
    },

    async fetchAndDownloadRegistrationNumbers(isNewStudent) {
      if (!this.count) {
        this.requiredFields.count = true;
        return;
      }

      const data = {
        count: parseInt(this.count),
        is_new_student: isNewStudent
      };

      this.isGenerating = true;
      try {
        const response = await getregisternumberslist(data);
        if (response.error) {
          const message = this.$t("import_student.error_message");
          toast.error(message, {
            autoClose: 3000,
          });
        } else {
          this.generateExcelFile(response);
        }
      } catch (err) {
        this.error = "Erreur lors de la récupération des matricules.";
      } finally {
        this.isGenerating = false;
      }
    },

    generateExcelFile(data) {
      const headers = ['Registration number', 'First name', 'Last name', 'Gender', 'Pob', 'Dob'];
      const rows = data.map(num => [num, '', '', '', '', '']);

      const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Matricules");

      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);

      this.downloadLink = url;
      setTimeout(() => URL.revokeObjectURL(url), 10000);
    },
  },

  watch: {
    dropzoneFile(newFile) {
      if (newFile && this.isValidFile) {
        this.scrollToButtons();
      }
    }
  },

  created() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>


<style lang="scss" scoped>
.title-info {
  margin-top: 32px;
}

.heart-animation {
  text-align: center;
  padding: 12px 24px;
  color: #fff;
  border-radius: 5px;
  background: #F34D4D;
  box-shadow: 0 0 0 0 rgba(255, 71, 84, .7);
  animation: battement 2s infinite cubic-bezier(.66, 0, 0, 1);
  text-decoration: none;
}

@keyframes battement {
  to {
    box-shadow: 0 0 0 30px rgba(255, 71, 84, 0);
  }
}

#import {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;

  .import-student {
    width: 50%;
  }
}

.custom-alert {
  border: 1px solid #c45341;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0.8;
  color: #c45341;
  padding: 15px;
}

.custom-alert ul {
  list-style-type: none;
  padding: 0;
}

.custom-alert li {
  margin: 5px 0;
  padding-left: 20px;
  position: relative;

}

.custom-alert li::before {
  content: "\f071";
  font-family: FontAwesome;
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 0;
}

#dropdownMenuButton1 {
  margin-top: 10px;
}

#dropdownMenuButton1 .btn {
  transform: translateY(-20px);
  height: 50px;
  border-radius: 0px 10px 10px 0px;
  width: 150px;
}

#dropdownMenuButton1 .dropdown-menu {
  padding: 0;
}


#dropdownMenuButton1 .dropdown-menu .nav-link {
  transition: 0.3s;
  height: 45px;
  padding-top: 13px;
  background: #404868;

}

#dropdownMenuButton1 .dropdown-menu .nav-link:hover {
  background: rgba(0, 0, 0, 0.3);
}

.required {
  border: 2px solid #f13939 !important;
  border-radius: 11px;
}

.generate {
  border: 1px solid rgba(250, 250, 250, 0.8);
  background: none;
  border-radius: 5px;
  height: 50px;
  width: 250px;
  transition: 0.3s;
  opacity: 0.8;
}

.generate:hover {
  transform: translateY(-2px)
}
</style>
