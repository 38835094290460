<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        
        <div class="row ">
        
           

          <classes-list-card1 />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassesListCard1 from "@/views/Classroom/ClassListCard1";

export default {
  name: "school-classes-Students-Settled",
  data() {
    return {
      stats: {
        money: {
          title: "Today s Money",
          value: "$53,000",
          percentage: "+55%",
          iconClass: "ni ni-money-coins",
          detail: "since yesterday",
          iconBackground: "bg-gradient-primary",
        },
        users: {
          title: "Today s Users",
          value: "2,300",
          percentage: "+3%",
          iconClass: "ni ni-world",
          iconBackground: "bg-gradient-danger",
          detail: "since last week",
        },
        clients: {
          title: "New Clients",
          value: "+3,462",
          percentage: "-2%",
          iconClass: "ni ni-paper-diploma",
          percentageColor: "text-danger",
          iconBackground: "bg-gradient-success",
          detail: "since last quarter",
        },
        sales: {
          title: "paiement approuvés Sans confirmation de facture",
          detail: "Sans confirmation de facture",
          value: "40",
          percentage: "+5%",
          iconClass: "fas fa-money-check-alt",
          iconBackground: "bg-gradient-warning",
        },
      },
    };
  },
  components: {
    ClassesListCard1,
  },
};
</script>

<style scoped>
.items {
  margin-top: 10vh;
}
</style>
