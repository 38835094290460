<template>
  <div class="py-4 container">
    <div class="row mt-3 mb-4 text-center">
      <h5 class="mb-5"> {{ $t("single_payment.title") }}</h5>

      <div class="row mt-3">
        <div class="col-md-6 col-xs-6 col-lg-7 mx-auto content text-start">
          <div class="p-3 card w-">
            <div class="card-header pb-0"></div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="mb-4">
                <label style="transform:translateY(8px)"> {{ $t("single_payment.description") }}  <em style="color: #f60000">*</em></label>
                <argon-input :placeholder="$t('single_payment.description')"  v-model="description" :class="{'input-error': showError && !description}"></argon-input>
              </div>
                   
              <div class="mb-4">
                <label style="transform:translateY(8px)">{{ $t("single_payment.amount") }}  <em style="color: #f60000">*</em></label>
                <argon-input type="number" :placeholder="$t('single_payment.amount')"  v-model="amount" :class="{'input-error': showError && !amount}"></argon-input>
              </div>

              <div class="mb-4">
                <label style="transform:translateY(8px)"> {{ $t("single_payment.due_date") }}  <em style="color: #f60000">*</em></label>
                <div class="d-flex">
                  <select class="text-center" style=" width: 40%; height: 50px; border-radius: 5px; border:none"  v-model="due_day" :class="{'input-error': showError && !due_day}">
                    <option value="">{{ $t("new_student.day") }}</option>
                    <option  v-for="day in 31" :key="day" :value="day">{{ day }}</option>
                  </select>
                  <select  class="text-center mx-3" style=" width: 40%; height: 50px; border-radius: 5px; border:none"   v-model="due_month" :class="{'input-error': showError && !due_month}">
                    <option value="">{{ $t("new_student.month") }} </option>
                    <option v-for="month in 12" :key="month" :value="month">{{ month }}</option>
                  </select>
                  <select  class="text-center" style=" width: 40%; height: 50px; border-radius: 5px; border:none;"  v-model="due_year" :class="{'input-error': showError && !due_year}">
                    <option value="">{{ $t("new_student.year") }}</option>
                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                  </select>
                </div>
              </div>

              <div class="mt-5">
                <div class="row">
                   
                  <div class="col-md-6">
                    <argon-button :loading="loading" :active="buttonActive || showConfirmation" fullWidth color="success" variant="gradient" class="my-4 mb-2" :clickEvent="button" :disabled="loading" size="lg" @click="createotherconfig">
                      {{ $t("button.save") }}
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import ArgonButton from "@/components/ArgonButton";
import ArgonInput from "@/components/ArgonInput";
import { createotherconfig } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "Only_Payment_Form",
  components: { ArgonButton, ArgonInput },
  data() {
    return {
      button: "",
      successMessage: "",
      failedMessage: "",
      loading: false,
      buttonActive: false,
      showConfirmation: false,
      is_on_off: true,
      description: "",
      amount: "",
      due_day: "",
      due_month: "",
      due_year: "",
      invoices: [],
      showError: false,
      years: Array.from({ length: 50 }, (v, k) => k + new Date().getFullYear()), // Génère une liste d'années
    };
  },
  methods: {
    async createotherconfig() {
      this.showError = false;
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        this.button = "disabled";

        if (!this.description || !this.amount || !this.due_day || !this.due_month || !this.due_year) {
          this.showError = true;
          return;
        }

        // Combine the day, month, and year into a single date string
        const formattedDueDate = `${this.due_year}-${this.due_month.toString().padStart(2, '0')}-${this.due_day.toString().padStart(2, '0')}`;

        let data = {
          is_on_off: this.is_on_off,
          description: this.description,
          amount: this.amount,
          due_date: formattedDueDate,  // Utiliser la date formatée
          invoices: this.invoices
        };

        let res = await createotherconfig(data);

        if (!navigator.onLine) {
          const message = this.$t("message.notinternet");
          toast.error(message, {
            autoClose: 3000,
          });
        } else if (res.status === 404) {
          const message = this.$t("message.user404");
          toast.error(message, {
            autoClose: 3000,
          });
        } else if (res.status === 201) {
          this.createdConfigId = res.data.id;
          this.showConfirmation = true;
          const message = this.$t("single_payment.message_success");
          toast.success(message, {
            autoClose: 3000,
          });

          setTimeout(() => {
            this.showConfirmation = false;
            this.$router.push({
              path: "/other-payment",
              query: { forceReload: true },
            });
          }, 4000);
        }
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 404) {
          toast.error(". error", {
            autoClose: 3000,
          });
        } else {
          const message = this.$t("message.requiredfile");
          toast.error(message, {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
      }
    },
  },
};
</script>


<style scoped>
content {
  margin-left: auto;
  margin-right: auto;
}

.input-error {
  border: 1px solid red;
  border-radius: 10px;
}
</style>