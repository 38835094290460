<template>
  <div class="collapse navbar-collapse w-auto h-auto h-100" id="sidenav-collapse-main">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item :url="{ name: 'welcome' }" :class="getRoute() === 'dashboard-default' ? 'active' : ''"
          :navText="$t('sidebar.dashboard')">
          <template v-slot:icon>
            <i style="font-size: 16px; transform:translateY(-3px)" class="fa fa-table text-primary  opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <hr class="mt-3 horizontal dark" />


      <li class="nav-item">
        <sidenav-item :url="{ name: 'classrooms' }" :class="getRoute() === 'classes' ? 'active' : ''"
          :navText="$t('sidebar.classroom')">
          <template v-slot:icon>
            <i class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <hr class="mt-3 horizontal dark" v-if="isLocal" />

      <!--======================= Eska ================  -->

      <div class="d-flex" v-if="isLocal" style="position: relative;">
        <img class="ms-3 mt-2" style="width: 18px; border-radius:2px;height: 18px; position:absolute; left:13px"
          src="@/assets/img/eska.png" alt="">
        <sidenav-dropdown title="Eska" class="w-90" style="transform: translateX(22px)">
          <sidenav-item class="w-100" :url="{ name: 'notes' }" :class="getRoute() === 'notes' ? 'active' : ''"
            :navText="$t('sidebar.notes')">
            <template v-slot:icon>
              <i style="font-size: 16px; transform:translateY(-3px);" class="bi bi-clipboard-data"></i>
            </template>
          </sidenav-item>
          <sidenav-item class="w-100" :url="{ name: 'message' }" :class="getRoute() === 'list-message' ? 'active' : ''"
            :navText="$t('sidebar.message')">
            <template v-slot:icon>
              <i style="font-size: 16px; transform:translateY(-3px);" class="bi bi-chat-dots-fill"></i>
            </template>
          </sidenav-item>
          <sidenav-item class="w-100" :url="{ name: 'eska' }" :class="getRoute() === 'eska' ? 'active' : ''"
            :navText="$t('sidebar.sponsoring')">
            <template v-slot:icon>
              <i style="font-size: 16px; transform:translateY(-3px);" class="fas fa-handshake opacity-10"></i>
            </template>
          </sidenav-item>
        </sidenav-dropdown>
      </div>




      <hr class="mt-3 horizontal dark" />

      <!--=============== Opérations ================  -->


      <sidenav-dropdown :title='$t("sidebar.operations")' class="w-100">

        <sidenav-item class=" w-100" :url="{ name: 'historique-transaction' }"
          :class="getRoute() === 'historique-transaction' ? 'active' : ''" :navText="$t('sidebar.history')">
          <template v-slot:icon>
            <i style="font-size: 16px; transform:translateY(-3px); color: #FFD43B;" class="fas fa-exchange-alt "></i>
           
          </template>
        </sidenav-item>
        <sidenav-item v-if="hasAdmin" class=" w-100" :url="{ name: 'transfer' }" :class="getRoute() === 'transfer' ? 'active' : ''"
          :navText="$t('sidebar.transfert')">
          <template v-slot:icon>
            <i style="font-size: 16px; transform:translateY(-3px);" class="bi bi-clock-history opacity-10"></i>
          </template>
        </sidenav-item>

      </sidenav-dropdown>


      <hr class="mt-2 horizontal dark" />


      <!--======================= Configuration ================  -->

      <sidenav-dropdown :title="$t('sidebar.configuration')">
        <sidenav-item class=" w-100" :url="{ name: 'payment-config' }"
          :class="getRoute() === 'payments' ? 'active' : ''" :navText="$t('sidebar.scolarité')">
          <template v-slot:icon>

            <i style="font-size: 16px; transform:translateY(-3px);" class="bi bi-cash-stack text-info opacity-10"></i>
          </template>
        </sidenav-item>
        <sidenav-item   class=" w-100" :url="{ name: 'other-payment' }"
          :class="getRoute() === (paymentType === 'other-payment') ? 'active' : ''"
          :navText="$t('sidebar.other-payment')">
          <template v-slot:icon>
            <i style="font-size: 16px; transform:translateY(-3px);" class="bi bi-cash-stack text-info opacity-10"></i>

          </template>
        </sidenav-item>
        <sidenav-item class=" w-100" :url="{ name: 'Parem' }" :class="getRoute() === 'Parem' ? 'active' : ''"
          :navText="$t('sidebar.params')">
          <template v-slot:icon>
            <i style="font-size: 16px; transform:translateY(-3px);" class="bi bi-gear text-info opacity-10"></i>
          </template>
        </sidenav-item>
      </sidenav-dropdown>






      <hr class="mt-3 horizontal dark" />

      <li class="mt-3 nav-item mt-2">
        <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'">
          {{ $t('sidebar.access') }}
        </h6>
      </li>
      <li class="nav-item" v-if="hasAdmin">
        <sidenav-item :url="{ name: 'accounts' }" :class="getRoute() === 'accounts' ? 'active' : ''"
          :navText="$t('sidebar.account')">
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item :url="{ name: 'informations-personnelles' }" :class="getRoute() === 'informations-personnelles' ? 'active' : ''"
          :navText="$t('sidebar.profil')">
          <template v-slot:icon>
             
            <i class="bi bi-person-circle opacity-10" style="font-size: 17px; margin-top:-3px;"></i>
          </template>
        </sidenav-item>
      </li>


      <li class="nav-item">
        <sidenav-item :url="{ name: 'logout' }" :class="getRoute() === 'logout' ? 'active' : ''"
          :navText="$t('sidebar.logout')" data-bs-toggle="modal" data-bs-target="#staticBackdrop1">
          <template v-slot:icon>
            <i class="fa fa-door-open text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
    </ul>
  </div>

  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <hr class="mt-3 horizontal dark" />

    <sidenav-card :class="cardBg" :textPrimary="$t('help.help')" :textSecondary="$t('help.check_docs')" />
  </div>


  <div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center pt-6 pb-6">

            <h6> {{ $t("logout.text") }} </h6>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" style="width: 150px; height: 50px;">{{
              $t("button.cancel") }} </button>

            <router-link to="/logout">
              <button type="button" class="btn btn-success mt-1" style="width: 150px; height: 50px;"> {{
                $t("button.confirm") }}</button>

            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";
import SidenavDropdown from "@/components/SidenavDropdown.vue";
import {  getMembers } from "@/utils/services";
import { ref, onMounted } from "vue";
export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Argon Dashboard 2",
      controls: "dashboardsExamples",
      isActive: "active",
      paymentType: '',
      isLocal: window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    };
  },
  components: {
    SidenavItem,
    SidenavCard,
    SidenavDropdown,
  },

  setup() {
    const members = ref([]);
    const loading = ref(false);

    const fetchMembers = async () => {
      try {
        loading.value = true;
        const result = await getMembers();
        if (!navigator.onLine) {
          this.internet = false;  
          this.no_internet = true;  
          alert(
            "Vous n'êtes pas connecté à Internet. Veuillez vérifier votre connexion et réessayer."
          );
          return;
        }

        if (result.status === 200) {
          members.value = result.data;
          localStorage.setItem("members", JSON.stringify(result.data));
      
          this.internet = true;
          this.no_internet = false;
        } else {
          console.error(
            "Erreur lors de la récupération des membres :",
            result.error
          );
        }
      } catch (error) {
        console.error(
          "Erreur inattendue lors de la récupération des membres :",
          error
        );
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchMembers();
    });



    return {
      members,
      loading


    };


  },

  mounted() {
    this.loadPaymentType();
  },
  computed:{
    hasAdmin() {
      return this.members.some(member => member.is_staff);
    }
  },
  methods: {
    loadPaymentType() {
      this.paymentType = localStorage.getItem('payment_type');
    },

    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>

<style>
.nav-item {
  position: relative;

}

.nav-item .span {
  position: absolute;
  left: 7%;
  top: 9%;
  z-index: 10;
  display: block;
  content: "";
  height: 17px;
  width: 17px;
  background-color: #f14a4b;
  border-radius: 50%;
}
</style>
