<template>
    <div v-if="isOffline" class="offline-alert">
      <NoOnline />
    </div>
  </template>
  
  <script>
  import NoOnline from "@/views/components/NoOnline.vue";  
  
  export default {
    components: {
      NoOnline
    },
    data() {
      return {
        isOffline: !navigator.onLine,
      };
    },
 
  
    mounted() {
      window.addEventListener('online', this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);
      this.startPeriodicInternetCheck();
    },
    beforeUnmount() {
      window.removeEventListener('online', this.updateOnlineStatus);
      window.removeEventListener('offline', this.updateOnlineStatus);
      this.stopPeriodicInternetCheck();
    },
    methods: {
      updateOnlineStatus() {
        this.isOffline = !navigator.onLine;
      },
  
      async checkInternetConnection() {
        try {
          const response = await fetch("https://www.google.com", {
            mode: "no-cors",
            cache: "no-store",
            headers: { 'Cache-Control': 'no-cache' }
          });
          this.isOffline = !(response.ok || response.type === 'opaque');
        } catch (error) {
          this.isOffline = true;
        }
      },
  
      startPeriodicInternetCheck() {
        this.checkInternetConnection();
        this.internetCheckInterval = setInterval(() => {
          this.checkInternetConnection();
        }, 420000); // Vérification toutes les 7 minutes
      },
  
      stopPeriodicInternetCheck() {
        if (this.internetCheckInterval) {
          clearInterval(this.internetCheckInterval);
          this.internetCheckInterval = null;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .offline-alert {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    height: 100%;
  
    color: white;
    text-align: center;
     
  }
  </style>
  