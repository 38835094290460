<template>

    <div class="container-fluid">
         
        <div class="card text-center pt-7 pb-7" >
            <h6>Configuration autres paiements</h6>
            <p class="px-8 mt-5">
                Fuerit toto in consulatu sine provincia, cui fuerit, antequam designatus est,
                decreta provincia. Sortietur an non? Nam et non sortiri absurdum est, et, quod
                sortitus sis, non habere. Proficiscetur paludatus? Quo? Quo pervenire ante
                certam diem non licebit. ianuario, Februario,
                provinciam non habebit; Kalendis ei denique Martiis nascetur repente provincia.
            </p>

            <RouterLink to="/other-payment" class="mt-5">
                <argon-button color="success" size="lg">
                    Autres paiements

                </argon-button>
            </RouterLink>
        </div>
    </div>

</template>


<script>
import ArgonButton from "@/components/ArgonButton";
 

export default {

    data() {
        return {
            isOffline: !navigator.onLine,
        }
    },

    name: "Other_Payment_Govfees",
    components: { ArgonButton },

    methods: {
        updateOnlineStatus() {
            this.isOffline = !navigator.onLine;
            this.checkInternetConnection();
        },

        async checkInternetConnection() {
            try {
                const response = await fetch("https://www.google.com", {
                    mode: "no-cors",
                });
                if (response.ok || response.type === 'opaque') {
                    this.isOffline = false;
                } else {
                    this.isOffline = true;
                }
            } catch (error) {
                this.isOffline = true;
            }
        },
    }


}


</script>


<style></style>