<template>
      
    <router-link
  :to="{ name: 'pending-students', params: { classId: classroom_id } }"
   
  >
    <div class="mb-4 card" >
      <div class="p-3 card-body position-relative">
        <div
          class="card info bg-light position-absolute  h-40 text-dark p-2"
          style="width:400px;"
          v-if="isInfoVisible"
        >
          {{ $t("classroom.alert") }}
        </div>
        <div class="d-flex" :class="directionReverse ? reverseDirection : ''">
          <div>
            <div
              class="text-center icon icon-shape position-relative"
              style="margin-right: 15px"
              :class="`${iconBackground} ${
                this.$store.state.isRTL
                  ? 'border-radius-md'
                  : 'border-radius-2xl'
              } `"
            >
              <div
                class="payment position-absolute bg-gradient-danger"
                v-if="payment"
              >
                <p>25</p>
              </div>
               
              <h5
              v-if="classroomReport"
                class="font-weight-bolder"
                aria-hidden="true"
                style="line-height: 2.5; color: #ffffff"
              >
                {{ classroomReport.pending_payment.count }} 
              </h5>
            </div>
          </div>

          <div :class="contentClass">
            <div class="numbers">
              <h3
                class="mb-0  text-sm text-capitalize font-weight-bold"
                :class="titleColor"
              >
              <span style="opacity: 0.8;">{{ grade }} </span><br/> <span style="opacity: 0.4;">{{ speciality_code }} </span>
              </h3>
            </div>
          </div>
        </div>
        <div class="price">
          <span style="font-size: 12px; border-radius:5px;  border: 1px solid #fb8840; padding: 2px 8px"> XAF {{ Number(pending_amount).toLocaleString() }} </span>
      </div>
      </div>
      
    </div>
  </router-link>


  
</template>

<script>
import { getclassroomsconfig , getclassroomreport } from "@/utils/services";

export default {
  name: "ClassesCard2",
  data() {
    return {
      reverseDirection: "flex-row-reverse justify-content-between",
      payment: false,
      config: true,
      isInfoVisible: false,
      classroomid_list: [],
      id: "658c0a5578de24dc76cc7354",
      loading:false,
      table:[],
      classroomReport:null
    };
  },
  components: {
  },


  methods: {
    showInfo() {
      this.isInfoVisible = true;
    },
    hideInfo() {
      this.isInfoVisible = false;
    },

    async fetchConfig() {
      try {
        
        this.loading=true;
        const result = await getclassroomsconfig();

        if (result && result.length > 0) {
          this.classroomid_list = result.map((config) => config.classroom_id);
        }

        // ...
      } catch (error) {
        console.error(
          "Erreur inattendue lors de la récupération des configurations appliquées :",
          error
        );
      } finally{
        this.loading = false;
      }
    },

    async fetchClassroomReport(classroom_id) {
    try {
      this.loading = true;
      // Appeler la fonction pour récupérer les informations de la classe
      const response = await getclassroomreport(classroom_id);
      if (response) {
        this.classroomReport = response;
        console.log("Rapport de classe:", this.classroomReport);

        this.table =
          this.classroomReport.no_receipt_confirmation.receipt_without_confirmation;
        console.log("leo tag", this.table);
      }
    } catch (error) {
      console.error("Error fetching classroom report:", error);
    } finally {
      this.loading = false;
    }
  },
  },

  computed: {},

  props: {
    directionReverse: Boolean,
    configApplied: Boolean,
    grade: {
      type: String,
      required: true,
    },
    payment_pedding: {
      type: String,
      required: true,
    },
    classroom_id: {
      type: String,
      required: true,
    },
    detail: {
      type: String,
    },
    titleColor: {
      type: String,
    },
    value: {
      type: String,
    },
    student_count: {
      type: String,
    },
    pending_payment: {
      type: String,
    },
    speciality_code: {
    type: String,
    },
    valueColor: {
      type: String,
    },
    percentage: String,
    iconClass: {
      type: String,
      required: true,
    },
    percentageColor: {
      type: String,
      default: "text-success",
    },
    iconBackground: {
      type: String,
      default: "bg-white",
    },
    badgeBackground: {
      type: String,
      default: "bg-gradient-danger",
    },
    contentClass: {
      type: String,
    },

    pending_amount: {
      type: Number,
      required: true,
    }
  },
  mounted() {
    this.fetchClassroomReport(this.classroom_id);
    this.fetchConfig();
  },
};
</script>

<style scoped>
.fa-ellipsis-h {
  cursor: pointer;
}
.dropdown a:hover {
  color: #1a2035;
}

.card .card-body .payment {
  top: -10px;
  right: -20px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.card .card-body .payment p {
  font-size: 14px;
  transform: translateY(3px);
  font-weight: bold;
}
.card .info {
  top: -125px;
  right: -100px;
  border-radius: 0;
  text-align: center;
}
</style>
``
