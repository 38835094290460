<template>
  <div class="container">
    <div class="row">
      <div   class="col-md-3" v-for="item in stat_liste" :key="item.icons">
        <Router-link :to="{ path: item.link }" v-if="item.link">

       
        <div class="card" :title="item.title_card" >
            <div class="icons"><i :class="[ 'fa', item.icons ]"></i></div>
          <div class="title-card">{{ item.title }}</div>
          <div class="number">{{ item.number }}</div>
          
        </div>
      </Router-link>

      <div v-else>
        <div class="card">
            <div class="icons"><i :class="[ 'fa', item.icons ]"></i></div>
          <div class="title-card">{{ item.title }}</div>
          <div class="number">{{ item.number }}</div>
          <span  title="cliquez pour voir la liste">{{ item.list }}</span>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card_Eska",

  data() {
    return {
      stat_liste: [
        {
         icons: "fas fa-chalkboard-teacher",
          title: "Nombre d'enseignants",
          number: "200",
          link: "/teachers-list",
          title_card:"Cliquez pour voir la liste",
        },

        {
          icons: "fas fa-chalkboard-teacher",
          title: "Nombre d'élèves",
          number: "50000",
          
        },
        {
           icons: "fas fa-chalkboard-teacher",
          title: "Devoirs Envoyés",
          number: "456400",
        },

        {
          icons: "fas fa-chalkboard-teacher",
          title: "Devoirs Reçus",
          number: "410236",
        },

         
      ],
    };
  },
};
</script>

<style scoped>
.card {
  height: 200px;
  margin-top: 30px;
  text-align: center;
  padding-top: 30px;

}
.card .icons{
  color: #e74c3c;
  height: 70px;
  width: 70px;
  margin-left: auto;
  margin-right: auto;
  font-size: 25px;
  border-radius: 50%;
  padding-top:14px;
background: white;
}
.card .title-card{
    font-weight: bold;
    margin-top: 15px;
}
 

.card .title{
    font-weight: bold;
    margin-top: 15px;
}
 

.card .number{
    font-weight: bold;
    
}
 
</style>
