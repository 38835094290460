import axios from 'axios'
import api_config from "@/config";

const REST_ENDPOINT = api_config.API_ENDPOINT

export async function updateConfig(config_id, data) {
    let response = {}
    try {
        let res = await axios({
            url: REST_ENDPOINT + '/api/config/' + config_id + '/',
            method: 'PATCH',
            data: data
        })
        response.status = res.status
        response.data = res.data
    } catch (err) {
        response.status = err.response.status
        console.error('Caught an error during update config:', err)
    }
    return response
}
export async function pullGrades() {
    let response = {}
    try {
        let res = await axios({
            url: REST_ENDPOINT + '/api/pull_grades',
            method: 'GET',
        })
        if (res.status === 200){
            response.status = res.status
        }
    } catch (err) {
        response.status = err.response.status
        console.error('Caught an error during pulling grades:', err)
    }
    return response
}
export async function pullSpecialities() {
    let response = {}
    try {
        let res = await axios({
            url: REST_ENDPOINT + '/api/pull_specialities',
            method: 'GET',
        })
        if (res.status === 200){
            response.status = res.status
        }
    } catch (err) {
        response.status = err.response.status
        console.error('Caught an error during pulling specialities:', err)
    }
    return response
}