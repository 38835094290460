<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "account-register"
}
</script>

<style scoped>

</style>