<template>
  <div class="modal-dialog">
    <div class="card">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
          <h5 class="modal-title" id="staticBackdropLabel10"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close1"></button>
        </div>
        <div class="modal-body text-center pt-4 pb-4">
          <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
          <h6>{{ $t("modal_pending.title") }}</h6>
          <p class="text-justify">
            {{ $t("modal_pending.description") }}
          </p>

           

          <div class="checkbox">
            <div class="row mt-4">
              <div class="col-12 text-start mb-3">
                <div class="row px-4">
                  <div class="form-check col-6" style="padding-left: 38px">
                    <input class="form-check-input" style="margin-left:30%;" type="radio" name="flexRadioDefault"
                      id="flexRadioDefault2" @change="togglePending" ref="pendingRadio" />
                    <label style="font-size: 0.75rem; font-weight: 700" class="form-check-label"
                      for="flexRadioDefault2">
                      {{ $t("modal_pending.status_pending") }}
                    </label>
                  </div>
                  <div class="form-check col-6">
                    <input class="form-check-input" style="margin-left:25%;" type="radio" name="flexRadioDefault"
                      id="flexRadioDefault1" @change="toggleSettled" ref="settledRadio" />
                    <label style="font-size: 0.75rem; font-weight: 700" class="form-check-label"
                      for="flexRadioDefault1">
                      {{ $t("modal_pending.status_settled") }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <p v-if="showPending" id="pending">
              {{ $t("modal_pending.status_pending_description") }}
            </p>
            <p v-if="showSettled" id="settled">
              {{ $t("modal_pending.status_settled_description") }}
            </p>
          </div>


          


          <div class="table-periods mt-4 mb-5 w-60" style="margin:0 auto">
            <div class="title-p text-start">
              <h6>Sélectionner une période</h6>
            </div>
             
            <ul class="list-group mb-2" v-for="(period, index) in periods" :key="index">
              <li class="list-group-item">
                <div class="row">
                  <div class="col-1"> <input type="checkbox" v-model="selectedPeriods" :value="period.id"></div> 
                    <div class="col-6   text-end">
                      15 000
                    </div>
                  <div class="col-5 text-end">
                    {{ period.name }} / {{ period.amount }}
                  </div>
                </div>
              </li>
               
            </ul>
            

          </div>
          <div v-if="showAlert" class="alert " id="alert-modal" role="alert">
            <i class="bi bi-exclamation-triangle-fill text-warning fs-4 mx-3"></i>
            {{ $t("modal_pending.alert") }}
          </div>
        </div>
        <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
          <argon-button type="button" class="btn" color="danger" variant="gradient" title="Fermer"
            data-bs-dismiss="modal" style="width: 200px; height: 50px; padding-top: 15px" @click="annuler">
            <span>
              {{ $t("button.cancel") }}
            </span>
          </argon-button>
          <argon-button type="button" class="btn btn-custom" color="success" variant="gradient"
            style="width: 200px; height: 50px; padding-top: 15px" @click="confirmAction" :disabled="!isChoiceMade">
            {{ $t("button.confirm") }}
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";

export default {
  name: 'Curent-Modal',
  components: {
    ArgonButton
  },
  props: {
    amount: {
      type: Number,
      required: true
    },
    installmentNumber: {
      type: Number,
      required: true
    },
    due_date: {
      type: String,
      required: true
    },
    updatestudentstatut: {
      type: Function,
      required: true
    },
    updatestatutsettled: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      showPending: false,
      showSettled: false,
      selectedStatus: '',
      isChoiceMade: false,
      loading: false,
      classroomId: null,
      showAlert: false,
      periods: [
        { id: 1, name: 10, amount: "Juin" },
        { id: 2, name: 10, amount: "Juin" },
        { id: 3, name: 10, amount: "Juin" },
      ],
      selectedPeriods: []
    };
  },
  beforeMount() {
    this.classroomId = this.$route.params.classId;
  },
  methods: {
    confirmAction() {
      if (this.isChoiceMade) {
        this.showAlert = false;
        if (this.selectedStatus === 'pending') {
          this.ParentUpdatestudentstatut();
        } else if (this.selectedStatus === 'settled') {
          this.Parentupdatestatutsettled();
        }
      } else {
        this.showAlert = true;
      }
    },
    annuler() {
      this.showPending = false;
      this.showSettled = false;
      this.$refs.pendingRadio.checked = false;
      this.$refs.settledRadio.checked = false;
      this.isChoiceMade = false;
      this.showAlert = false;
    },
    togglePending() {
      this.showPending = true;
      this.showSettled = false;
      this.selectedStatus = 'pending';
      this.isChoiceMade = true;
      this.showAlert = false;
    },
    toggleSettled() {
      this.showPending = false;
      this.showSettled = true;
      this.selectedStatus = 'settled';
      this.isChoiceMade = true;
      this.showAlert = false;
    },
    ParentUpdatestudentstatut() {
      this.loading = true;
      this.updatestudentstatut();
    },
    Parentupdatestatutsettled() {
      this.updatestatutsettled();
      this.loading = true;
    }
  }
}
</script>


<style>
#pending,
#settled {
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  border: 1px solid green;
}

#alert-modal {
  border: 1px solid #83454d;
  color: #83454d;
  background: rgba(0, 0, 0, 0.6)
}
</style>