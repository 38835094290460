<template>
  <div class="title">
    <div class="container">
      <div class="row">
        <div class=" px-4 d-flex col-md-6">
          <img src="@/assets/img/eska.png" alt="" />

          <h4 >{{ $t("eska.statistics") }}</h4>
        </div>

        <div class="col-md-6">
          <div class="dropdown " id="dropdown ">
            <button class="btn  dropdown-toggle text-light fw-bold" style="background: #111c44" type="button"
              data-bs-toggle="dropdown">

              Filtre
            </button>
            <ul class="dropdown-menu " style="position:absolute; top:1000px;">
              <input type="text" v-model="searchQuery" placeholder="Search..." class="form-control dropdown-input" />
              <li v-for="item in filteredItems" :key="item.id">
                <a class="dropdown-item" href="#">
                  {{ item.text }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <CardEska />
  </div>

  <div class="container mt-5">
    <GradientLineChart />
  </div>

  <div class="container mt-5" style="display: none">
    <ConsumptionRoomChart />
  </div>

  <div class="container mt-4" style="display: none">
    <ActiveUsersChart />
  </div>
  <div class="container mt-4">
    <ConsumptionDayChart />
  </div>
</template>

<script>
import CardEska from "@/widget/Cards/CardEska.vue";
import GradientLineChart from "@/widget/Charts/GradientLineChart.vue";
import ActiveUsersChart from "@/widget/Charts/ActiveUsersChart.vue";
import ConsumptionDayChart from "@/widget/Charts/ConsumptionDayChart.vue";
import ConsumptionRoomChart from "@/widget/Charts/ConsumptionRoomChart.vue";
export default {
  name: "Eska-table",
  data() {
    return {
      searchQuery: "",
      items: [
        { id: 1, text: "Action" },
        { id: 2, text: "Another action" },
        { id: 3, text: "Something else here" },
      ],
    };
  },

  computed: {
    filteredItems() {
      return this.items.filter((item) =>
        item.text.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  components: {
    CardEska,
    GradientLineChart,
    ConsumptionDayChart,
    ConsumptionRoomChart,
    ActiveUsersChart,
  },
};
</script>

<style>
.title {
  margin-top: 4rem;
}

.title img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.title h4 {
  font-weight: bold;
  transform: translateY(17px);
  margin-left: 15px;
}

.content {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
</style>
