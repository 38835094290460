<template>



  <!-- Add the modal -->
  <div class="modal fade" id="addStudentModal_search" tabindex="-1" aria-hidden="true">
    <searchModal ref="searchModal" />
  </div>




  <div class="modal fade" id="staticBackdropsearch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <SearchModal />

          </div>
          <div class="modal-footer border-0">
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="notification-bar transition-effect" id="notificationBar" style="display: none">
    <div class="header text-center mb-0">
      <div class="row">
        <span class="col-md-4 pt-4 fw-bold text-start" style="padding-left:18px ">Notifications</span>
        <span class="col-md-4 text-dark fw-bold"></span>
        <span class="close_modal pt-3 col-md-4 pe-5 text-dark text-end  fw-bold" title="Fermé">
          <i @click="toggleNotificationBar" class="bi bi-x-circle-fill"></i>
        </span>
      </div>
    </div>
    <div class="body p-0">
      <div class="body-content border-0">
        <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
          <div>
            <span class="mt-4">
              <Spinner></Spinner>
            </span>
          </div>
        </spinner>
        <div class="" v-else-if="table.length > 0">
          <!-- Aujourd'hui -->
          <div v-if="groupedNotifications.today.length > 0">

            <div class="message actif text-justify " v-for="list in groupedNotifications.today" :key="list.id">
              <div class="row">
                <div class="col-md-2 ps-2 profil">
                  <img src="@/assets/img/logo-ct.png" alt="" />
                </div>
                <div class="col-md-10">
                  <div class="title-notif mt-2">
                    <strong>{{ list.title }}</strong>
                  </div>

                  <span :class="{ 'new-notification': !isRead(list.id), 'read-notification': isRead(list.id) }"></span>
                  <div class="text mt-4 mb-0">
                    {{ list.content }} <strong>{{ list.username }}</strong>
                  </div>
                  <div class="video p-0 mt-0 " v-if="list_video">
                    <video width="100%" controls>
                      <source src="@/assets/img/video.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div class="image mt-2" v-if="list.image">
                    <img :src="list.image" alt="" />
                  </div>

                  <div class="button d-flex p-0 ">
                    <div class="time">{{ formatDate(list.created_on) }}</div>
                    <button v-if="list.button" class="fw-bold opacity-9">Classroom</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Hier -->
          <div v-if="groupedNotifications.yesterday.length > 0">
            <h3 class="notification-group-title">Hier</h3>
            <div class="message actif text-justify mt-2" v-for="list in groupedNotifications.yesterday" :key="list.id">
              <notification-content :list="list" :isRead="isRead" :formatDate="formatDate" />
            </div>
          </div>

          <!-- 4 derniers jours -->
          <div v-if="groupedNotifications.lastFourDays.length > 0">

            <div class="message actif text-justify " v-for="list in groupedNotifications.lastFourDays" :key="list.id">
              <div class="row">
                <div class="col-md-2 ps-2 profil">
                  <img src="@/assets/img/logo-ct.png" alt="" />
                </div>
                <div class="col-md-10">
                  <div class="title-notif mt-2">
                    <strong>{{ list.title }}</strong>
                  </div>

                  <span :class="{ 'new-notification': !isRead(list.id), 'read-notification': isRead(list.id) }"></span>
                  <div class="text mt-4 mb-0">
                    {{ list.content }} <strong>{{ list.username }}</strong>
                  </div>
                  <div class="video p-0 mt-0 " v-if="list_video">
                    <video width="100%" controls>
                      <source src="@/assets/img/video.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div class="image mt-2" v-if="list.image">
                    <img :src="list.image" alt="" />
                  </div>

                  <div class="button d-flex p-0 ">
                    <div class="time">{{ formatDate(list.created_on) }}</div>
                    <button v-if="list.button" class="fw-bold opacity-9">Classroom</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Plus ancien -->
          <div v-if="groupedNotifications.older.length > 0">

            <div class="message actif text-justify " v-for="list in groupedNotifications.older" :key="list.id">
              <div class="row">
                <div class="col-md-2 ps-2 profil">
                  <img src="@/assets/img/logo-ct.png" alt="" />
                </div>
                <div class="col-md-10">
                  <div class="title-notif mt-2">
                    <strong>{{ list.title }}</strong>
                  </div>

                  <span :class="{ 'new-notification': !isRead(list.id), 'read-notification': isRead(list.id) }"></span>
                  <div class="text mt-4 mb-0">
                    {{ list.content }} <strong>{{ list.username }}</strong>
                  </div>
                  <div class="video p-0 mt-0 " v-if="list_video">
                    <video width="100%" controls>
                      <source src="@/assets/img/video.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div class="image mt-2" v-if="list.image">
                    <img :src="list.image" alt="" />
                  </div>

                  <div class="button d-flex p-0 ">
                    <div class="time">{{ formatDate(list.created_on) }}</div>
                    <button v-if="list.button" class="fw-bold opacity-9">Classroom</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="button text-center">
            <button class="mt-2" @click="notification">
              <span class="opacity-9">Voir toutes les notifications</span>
            </button>
          </div>
        </div>
        <div class="no-notif" v-else>
          <div class="container-fluid">
            <div class="content text-center">
              <img style="width:110px;" class="image" src="@/assets/img/notiff.png" alt="" />
              <h5>Aucune notification</h5>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav id="navbarBlur" v-bind="$attrs" :class="this.$store.state.isRTL ? 'top-0 position-sticky z-index-sticky' : ''"
    class="navbar  navbar-main navbar-expand-lg px-0 mx-4 mb-4 shadow-none border-radius-xl " data-scroll="true">
    <div class="px-2   container-fluid " style="height: 70px">

      <breadcrumbs :currentPage="currentRouteName" textWhite="text-white" id="page_title" />

      <div id="navbar" style="width: 50%;" :class="this.$store.state.isRTL ? 'px-0' : 'me-sm-4'"
        class=" collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4">
        <div :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'" class="pe-md-3 d-flex">


          <div class="col-md-3 d-flex " style="width:max-content">



            <!-- Add the new search bar here -->
            <div class="search-container  mt-4 " style="z-index: 10;">
              <div class="input-group position-relative "
                style="border: 1px solid  rgba(250, 250, 250, 0.4); height: 40px;">
                <input type="text" class="form-control border-0 search-input text-uppercase"
                  :placeholder="$t('navbar.search')" v-model="capitalizedSearchQuery" @input="handleSearch"
                  @focus="showSuggestions = true" @keydown.enter.prevent style="text-transform: uppercase">


                <span class="input-group-text border-0">
                  <i class="fas fa-search"></i>
                </span>

                <div class="suggestions-dropdown" v-if="showSuggestions && suggestions.length > 0">
                  <router-link v-for="(suggestion, index) in suggestions" :key="index" :to="{
                    name: 'student-details',
                    params: {
                      id: suggestion.data.student.id,
                      classroom_id: suggestion.data.classroom.id,
                    }
                  }" class="suggestion-item">
                    <div class="suggestion-title">{{ suggestion.title }}</div>
                    <div class="suggestion-subtitle">{{ suggestion.subtitle }}</div>
                  </router-link>
                </div>



                <!-- No results message -->
                <div v-else-if="searchQuery.length > 2" class="no-results  ">
                  <span> <i style="transform: translateY(6px)" class="fas fa-info-circle  me-2 text-danger fs-3"></i>
                    {{ $t("navbar.no_result") }}</span><br>

                  <argon-button data-bs-toggle="modal" data-bs-target="#addStudentModal_search" color="success"
                    size="xl" style="background: #75acff;  margin-top: 20px; transform: translateX(10px);">
                    {{ $t("navbar.add_student") }}
                  </argon-button>


                </div>
              </div>
            </div>

          </div>

          <div class="bell mt-4 px-2 mx-2" id="bell">
            <i title="Notification" class="bi bi-bell-fill" @click="toggleNotificationBar"></i>
            <span v-if="pendingNotificationsCount > 0">{{ pendingNotificationsCount }}</span>
          </div>

          <div class="row mt-2 px-2">

            <div class="input-group translate px-0 mt-3  "
              style="height:40px; width:max-content; border:1px solid rgba(250, 250, 250, 0.4);">
              <div class=" d-flex">
                <span class="px-2 pt-2 change-language-select border-0 text-body flag-custom">
                  <i aria-hidden="true" :class="`fi fi-${current}`"></i>
                </span>
                <select v-model="$i18n.locale" class="form-select border-0
              change-language-select 
              text-uppercase" @change="langChange($event)">
                  <option class="text-uppercase text-dark" v-for="locale in $i18n.availableLocales"
                    :key="`locale-${locale}`" :value="locale">
                    {{ locale }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <ul class="navbar-nav justify-content-end mt-4 nav-logout  ">
          <li class="nav-item li-logout d-flex align-items-center" :title="$t('sidebar.logout')">
            <a style="cursor: pointer; width: 40px; height: 40px; border-radius: 100%; padding-left: 13px; "
              v-if="isLoggedIn" class=" nav-link font-weight-bold text-light a-logout" data-bs-toggle="modal"
              data-bs-target="#staticBackdrop1">
              <i style="transform: translateY(-10px)" class="bi bi-box-arrow-right "></i>
            </a>
            <router-link v-else :to="{ name: 'Signin' }" class="px-0 nav-link font-weight-bold text-white "
              target="_blank">
              <i :class="this.$store.state.isRTL ? 'ms-sm-2' : 'me-sm-2'" class="fa fa-user"></i>
              <span class="d-sm-inline d-none">Sign In</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>





<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import Spinner from "../../components/Spinner.vue";
import { isLoggedIn, logoutUser } from "@/utils/auth";
import { getnotifications, Search } from "@/utils/services";
import { format, isToday, isYesterday, subDays, isAfter } from 'date-fns';
import ArgonButton from "@/components/ArgonButton";
import searchModal from "@/views/modal/modalsearch.vue";


export default {
  name: "xschool-navbar",
  data() {
    return {
      showMenu: false,
      current: this.$i18n.locale,
      table: [],
      loading: false,
      readNotifications: new Set(),
      searchQuery: '',
      showSuggestions: false,
      suggestions: [],
    };
  },
  props: ["minNav", "textWhite"],
  methods: {


    closeDropdownOnClickOutside(event) {
      const dropdown = document.querySelector('.suggestions-dropdown')
      const noResults = document.querySelector('.no-results')
      const searchInput = document.querySelector('.search-input')

      if ((dropdown || noResults) &&
        !searchInput.contains(event.target) &&
        !(dropdown?.contains(event.target)) &&
        !(noResults?.contains(event.target))) {
        this.showSuggestions = false
        this.searchQuery = ''
      }
    },

    async handleSearch() {
      if (this.searchQuery.length > 2) {
        try {
          const response = await Search(this.searchQuery);

          if (response.status === 200 && response.data && response.data.length > 0) {
            this.suggestions = response.data.map(item => ({
              title: `${item.student.first_name} ${item.student.last_name}`,
              subtitle: item.classroom ?
                `${item.classroom.grade_code} ${item.classroom.speciality_code ? item.classroom.speciality_code : ''} - Classe: ${item.classroom.number}` :
                'Ancien élève pas dans une classe actuellement',
              data: item
            }));
          } else {
            this.suggestions = [];
          }
          this.showSuggestions = true;
        } catch (error) {
          console.error('Erreur recherche:', error);
          this.suggestions = [];
        }
      }
    },





    selectSuggestion(suggestion) {
      this.searchQuery = suggestion.title;
      this.showSuggestions = false;
      // Handle navigation or action on selection
    },

    openSearchModal() {
      this.$refs.searchModal.openModal();
    },


    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),
    isLoggedIn() {
      return isLoggedIn();
    },
    isRead(notificationId) {
      return this.readNotifications.has(notificationId);
    },
    notification() {
      this.toggleNotificationBar();
      this.$router.push({
        path: "/notifications",
        query: { forceReload: true },
      });
    },
    langChange(event) {
      const selectedLocale = event.target.value;
      this.$i18n.locale = selectedLocale;
      this.current = selectedLocale === "en" ? "us" : selectedLocale;
      // Sauvegarde de la langue dans localStorage
      localStorage.setItem('selectedLocale', selectedLocale);
    },

    async logout() {
      try {
        logoutUser();
      } catch (err) {
        alert(`Error: ${err}`);
      }
    },
    toggleNotificationBar() {
      const notificationBar = document.getElementById("notificationBar");
      notificationBar.style.display =
        notificationBar.style.display === "none" ? "block" : "none";
      notificationBar.classList.toggle("show");

      // Marquer les notifications affichées comme lues
      if (notificationBar.style.display === "block") {
        this.markAllAsRead();
      }
    },
    async fetchnotifications() {
      try {
        this.loading = true;
        let notifications = await getnotifications();

        notifications.sort((a, b) => new Date(b.created_on) - new Date(a.created_on));

        this.table = notifications.slice(0, 11);
      } catch (error) {
        console.error("Error fetching notifications data:", error);
      } finally {
        this.loading = false;
      }
    },
    groupNotifications() {
      const today = new Date();

      const fourDaysAgo = subDays(today, 4);

      const grouped = {
        today: [],
        yesterday: [],
        lastFourDays: [],
        older: []
      };

      this.table.forEach(notification => {
        const notifDate = new Date(notification.created_on);
        if (isToday(notifDate)) {
          grouped.today.push(notification);
        } else if (isYesterday(notifDate)) {
          grouped.yesterday.push(notification);
        } else if (isAfter(notifDate, fourDaysAgo)) {
          grouped.lastFourDays.push(notification);
        } else {
          grouped.older.push(notification);
        }
      });

      return grouped;
    },

    formatDate(dateString) {
      return format(new Date(dateString), 'dd/MM/yyyy HH:mm');
    },
    markAsRead(notificationId) {
      this.readNotifications.add(notificationId);
      this.saveReadNotifications();
    },

    markAllAsRead() {
      this.table.forEach(notification => {
        if (notification.status === "Pending") {
          this.readNotifications.add(notification.id);
        }
      });
      this.saveReadNotifications();
    },

    saveReadNotifications() {
      localStorage.setItem('readNotifications', JSON.stringify([...this.readNotifications]));
    },

    loadReadNotifications() {
      const saved = localStorage.getItem('readNotifications');
      if (saved) {
        this.readNotifications = new Set(JSON.parse(saved));
      }

    },


  },
  components: {
    Breadcrumbs,
    Spinner,
    ArgonButton,
    searchModal

  },
  computed: {
    currentRouteName() {
      return this.$route.name;

    },
    groupedNotifications() {
      return this.groupNotifications();
    },
    ///===========filtre ========
    pendingNotificationsCount() {
      return this.table.filter(notification =>
        notification.status === "Pending" && !this.readNotifications.has(notification.id)
      ).length;
    },
    limitedNotifications() {
      return this.table.slice(0, 6);
    },
    capitalizedSearchQuery: {
      get() {
        return this.searchQuery.charAt(0).toUpperCase() + this.searchQuery.slice(1);
      },
      set(value) {
        this.searchQuery = value;
      }
    }
  },
  mounted() {
    this.fetchnotifications();
    this.loadReadNotifications();
    // Vérifie s'il y a une langue stockée dans le localStorage
    const savedLocale = localStorage.getItem('selectedLocale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
      this.current = savedLocale === "en" ? "us" : savedLocale;
    }

    document.addEventListener('click', this.closeDropdownOnClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdownOnClickOutside)
  }
};
</script>




<style lang="scss" scoped>
#navbar {
  .form-select {
    background-image: none !important;
  }

  .flag-custom {
    width: 43px !important;
    margin-left: 8px;
    border-radius: 0px 1px 0px 1px solid rgba(250, 250, 250, 0.4);

  }

  .input-group {
    width: auto;
  }
}

.desktop {
  display: none;
}

@media (max-width: 780px) {
  .desktop {
    display: none;
  }

  .translate {
    margin-top: 50px;
  }

  .nav-logout {
    transform: translateY(-30px);
  }
}

#bell {
  position: relative;
}

#bell i {
  font-size: 27px;
  cursor: pointer;
  color: white;
  transition: 0.3s;
}

#bell i:hover {
  color: #e2e1e1;
}

#bell span {
  position: absolute;
  right: 8px;
  color: white;
  background: #f5484c;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 13px;
  padding-left: 6px;
  font-weight: bold;
}

.notification-bar {
  position: absolute;
  width: 450px;
  top: 100px;
  right: 10px;
  height: 85vh;
  background: #0b122c;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 1px solid #111c44;

  z-index: 120;
}

.notification-bar .header {
  height: 70px;
  width: 100%;
  background: #0b122c;
  border-radius: 10px 0px 0px 0px;
}

.notification-bar .header span {
  color: white;
}

.notification-bar .button {
  margin-top: 20px;
}

.notification-bar .button button {
  border: none;
  height: 40px;
  width: 250px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  transition: 0.3s;
  box-shadow:
    rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.notification-bar .button button:hover {
  transform: translateY(-2px);
}

.notification-bar .header .close_modal {
  font-size: 22px;
  opacity: 0.6;
}

.notification-bar .header .close_modal i {
  cursor: pointer;
  color: white;
}

.notification-bar .body {
  padding: 0;
  height: 75vh;



}

.notification-bar .body .body-content {
  overflow-y: scroll;
  padding: 20px 10px;
  height: 100%;
  overflow-x: hidden;
  border: 1px solid rgba(250, 250, 250, 0.2);


}


.notification-bar .body .body-content .message {

  padding: 20px 10px 20px 10px;
  color: white;
  width: 100%;
  display: block;
  height: auto;
  font-size: 14px;
  border-radius: 0;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  border: 1px solid rgba(250, 250, 250, 0.2);
  border-radius: 3px;
  background: #111c44;
  margin-top: -20px;
  margin-bottom: 25px;
}

.notification-bar .body .body-content .message .profil img {
  width: 50px;
  border-radius: 50%;
  margin-left: -2px;
}

.notification-bar .body .body-content .message .title-notif {
  font-size: 16px;
  transform: translateY(5px);
  text-align: left;
  color: white;
}

.new-notification {
  background-color: #4CAF50;
  /* vert */
}

.read-notification {
  background-color: #9E9E9E;

}


.notification-bar .body .body-content .message span {
  position: absolute;
  display: block;
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  top: 10px;
  right: 10px;
}



.notification-bar .body .body-content .message .text {
  font-size: 14px;
  color: white;
}

.notification-bar .body .body-content .message .image {
  position: relative;
}

.notification-bar .body .body-content .message .image img {
  max-width: 100%;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;
  image-rendering: pixelated;
}


.notification-bar .body .body-content .message .button button {
  width: auto;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 13px;
  justify-content: space-between;

}

.notification-bar .body .body-content .message .span {
  background: white;
}

.notification-bar .body .body-content .message:hover {
  transform: translateY(-3px);
}


.search-container {
  .input-group {
    height: 35px;
    border: none;

    .search-input {
      color: white;
      background: transparent;
      border: 1px solid rgba(250, 250, 250, 0.4);
      width: 400px;

      &:focus {
        outline: none;
        box-shadow: none;
        height: 40px;
      }

      &::placeholder {
        color: white;
      }
    }

    .input-group-text {
      color: white;
      background: transparent;
      border: 1px solid rgba(250, 250, 250, 0.4);

    }
  }
}

.search-container {
  position: relative;


  .no-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 15px;
    background: #111c44;
    color: white;
    text-align: center;
 
    border-radius: 4px;
    margin-top: 5px;
    z-index: 1000;
  }


  .suggestions-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #111c44;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: 5px;
    z-index: 2000;
    max-height: 300px;
    padding-bottom: 50px;
    /* Hauteur maximale */
    overflow-y: auto;
    /* Activation du scroll */
    scrollbar-width: thin;
    /* Style fin pour Firefox */

    /* Style de la scrollbar pour Chrome/Safari */
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #0b122c;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 3px;
    }
  }

}

.change-language-select {
  background: transparent;
  color: white;
  border: 1px solid rgba(250, 250, 250, 0.4);

}

.a-logout {
  background: transparent;
  border: 1px solid rgba(250, 250, 250, 0.4);

  i {
    transform: translateY(-15px)
  }
}


.suggestion-item {
  padding: 12px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-decoration: none;
  color: white;
  display: block;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    color: white;
  }
}
</style>
