<template>
    <li class="nav-item" style="cursor:pointer; position:relative;">
      <a class="nav-link" id="link" @click="toggleDropdown" :class="{ 'show': isOpen }">
        <span style="font-weight: 700; opacity:0.8; font-size: 12px; color: white !important; text-transform: uppercase;"  class="text-light ms-1">{{ title }}</span>
        <i style="top:12px; right: 5px; position:absolute" class="fas fa-chevron-down "></i>
      </a>
      <div class="collapse" :class="{ 'show': isOpen }">
        <ul class="nav ">
          <slot></slot>
        </ul>
      </div>
    </li>
  </template>
  
  <script>
  export default {
    name: 'SidenavDropdown',
    props: {
      title: String
    },
    data() {
      return {
        isOpen: false
      }
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      }
    }
  }
  </script>

<style>

#link{
    display: flex;
    justify-content: space-between;
}

</style>