<template>
  
  <div class="p-5 container-fluid mb-10">
    <div class="description">
      <div class="student-form-title">
        <h4>  {{ $t("newaccounts.title") }} </h4>
      </div>
      <div class="student-form">
        <form class="form col-xs-7 col-md-6 col-lg-7">
          <div class="mb-3">
            <label>
              {{ $t("newaccounts.text") }}
              <em style="color: #f60000">*</em>
            </label>
            <div class="input-group">
              <div class="input-group-prepend d-flex w-100">
                <select
                  v-model="selectedPrefix"
                  class="form-control"
                  style="height: 50px; width: 70px"
                >
                  <option value="237">+237</option>
                </select>
                <argon-input
                  :placeholder="$t('newaccounts.phone')"
                  is-required
                  v-model="username"
                  :value="selectedPrefix + username"
                  :class="{
                    required: requiredFields.username,
                    'required-thick': requiredFields.username,
                  }"
                  style="width: 100%; margin-left: 15px"
                ></argon-input>
              </div>
            </div>
          </div>

          <div class="mb-3" style="display: none">
            <label>Password <em style="color: #f60000">*</em></label>
            <div class="row">
              <div class="input-group mb-3">
                <input
                  type="text"
                  v-bind:class="password !== '' ? '' : required"
                  class="form-control text-uppercase"
                  v-model="password"
                  style="height: 47px"
                  placeholder="Eg: 1011002457"
                />
                <span
                  class="input-group-text bi bi-arrow-clockwise"
                  @click="generateMat()"
                ></span>
              </div>
            </div>
          </div>

          <div class="row p-1 mt-5 g-3">
             
            <div class="col-lg-6">
              <argon-button
              :loading="loading"
              :active="buttonActive || showConfirmation"
                size="lg"
                color="success"
                full-width
                @click="createMembers"
                :clickEvent="button"
                :disabled="loading"
              >
                <span> {{ $t("button.save") }}</span>
              </argon-button>

              <argon-alert color="success" v-if="successMessage">
                <small>{{ successMessage }}</small>
              </argon-alert>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput";
import ArgonButton from "@/components/ArgonButton";
import ArgonAlert from "@/components/ArgonAlert.vue";

import { createMembers } from "@/utils/services";
import { ref } from "vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "New-Account",
  components: { ArgonButton, ArgonInput, ArgonAlert },
  data() {
    return {
      username: "",
      role: "",
      loading: false,
      button: "",
      required: "",
      failedMessage: "",
      successMessage: "",
      school_name:"",
      selectedPrefix: "237", // Préfixe par défaut
      buttonActive: false,
      showConfirmation: false,
      requiredFields: {
        username: false,
      },
    };
  },
  setup() {
    const generateRandomPassword = () => {
      const validChars = "23456789abcdefghjkmnpqrstuvwxyz";
      const passwordLength = 10;
      let newPassword = "";

      for (let i = 0; i < passwordLength; i++) {
        const randomIndex = Math.floor(Math.random() * validChars.length);
        newPassword += validChars[randomIndex];
      }

      return newPassword;
    };

    let password = ref(generateRandomPassword());

    const generateMat = () => {
      password.value = generateRandomPassword();
    };

    return {
      generateMat,
      password,
    };
  },
  methods: {
    resetForm() {
      this.password = "";
      this.username = "";
    },
    async createMembers() {
      try {
        if(this.loading){
          return;
        }
        this.loading = true;
        this.button = "disabled";

        let data = {
          username: this.selectedPrefix + this.username,
          password: this.selectedPrefix + this.username,
        };

        if (!this.username || !this.password) {
          this.requiredFields.username = true;

          if (!this.showConfirmation) {
            this.showConfirmation = true;
            const message = this.$t("message.requiredfile");
            toast.error(message, {
              autoClose: 3000,
            });
            setTimeout(() => {
              this.showConfirmation = false;
            }, 4000);
          }
          return;
        }

        if (
          this.username === "" ||
          this.username === null ||
          this.password === "" ||
          this.password === null
        ) {
          if (!this.showConfirmation) {
            this.requiredFields.first_name = true;
            this.requiredFields.registration_number = true;
            this.showConfirmation = true;
            const message = this.$t("message.requiredfile");
            toast.error(message, {
              autoClose: 3000,
            });
            setTimeout(() => {
              this.showConfirmation = false;
            }, 4000);
          }
        } else {
          let res = await createMembers(data);

          if (!navigator.onLine) {
            const message = this.$t("message.notinternet");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (res.status === 404) {
            const message = this.$t("message.user404");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (res.status === 400) {
            const message =  this.$t("newaccounts.no_eska");
            this.failedMessage = toast.error(message, {
              autoClose: 3000,
            });
            setTimeout(() => {
              this.failedMessage = this.$t("newaccounts.no_eska");
            }, 3000);
          } else if (res.status === 201) {
            if (!this.showConfirmation) {
              this.showConfirmation = true;
              const message = this.$t("newaccounts.message_success");
              toast.success(message, {
                autoClose: 3000,
              });
              setTimeout(() => {
                this.showConfirmation = false;
                this.$router.push("/accounts");
              }, 4000);
            }
          }
        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          toast.error("User not found. Please check the provided information", {
            autoClose: 3000,
          });
        } else {
          toast.error("An error occurred while creating the member", {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
      }
    },
  },
  mounted() {
    this.school_name = localStorage.getItem("school_name");
  },

};
</script>

<style scoped lang="scss">
.student-form {
  display: flex;
  margin-top: 5vh;
}

.student-form-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.required {
  border: 2px solid #f13939 !important;
  border-radius: 11px;
}

.info {
  color: #dc3545;
  font-size: 14px;
  margin-left: 5px;
}
</style>
