<template>
  <student-profile></student-profile>
</template>

<script>
import StudentProfile from '@/views/Student/StudentProfile'
export default {
  name: 'student-details',
  components: { StudentProfile }
}
</script>

<style scoped>

</style>
