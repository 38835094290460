<template>
    <!-- -------- START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT ------- -->
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
      </div>
      <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
          <p class="mb-0 text-secondary">
            Copyright ©  {{currentYear}},   - <a target="_blank" href="https://www.ikwen.com/">ikwen Ltd / scolarfleetfront</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'app-footer',
   data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>
