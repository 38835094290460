const env = process.env.NODE_ENV;
 
// Production host  eg: cm.sf-vogt.eskalearning.com
let prod_host_name = window.location.host;
let service_name = "SandDUM";
let apiBaseUrl = "https://cm.sfbe.eskalearning.com/";
if (env !== "production") {
  apiBaseUrl = "https://sandbox.scolarfleet.eskalearning.com";
  // apiBaseUrl = 'http://127.0.0.1:8001'
} else {
  const service = prod_host_name.split(".")[1];
  service_name = service.split("-")[1];
  apiBaseUrl = apiBaseUrl + service_name;
}



 
const api_config = {
  API_ENDPOINT: apiBaseUrl,
  SERVICE_NAME: service_name,
  TOKEN_ESKA:"5846c84975f0bff1895aafdb20da7315ae0570eb",
  TOKEN_XSCHOOLPAY:"87885139996d2bc9a1fb0d23f0284a8a0515b872",
  CURRENCY: "XAF",
  SETTLED: "Settled",
  ACCEPTED: "Accepted",
  OVERDUE: "Overdue",
  PENDING: "Pending",
  PAID: "Paid",
};
export default api_config;