<template >
 
    <div class="card text-center w-60 mx-auto" >
      <h5 class="mt-5 mb-3">  {{ $t("profil.title") }}</h5>
      <!--
      <p class="w-60 mx-auto" >Mettez à jour vos informations personnelles pour garder votre compte sécurisé et à jour.</p>
     -->
      <form class="w-80 text-start mx-auto mt-3 mb-6"> 
        <div class="row">
          <div class="form-group mb-3 col-6">
          <label for="name">{{ $t("profil.username") }}</label>
          <input 
            style="height: 50px;" 
            type="text" 
            class="form-control" 
            id="name" 
            placeholder="Entrez votre nom" 
            v-model="user.username"
          >
        </div>
        <div class="form-group mb-3 col-6">
          <label for="firstname">Email </label>
          <input 
            style="height: 50px;" 
            type="text" 
            class="form-control" 
            id="firstname" 
            placeholder="Entrez votre prénom" 
            v-model="user.email"
          >
        </div>
           
        </div>

         <div class="row">

          <div class="form-group mb-3 col-6">
          <label for="lastname">{{ $t("profil.first_name") }} </label>
          <input 
            style="height: 50px;" 
            type="text" 
            class="form-control" 
            id="lastname" 
            placeholder="Entrez votre nom" 
            v-model="user.lastname"
          >
        </div>
  
        <div class="form-group mb-3 col-6">
          <label for="firstname">{{ $t("profil.last_name") }}</label>
          <input 
            style="height: 50px;" 
            type="text" 
            class="form-control" 
            id="firstname" 
            placeholder="Entrez votre prénom" 
            v-model="user.firstname"
          >
        </div>


         </div>
       
       <div class="row">

        <div class="form-group mb-3 col-6">
          <label for="firstname">{{ $t("profil.phone") }} </label>
          <input 
            style="height: 50px;" 
            type="text" 
            class="form-control" 
            id="firstname" 
            placeholder="" 
            v-model="user.phone"
          >
        </div>
         <!--
        <div class="form-group mb-4 col-6">
          <label for="password">Mot de passe</label>
          <input 
            style="height: 50px;" 
            type="password" 
            class="form-control" 
            id="password" 
            placeholder="Entrez votre mot de passe" 
            v-model="user.password"
          >
        </div>
        -->

       </div>
       
  
        
         <!--
        <button style="height: 50px;" type="submit" class="btn btn-success mt-3 w-50">Sauvegarder</button>
        -->
      </form>
    </div>
  </template>
  
  <script>
 import { getinfoMembers,updateMemberInfo } from "@/utils/services";
 
  
  export default {
    name: "Info-personnelle",
    data() {
      return {
        user: {
          username: '',
          lastname: '',
          firstname: '',
          password: '',
          user:'',
          email:'',
          phone:''
        }
      };
    },


    

    async created() {
    await this.loadUserData();
  },
    
    methods: {

      async loadUserData() {
      try {
        const username = localStorage.getItem('username');
        if (username) {
          const userData = await getinfoMembers(username);
          if (userData) {
            this.user = {
              username: userData.username,
              lastname: userData.last_name,
              firstname: userData.first_name,
              email:userData.email,
              password: '', 
              phone: userData.phone
            };
          } else {
            console.error("Aucune donnée utilisateur reçue");
          }
        } else {
          console.error("Aucun nom d'utilisateur trouvé dans le localStorage");
        }
      } catch (error) {
        console.error("Erreur lors du chargement des informations de l'utilisateur:", error);
      }
    },

    async updateUserData() {
      try {
        const updatedData = {
          last_name: this.user.lastname,
          first_name: this.user.firstname,
          email: this.user.email,
          password: this.user.password !== '' ? this.user.password : undefined // N'envoie le mot de passe que s'il est défini
        };

        // Retire les champs non définis (undefined) de l'objet updatedData
        Object.keys(updatedData).forEach(
          (key) => updatedData[key] === undefined && delete updatedData[key]
        );

        const username = this.user.username;
        const updatedUser = await updateMemberInfo(username, updatedData);

        if (updatedUser) {
          alert("Informations mises à jour avec succès !");
          // Si vous avez besoin de rafraîchir les données de l'utilisateur
          await this.loadUserData();
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour des informations de l'utilisateur:", error);
        alert("Une erreur s'est produite lors de la mise à jour. Veuillez réessayer.");
      }
    }
  
 
    
       
    }
  };
  </script>
  
  <style scoped>
  /* Ajoutez des styles spécifiques ici si nécessaire */
  </style>
  