<template>
  <div class="container-fluid">
    <div class="content border-0">
      <div class="title text-center">
        <h5>Sponsorisez votre école sur Eska !</h5>
      </div>
      <div class="image text-center p-0">
        <img class="img py-0" src="@/assets/img/eska-8.png" alt="" />
      </div>

      <div class="alerte mt-0 text-center w-60">
        <p class="text">
          Votre école est déja sur Eska en <a href="#"> <span>mode libre</span></a>
          Les enseignants peuvent offrir le service et facturer comme ils
          souhaitent.
        </p>
      </div>
      <div class="paragraphe1 w-80 text-justify">
        <span class="text">
          En sponsorisant, les enseignants n’ont plus le droit de facturer leur service de suivi 
          et les parents peuvent accéder au service gratuitement ou au prix fixé par vous.
        </span>
      </div>
      <div class="paragraphe1 w-80 text-justify mt-4">
        <span class="text">
          Si vous décidez de fixer un coût pour le service, le paiement ira 
          directement au compte de l’école. Vous devrez créer une nouvelle
           entrée dans le menu <span>"Autres paiements"</span> et définir le coût 
           que vous souhaitez. Les parents recevront la facture en début de chaque mois. 
           Cela n’implique aucun changement pour l’enseignant.
        </span>
      </div>

      <div class="button text-center mt-5">
        <argon-button
          :loading="loading"
          :active="buttonActive || showConfirmation"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop27"
          type="button"
          class="mt-1"
          style="
            width: 250px;
            height: 50px;
            padding-top: 15px;
            background: #0d9b5e;
          "
        >
          Je sponsorise mon école
        </argon-button>
      </div>
    </div>
  </div>

  <!--========================= modal student numbers =================================== -->

  <div
    class="modal fade"
    id="staticBackdrop27"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div
            class="modal-header"
            style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)"
          >
            <h5 class="modal-title" id="staticBackdropLabel27"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-center">
            <div class="title" style="transform: translateY(-50px)">
              <h6 style="margin-left: auto; margin-right: auto">
                Nombre d'élève de votre école
              </h6>
            </div>

            <div
              class="text w-70"
              style="
                margin-right: auto;
                margin-left: auto;
                transform: translateY(-10px);
              "
            >
              <span style="font-size: 15px" class="text-justify p-0">
                Les offres de sponsoring sont fonction du nombre d’élèves
                 de votre établissement,
                 veuillez renseigner pour obtenir l’offre adaptée.
              </span>
            </div>
            <div class="form mb-5 mt-3">
              <form>
                <div
                  class="col-12 text-start w-70"
                  style="margin-left: auto; margin-right: auto"
                >
                  <label class="">
                    Nombre d'élève de vore école
                    <em style="color: #f60000">*</em> </label
                  ><br />
                  <input
                    id="input"
                    class="w-100 form-control"
                    type="number"
                    placeholder="Entrez le nombre"
                  />
                </div>
              </form>
            </div>
          </div>
          <div
            class="modal-footer"
            style="border-top: 1px solid rgba(255, 255, 255, 0.4)"
          >
            <argon-button
              type="button"
              class="btn"
              color="danger"
              variant="gradient"
              data-bs-dismiss="modal"
              style="width: 150px; height: 50px; padding-top: 15px"
            >
              {{ $t("button.cancel") }}
            </argon-button>

            <router-link to="/our-offers">
              <argon-button
                :loading="loading"
                :active="buttonActive || showConfirmation"
                color="success"
                type="button"
                class="mt-1"
                style="width: 150px; height: 50px; padding-top: 15px"
                @click="removemoadal"
              >
                {{ $t("button.continue") }}
              </argon-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
export default {
  name: "Eska-Sponsor",
  components: { ArgonButton },
  data() {
    return {};
  },
  methods:{
    removemoadal(){
      document.body.classList.remove("modal-open");
            const modalBackdrops =
              document.getElementsByClassName("modal-backdrop");
            for (const backdrop of modalBackdrops) {
              backdrop.parentNode.removeChild(backdrop);
            }
    }
  }
};
</script>

<style>
 
.content .title h5 {
  margin-left: auto;
  margin-right: auto;
}
.content .image .img {
  width: 150px;
  
  transform: translateY(-40px);
}
.content .alerte {
  border: 1px solid #21846c;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 50px 10px 50px;
  transform: translateY(-60px);
}
.content .alerte .text {
  font-size: 16px;
  color: #21846c;
  transform: translateY(5px);
}
.content .alerte .text a span {
  color: #21846c;
  border-bottom: 1px solid #21846c;
}
.content .paragraphe1 {
  margin-left: auto;
  margin-right: auto;
}

.content .paragraphe1 .text {
  font-size: 16px;
}
.content .paragraphe1 .text span {
  color: #3613ee;
}
#input {
  background-color: transparent;
  border: 1px solid rgba(250, 250, 250, 0.4);
  height: 50px;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 15px;
  color: white;
}

#input:focus {
  border: 1px solid rgba(250, 250, 250, 0.4);
}
</style>
