<template>


<!-- Modal -->
<div class="modal fade" id="exampleModal46" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="card">
                        <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
                            <h5 class="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center pt-4 pb-4">
                            <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                            <h6>{{ $t("single_payment.modal_modify_title") }}</h6>
                            <p class="text-justify px-6">
                                {{ $t("single_payment.modal_modify_text") }}
                            </p>
                        </div>
                        <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
                            <argon-button type="button" class="btn me-4" color="danger" variant="gradient"
                                data-bs-dismiss="modal" style="width: 150px; height: 50px; padding-top: 15px">
                                {{ $t("button.close") }}
                            </argon-button>
                            <argon-button :loading="loading" :active="buttonActive || showConfirmation" type="button"
                                class="btn me-4" color="success" variant="gradient"  
                                style="width: 150px; height: 50px; padding-top: 15px" :clickEvent="button"
                                :disabled="loading"  @click="updatePaymentConfig">
                                {{ $t("button.confirm") }}
                            </argon-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>



  <div class="py-4 container">
    <div class="row mt-3 mb-4 text-center">
      <h5 class="mb-5">{{ $t("single_payment.page_modify") }}</h5>
      <div class="row mt-3">
        <div class="col-md-6 col-xs-12 col-lg-7 content text-start mx-auto">
          <div class="p-3 card">
            <div class="card-header pb-0"></div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="mb-4">
                <label>{{ $t("recurring_payment.description") }} <em style="color: #f60000">*</em></label>
                <argon-input placeholder="Désignation..." class="p-1" v-model="description" required></argon-input>
              </div>
              <div class="mb-4">
                <label>{{ $t("recurring_payment.period") }}</label>
              </div>
              <p style="font-size: 13px;">
                {{ $t("recurring_payment.period_text") }}
              </p>
              <!-- Affichage des périodes -->
              <div v-for="(period, index) in periods" :key="index" class="mb-4" style="position: relative">
                <div class="row align-items-center pb-3">
                  <div class="col-6">
                    <label>{{ $t("recurring_payment.amount") }} <em style="color: #f60000">*</em></label>
                    <input type="number" :placeholder="$t('recurring_payment.amount')" class="form-control"
                      v-model="period.amount" :class="{ 'is-invalid': errors[index]?.amount }" />
                    <div v-if="errors[index]?.amount" class="text-danger">{{ errors[index].amount }}</div>
                  </div>
                  <div class="col-3">
                    <label>{{ $t("recurring_payment.month") }} <em style="color: #f60000">*</em></label>
                    <select v-model="period.label" class="form-select" :class="{ 'is-invalid': errors[index]?.label }">
                      <option v-for="(month, monthIndex) in months" :key="monthIndex" :value="monthIndex + 1">{{ month
                        }}</option>
                    </select>
                    <div v-if="errors[index]?.label" class="text-danger">{{ errors[index].label }}</div>
                  </div>
                  <div class="col-3">
                    <label>{{ $t("recurring_payment.day") }} <em style="color: #f60000">*</em></label>
                    <select v-model="period.date" class="form-select" :class="{ 'is-invalid': errors[index]?.date }">
                      <option v-for="day in days" :key="day" :value="day">{{ day }}</option>
                    </select>
                    <div v-if="errors[index]?.date" class="text-danger">{{ errors[index].date }}</div>
                  </div>
                  <div class="text-end" v-if="index > 0" style="position: absolute; top: 0; right: -10px; width:max-content; height: 40px;">
                    <button @click="removePeriod(index)" class="btn btn-danger"
                      :title="$t('recurring_payment.delete_period')"
                      style="border-radius: 50%; padding: 10px 14px; opacity: 0.8;">
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="text-end">
                <button @click="addPeriod" class="btn btn-success" :title="$t('recurring_payment.add_period')">
                  <i class="bi bi-plus"></i>
                </button>
              </div>
              <div class="mt-5">
                <div class="row">
                  <div class="col-md-6">
                    <router-link to="/other-payment">
                      <argon-button fullWidth color="danger" variant="gradient" class="my-4 mb-2" :clickEvent="button"
                        :disabled="loading" size="lg">
                        {{ $t("button.cancel") }}
                      </argon-button>
                    </router-link>
                  </div>
                  <div class="col-md-6">
                    <argon-button :loading="loading" :active="buttonActive || showConfirmation" fullWidth
                      color="success" variant="gradient" class="my-4 mb-2" :clickEvent="button" :disabled="loading"
                       data-bs-toggle="modal" data-bs-target="#exampleModal46" size="lg">
                      {{ $t("button.save") }}
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ArgonButton from "@/components/ArgonButton";
import ArgonInput from "@/components/ArgonInput";
import { getPayment, updateotherpayment } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "Edit_Recurring_Payment_Form",
  components: { ArgonButton, ArgonInput },
  data() {
    return {
      id: this.$route.params.id, // ID of the payment configuration to edit
      description: "",
      periods: [{ label: '', amount: '', date: '' }],
      loading: false,
      days: Array.from({ length: 31 }, (_, i) => i + 1),
      months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      errors: [{ date: '', label: '', amount: '' }],
      is_on_off: false,
      amount: 0,
      due_date: "",
      buttonActive: false,
      showConfirmation: false,
    };
  },
  created() {
    this.fetchPaymentConfig();
  },
  methods: {
    async fetchPaymentConfig() {
      try {
        let response = await getPayment(this.id);


        this.description = response.data.description;


        let invoicesString = response.data.invoices;
        invoicesString = invoicesString.replace(/'/g, '"');

        this.periods = JSON.parse(invoicesString);
        this.amount = response.data.amount;
        this.due_date = new Date(response.data.due_date).toISOString().slice(0, 10);
        this.errors = this.periods.map(() => ({ label: '', amount: '', date: '' }));
      } catch (error) {
        console.error(error);
      }
    },
    addPeriod() {
      this.periods.push({ label: '', amount: '', date: '' });
      this.errors.push({ label: '', amount: '', date: '' });
    },
    removePeriod(index) {
      this.periods.splice(index, 1);
      this.errors.splice(index, 1);
    },
    async updatePaymentConfig() {
      try {
        if (this.loading) {
          return;
        }

        let hasError = false;
        this.errors = this.periods.map(() => ({ label: '', amount: '', date: '' }));

        this.periods.forEach((period, index) => {
          if (!period.amount) {
            this.errors[index].amount = this.$t("recurring_payment.error_amount");
            hasError = true;
          }
          if (!period.label) {
            this.errors[index].label = this.$t("recurring_payment.error_month");
            hasError = true;
          }
          if (!period.date) {
            this.errors[index].date = this.$t("recurring_payment.error_day");
            hasError = true;
          }
        });

        if (hasError) {
          this.loading = false;
          return;
        }

        if (!this.description) {
          if (!this.showConfirmation) {
            this.showConfirmation = true;
            const message = this.$t("message.requiredfile");
            toast.error(message, {
              autoClose: 3000,
            });
            setTimeout(() => {
              this.showConfirmation = false;
            }, 4000);
          }
          return;
        }

        this.loading = true;
        this.button = "disabled";

        let formattedDueDate = new Date(this.due_date).toISOString();

        let data = {
          id: this.id,
          is_on_off: this.is_on_off,
          description: this.description,
          amount: this.amount,
          due_date: formattedDueDate,
          invoices: this.periods,
        };



        let response = await updateotherpayment(this.id, data);

        if (response.status === 200) {
          if (!this.showConfirmation) {
            this.showConfirmation = true;
            const message = this.$t("single_payment.message_modify_success");
            toast.success(message, {
              autoClose: 3000,
            });

            document.body.classList.remove("modal-open");
            const modalBackdrops =
              document.getElementsByClassName("modal-backdrop");
            for (const backdrop of modalBackdrops) {
              backdrop.parentNode.removeChild(backdrop);
            }

            setTimeout(() => {
              this.showConfirmation = false;
              this.$router.push({
                path: "/other-payment",
                query: { forceReload: true },
              });
            }, 4000);
          }
        }
      } catch (error) {
        console.error(error);
        const message = this.$t("message.error");
        toast.error(message, {
          autoClose: 3000,
        });
      } finally {
        this.loading = false;
      }
    },
  },


};
</script>

<style scoped>
.container {}

.card {
  width: 100%;
  margin: 0 auto;
}

.card-body {
  width: 100%;
  padding: 0;
}

.form-control {
  width: 100%;
  box-sizing: border-box;
}

.form-select {
  width: 100%;
  box-sizing: border-box;
}
</style>
