<template>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="card">
        <div class="modal-header">
          <h6 class="modal-title">Discipline</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body border-0">
          <!-- Nav tabs -->
          <ul class="nav nav-tabs   border-0" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab" href="#retard">Retard</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#absence">Absence</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#comportement">Comportement</a>
            </li>
            
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#regles">Règles</a>
            </li>
             
             
          </ul>

          <!-- Tab panes -->
          <div class="tab-content mt-3">
            <!-- Retard Form -->
            <div id="retard" class="tab-pane active">
              <form>
                <div class="mb-3">
                  <label class="form-label">Nom de l'élève</label>
                  <input type="text" class="form-control" v-model="retardForm.nomEleve">
                </div>
                <div class="mb-3">
                  <label class="form-label">Date</label>
                  <input type="date" class="form-control" v-model="retardForm.date">
                </div>
                <div class="mb-3">
                  <label class="form-label">Heure d'arrivée</label>
                  <input type="time" class="form-control" v-model="retardForm.heureArrivee">
                </div>
                <div class="mb-3">
                  <label class="form-label">Durée du retard</label>
                  <input type="text" class="form-control" v-model="retardForm.duree">
                </div>
                <div class="mb-3">
                  <label class="form-label">Motif (facultatif)</label>
                  <input type="text" class="form-control" v-model="retardForm.motif">
                </div>
                <div class="mb-3">
                  <label class="form-label">Commentaires supplémentaires</label>
                  <textarea class="form-control" v-model="retardForm.commentaires"></textarea>
                </div>
              </form>
            </div>

            <!-- Comportement Form -->
            <div id="comportement" class="tab-pane fade">
              <form>
                <div class="mb-3">
                  <label class="form-label">Nom de l'élève</label>
                  <input type="text" class="form-control" v-model="comportementForm.nomEleve">
                </div>
                <div class="mb-3">
                  <label class="form-label">Date</label>
                  <input type="date" class="form-control" v-model="comportementForm.date">
                </div>
                <div class="mb-3">
                  <label class="form-label">Description du comportement</label>
                  <textarea class="form-control" v-model="comportementForm.description"></textarea>
                </div>
                <div class="mb-3">
                  <label class="form-label">Mesures prises</label>
                  <input type="text" class="form-control" v-model="comportementForm.mesuresPrises">
                </div>
                <div class="mb-3">
                  <label class="form-label">Témoins (facultatif)</label>
                  <input type="text" class="form-control" v-model="comportementForm.temoins">
                </div>
                <div class="mb-3">
                  <label class="form-label">Commentaires supplémentaires</label>
                  <textarea class="form-control" v-model="comportementForm.commentaires"></textarea>
                </div>
              </form>
            </div>

            <!-- Absence Form -->
            <div id="absence" class="tab-pane fade">
              <form>
                <div class="mb-3">
                  <label class="form-label">Nom de l'élève</label>
                  <input type="text" class="form-control" v-model="absenceForm.nomEleve">
                </div>
                <div class="mb-3">
                  <label class="form-label">Date de l'absence</label>
                  <input type="date" class="form-control" v-model="absenceForm.date">
                </div>
                <div class="mb-3">
                  <label class="form-label">Durée de l'absence</label>
                  <input type="text" class="form-control" v-model="absenceForm.duree">
                </div>
                <div class="mb-3">
                  <label class="form-label">Motif (facultatif)</label>
                  <input type="text" class="form-control" v-model="absenceForm.motif">
                </div>
                <div class="mb-3">
                  <label class="form-label">Commentaires supplémentaires</label>
                  <textarea class="form-control" v-model="absenceForm.commentaires"></textarea>
                </div>
              </form>
            </div>

            <!-- Règles Form -->
            <div id="regles" class="tab-pane fade">
              <form>
                <div class="mb-3">
                  <label class="form-label">Nom de l'élève</label>
                  <input type="text" class="form-control" v-model="reglesForm.nomEleve">
                </div>
                <div class="mb-3">
                  <label class="form-label">Date</label>
                  <input type="date" class="form-control" v-model="reglesForm.date">
                </div>
                <div class="mb-3">
                  <label class="form-label">Règle enfreinte</label>
                  <input type="text" class="form-control" v-model="reglesForm.regleEnfreinte">
                </div>
                <div class="mb-3">
                  <label class="form-label">Description de l'incident</label>
                  <textarea class="form-control" v-model="reglesForm.description"></textarea>
                </div>
                <div class="mb-3">
                  <label class="form-label">Mesures prises</label>
                  <input type="text" class="form-control" v-model="reglesForm.mesuresPrises">
                </div>
                <div class="mb-3">
                  <label class="form-label">Commentaires supplémentaires</label>
                  <textarea class="form-control" v-model="reglesForm.commentaires"></textarea>
                </div>
              </form>
            </div>

            <!-- Progrès Form -->
            <div id="progres" class="tab-pane fade">
              <form>
                <div class="mb-3">
                  <label class="form-label">Nom de l'élève</label>
                  <input type="text" class="form-control" v-model="progresForm.nomEleve">
                </div>
                <div class="mb-3">
                  <label class="form-label">Date</label>
                  <input type="date" class="form-control" v-model="progresForm.date">
                </div>
                <div class="mb-3">
                  <label class="form-label">Matière concernée</label>
                  <input type="text" class="form-control" v-model="progresForm.matiere">
                </div>
                <div class="mb-3">
                  <label class="form-label">Note actuelle</label>
                  <input type="number" class="form-control" v-model="progresForm.noteActuelle">
                </div>
                <div class="mb-3">
                  <label class="form-label">Note minimale requise</label>
                  <input type="number" class="form-control" v-model="progresForm.noteMinimale">
                </div>
                <div class="mb-3">
                  <label class="form-label">Commentaires sur les performances</label>
                  <textarea class="form-control" v-model="progresForm.commentaires"></textarea>
                </div>
              </form>
            </div>

            <!-- Incident Form -->
            <div id="incident" class="tab-pane fade">
              <form>
                <div class="mb-3">
                  <label class="form-label">Nom de l'élève</label>
                  <input type="text" class="form-control" v-model="incidentForm.nomEleve">
                </div>
                <div class="mb-3">
                  <label class="form-label">Date de l'incident</label>
                  <input type="date" class="form-control" v-model="incidentForm.date">
                </div>
                <div class="mb-3">
                  <label class="form-label">Description de l'incident</label>
                  <textarea class="form-control" v-model="incidentForm.description"></textarea>
                </div>
                <div class="mb-3">
                  <label class="form-label">Impliqués</label>
                  <input type="text" class="form-control" v-model="incidentForm.impliques">
                </div>
                <div class="mb-3">
                  <label class="form-label">Actions prises</label>
                  <input type="text" class="form-control" v-model="incidentForm.actionsPrises">
                </div>
                <div class="mb-3">
                  <label class="form-label">Commentaires supplémentaires</label>
                  <textarea class="form-control" v-model="incidentForm.commentaires"></textarea>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
          <argon-button type="button" class="btn" title="Fermer" data-bs-dismiss="modal" style="width: 200px; height: 50px; padding-top: 15px; background: none;
                        border: 1px solid rgba(255, 255, 255, 0.4);">
            <span>
              {{ $t("button.cancel") }}
            </span>
          </argon-button>
          <argon-button type="button" class="btn me-5 " color="success" variant="gradient"
            style="width: 200px; height: 50px; padding-top: 15px" :disabled="!isChoiceMade">
            {{ $t("button.send") }}
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
export default {
  name: 'Modal_Dicipline',
  data() {
    return {
      retardForm: {
        nomEleve: '',
        date: '',
        heureArrivee: '',
        duree: '',
        motif: '',
        commentaires: ''
      },
      comportementForm: {
        nomEleve: '',
        date: '',
        description: '',
        mesuresPrises: '',
        temoins: '',
        commentaires: ''
      },
      absenceForm: {
        nomEleve: '',
        date: '',
        duree: '',
        motif: '',
        commentaires: ''
      },
      reglesForm: {
        nomEleve: '',
        date: '',
        regleEnfreinte: '',
        description: '',
        mesuresPrises: '',
        commentaires: ''
      },
      progresForm: {
        nomEleve: '',
        date: '',
        matiere: '',
        noteActuelle: null,
        noteMinimale: null,
        commentaires: ''
      },
      incidentForm: {
        nomEleve: '',
        date: '',
        description: '',
        impliques: '',
        actionsPrises: '',
        commentaires: ''
      }
    }
  },

  components:{
    ArgonButton
  },
   
  methods: {
    submitForm() {
      // Handle form submission based on active tab
      const activeTab = document.querySelector('.tab-pane.active').id;
      const formData = this[`${activeTab}Form`];
      console.log('Submitting form for:', activeTab, formData);
      // Add your API call here
    }
  }
}
</script>

<style scoped>
.modal.show {
  display: block;
}

 

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.modal-lg {
  max-width: 800px;
}
</style>
