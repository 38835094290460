<template>
    
  <div class="top" style="width: 118%">
    <div class="card mx-3">
      <div class="card-body px-0 pt-0 mt-4">
        <div class="row" v-if="classroomReport && table.length > 0">
          <div class="col-md-7" style="padding-left: 35px"></div>
          <div class="col-md-5 text-end" style="padding-right: 30px">
            <h6>
              Total :<span class="opacity-6 mx-2" style="font-size: 18px"
                >XAF
                {{
                  Number(
                    classroomReport.no_receipt_confirmation.amount
                  ).toLocaleString()
                }}
              </span>
            </h6>
          </div>
        </div>
        <spinner
          class="text-center mt-8 mb-8"
          v-if="loading"
          style="height: 200px"
        >
          <div>
            <span class="mt-4"><Spinner></Spinner></span>
          </div>
        </spinner>
        <div
          class="table-responsive p-0 mt-5 mb-5"
          v-else-if="table.length > 0"
        >
          <table
            class="table align-items-center mb-0"
            v-if="paginatedTable.length > 0"
          >
            <thead>
              <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">
                <th scope="col" class="border-0" style="text-transform: none">
                  {{ $t("no_bill_payment.name") }}
                </th>
                <th scope="col" class="border-0"> {{ $t("no_bill_payment.grade") }}</th>
                <th scope="col" class="border-0"> {{ $t("no_bill_payment.number") }}</th>
                <th scope="col" class="text-end border-0"> {{ $t("no_bill_payment.receipt") }}</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(list, index) in paginatedTable"
                :key="index"
                style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)"
              >
                <td class="px-4 border-0">
                  {{ list.last_name }} {{ list.first_name }}
                </td>
                <td class="border-0">
                  {{ list.grade }}
                </td>
                <td class="px-4 border-0">{{ list.registration_number }}</td>
                <td
                  class="text-end px-4 border-0"
                  v-if="list.receipt_url == null"
                >
                  --------------------
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>

          <nav aria-label="..." class="px-0">
            <ul class="pagination">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button
                  class="page-link"
                  @click="prevPage"
                  :disabled="currentPage === 1"
                  style="background: none; color: white"
                >
                  <i class="bi bi-chevron-left"></i>
                </button>
              </li>
              <li
                v-for="page in totalPages"
                :key="page"
                class="page-item"
                :class="{ active: currentPage === page }"
              >
                <button
                  class="page-link fw-bold"
                  style="background: none"
                  @click="() => setCurrentPage(page)"
                >
                  {{ page }}
                </button>
              </li>

              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <button
                  class="page-link"
                  @click="nextPage"
                  :disabled="currentPage === totalPages"
                  style="background: none; color: white"
                >
                  <i class="bi bi-chevron-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="else text-center pb-5" v-else>
          <div class="container">
            <div class="text mt-3">
              <img
                style="transform: translateX(-40px)"
                class="image"
                src="@/assets/img/paiementlist.png"
                alt=""
              />
              <h5 class="mt-4"> {{ $t("no_bill_payment.no_payment") }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiConfig from "@/config";
import { getclassroomreport } from "@/utils/services";
import Spinner from "../components/Spinner.vue";

export default {
  name: "student-table",
  components: { Spinner },
  data() {
    return {
      classroom_id: this.$route.params.classId,
      table: [],
      loading: false,
      settled: apiConfig.SETTLED,
      accepted: apiConfig.ACCEPTED,
      overdue: apiConfig.OVERDUE,
      pending: apiConfig.PENDING,
      paid: apiConfig.PAID,
      currentPage: 1,
      itemsPerPage: 50,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.table.length / this.itemsPerPage);
    },
    paginatedTable() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.table.slice(startIndex, endIndex);
    },
  },
  methods: {
    async fetchClassroomReport(classroom_id) {
      try {
        this.loading = true;
        const response = await getclassroomreport(classroom_id);
        console.log("classe settled", response)
        if (response) {
          this.table =
            response.no_receipt_confirmation.receipt_without_confirmation;
        }
      } catch (error) {
        console.error("Error fetching classroom report:", error);
      } finally {
        this.loading = false;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
  },
  created() {
    this.fetchClassroomReport(this.classroom_id);
  },
};
</script>

<style scoped>
.main {
  position: relative;
}
.card {
  min-height: 48vh;
}

#avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  transition: 300ms ease;
}

#avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: inherit;
  user-select: none;
  pointer-events: none;
}

#avatar:hover {
  padding: 10px;
  transform: rotate(10deg);
}
.custom-select {
  position: relative;
}
.tabble-buttun {
  height: 40px;
  margin-top: 20px;
}
.tabble-buttun span i {
  font-size: 18px;
  cursor: pointer;
  transform: translateY(10px);
}

.pagination {
  display: flex;
  width: max-content;
}

.pagination button {
  margin: 0 5px;
  cursor: pointer;
  background-color: #081739;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 20px;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
