<template>

  <!-- Add this modal to your template section -->
  <div class="modal fade" id="studentParentModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <Modaldiscipline></Modaldiscipline>
  </div>

  <!--========================= modal change status =================================== -->

  <div class="modal fade" id="staticBackdrop10" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">

    <Modalpending :amount="amount" :due_date="due_date" :installmentNumber="installmentNumber" v-if="show_status"
      :updatestudentstatut="updatestudentstatut" :selectedStatus="selectedStatus"
      :updatestatutsettled="updatestatutsettled" :close1="close1" :classroomId="classroom_id"
      @confirmed="refreshComponentData" />
    <Modalpendingsuccess :amount="amount" :due_date="due_date" v-if="show_sendmessage"
      :installmentNumber="installmentNumber" :sendinvoice="sendinvoice" :close1="close1"
      :refreshComponentData="refreshComponentData" />
    <ModalSuccessSettled v-if="showConfirmation" :close1="close1" />

  </div>

  <div class="modal fade" id="staticBackdrop11" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">

    <ModalpendingOther :paymentDescription="paymentDescription" :paymentDate="paymentDate"
      :paymentAmount="paymentAmount" v-if="show_status" :applyotherstatut="applyotherstatut"
      :selectedStatus="selectedStatus" :applyothersettled="applyothersettled" :close1="close1"
      :classroomId="classroom_id" @confirmed="refreshComponentData" />

    <ModalpendingsuccessOther :paymentDate="paymentDate" :paymentAmount="paymentAmount" v-if="show_sendmessage"
      :paymentDescription="paymentDescription" :sendinvoiceother="sendinvoiceother" :close1="close1"
      :refreshComponentData="refreshComponentData" />
    <ModalSuccessSettled v-if="showConfirmation" :close1="close1" />

  </div>



  <!--========================= modal change status other payment =================================== -->

  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop40" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">

    <ModalCurrent :amount="amount" :due_date="due_date" :installmentNumber="installmentNumber" v-if="show_status"
      :updatestudentstatut="updatestudentstatut" :selectedStatus="selectedStatus"
      :updatestatutsettled="updatestatutsettled" :close1="close1" :classroomId="classroom_id"
      @confirmed="refreshComponentData" />

  </div>

  <!--========================= modal send alert =================================== -->


  <div class="modal fade" id="staticBackdrop50" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">

    <NoteModal />

  </div>

  <!--========================= modal sequence alert =================================== -->


  <div class="modal fade" id="staticBackdrop51" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">

    <SequenceModal />

  </div>

  <!--========================= modal stop course alert =================================== -->


  <div class="modal fade" id="staticBackdrop52" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">

    <StopcourseModal />

  </div>




  <!--========================= start main =================================== -->
  <div class="card">
    <div class="card-header pb-0">
      <div class="row">
        <div class="row px-0 mb-5">
          <div class="col-md-8">
            <h6 class="mt-2">
              {{ $t("studentlist.student") }} :
              <span class="fs-5 mx-2">{{ studentList.length }} / {{ classroom.student_count }}</span>
            </h6>
          </div>
          <div class="col-4 pt-3 ps-3 d-flex  " style="position: relative; " v-if="Students.length > 0">


            <!------------------------------- Button alert ---------------------------------->


            <div class="dropdown   " style="position: absolute;" v-if="isLocal">
              <a class="btn bg-gradient-success  dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                aria-expanded="false" style="
                  width: 200px;
                  height: 50px;
                  transform: translateY(-20px);
                  padding-top: 16px;
                ">
                <span class="icon-alert">
                  <i class="bi bi-lightning-charge"></i>
                </span>
                {{ $t("studentlist.alert") }}
              </a>

              <ul class="dropdown-menu menu-students p-0" style="border: 1px solid gray">
                <li data-bs-toggle="modal" data-bs-target="#staticBackdrop50" class="px-2 pt-2 li-alert"
                  style="border-bottom:1px solid rgba(255, 255, 255, 0.4); height:40px;">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-1 text-sm font-weight-normal">
                      <span class="font-weight-bold"> {{ $t("studentlist.note_available") }}
                      </span>
                    </h6>
                  </div>
                </li>
                <li data-bs-toggle="modal" data-bs-target="#staticBackdrop51" class="px-2 pt-2 li-alert" v-if="isLocal"
                  style="border-bottom:1px solid rgba(255, 255, 255, 0.4); height:40px;">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-1 text-sm font-weight-normal">
                      <span class="font-weight-bold"> {{ $t("studentlist.sequence_date") }}
                      </span>
                    </h6>
                  </div>
                </li>

                <li data-bs-toggle="modal" data-bs-target="#staticBackdrop52" class="px-2 pt-2 li-alert" v-if="isLocal"
                  style="height:40px; width: 250px;">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-1 text-sm font-weight-normal">
                      <span class="font-weight-bold"> {{ $t("studentlist.course_suspension") }}
                      </span>
                    </h6>
                  </div>
                </li>
              </ul>
            </div>



            <!------------------------------- Button new student ---------------------------------->



            <div class="dropdown" style="position: absolute; right: 0px">
              <a class="btn bg-gradient-success dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                aria-expanded="false" style="
                  width: 200px;
                  height: 50px;
                  transform: translateY(-20px);
                  padding-top: 16px;
                ">
                <span class="fa fa-plus-circle"></span>
                {{ $t("button.add_students") }}
              </a>

              <ul class="dropdown-menu menu-students" style="border: 1px solid gray">
                <li class="mb-2" style="width: 250px">
                  <router-link class="dropdown-item border-radius-md" :to="{
                    name: 'new-student',
                    params: { classroomId: classroom_id },
                  }">
                    <div class="py-1 d-flex">
                      <div class="my-auto">
                        <i class="avatar avatar-sm bg-gradient-success me-3 bi bi-plus"></i>
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-1 text-sm font-weight-normal">
                          <span class="font-weight-bold">{{ $t("button.add_student") }}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </router-link>
                </li>
                <li class="mb-2" v-if="isLocal">
                  <router-link class="dropdown-item border-radius-md" :to="{
                    name: 'import-students',
                    params: { classroomId: classroom_id },
                  }">
                    <div class="py-1 d-flex">
                      <div class="my-auto">
                        <i class="avatar avatar-sm bg-gradient-danger me-3 bi bi-upload"></i>
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-1 text-sm font-weight-normal">
                          <span class="font-weight-bold">{{ $t("button.excel") }}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>




          </div>
        </div>

        <div class="d-flex px-0" v-if="Students.length > 0">

          <!-------------------------------Select Installment---------------------------------->

          <div class="d-flex tabble-buttun" v-if="isDropdownVisible" style="width: 85%" :key="refreshKey">
            <span>
              <div class="d-flex tabble-buttun" v-if="isDropdownVisible">
                <div id="filter">
                  <div class="dropdown" id="dropdown">
                    <button class="btn dropdown-toggle text-light fw-bold" id="btn" type="button"
                      data-bs-toggle="dropdown">

                      {{ $t("studentlist.select_installment") }}

                    </button>
                    <ul class="dropdown-menu" id="dropdown-menu">
                      <li class="li bg-light text-dark fw-bold">
                        {{ $t("sidebar.scolarité") }}
                      </li>

                      <li v-for="obj in configInstallments" :key="obj.id" :title='$t("studentlist.select_title")'
                        class="li" @click="openmodal(obj.installment[0], obj.id)" data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop10">
                        {{ $t("studentlist.installment") }} {{ obj.installment[0].number }}
                        ( XAF {{ Number(obj.installment[0].amount).toLocaleString() }})
                      </li>

                      <li v-if="otherPayments" class="li bg-light text-dark fw-bold">
                        {{ $t("sidebar.other-payment") }}
                      </li>

                      <li v-for="obj in otherPayments" :key="obj.id" :title='$t("studentlist.select_title")' class="li"
                        @click="openPaymentModal(obj, obj.id)" data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop11">
                        {{ obj.description }} ( XAF {{ Number(obj.amount).toLocaleString() }})
                      </li>
                    </ul>

                  </div>
                </div>
              </div>
            </span>


          </div>

          <!-------------------------Error message---------------------------->

          <div class="message d-flex" v-if="!isDropdownVisible && !studentsPending.length > 0">
            <i style="transform:translateY(-10px);" class="bi bi-exclamation-triangle-fill text-warning  fs-3"></i>
            <p class="mx-2">
              <span>{{ $t("studentlist.table_alert") }}</span>
            </p>
          </div>

          <!-------------------------button send message---------------------------->

          <div class="message" v-if="isDropdownVisible && studentsPending.length > 0"
            style="transform: translateY(20px); margin-left: 90px">
            <div @click="sendinvoice" :disabled="loading" title="Envoyer la facture">
              <span v-if="!loading" id="send"><i class="bi bi-send-fill mx-2"></i></span>
              <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-------------------------Loading --------------------------->
    <div class="card-body px-0 pt-0 pb-2 mt-4">
      <div class=" d-flex" id="legend" v-if="Students.length > 0">

        <div :title='$t("studentlist.no_statut_title")' class=" d-flex mx-3 "> <span id="stat-null"></span>
          <p class="mx-1">{{ $t("studentlist.no_statut") }} </p>
        </div>
        <div :title='$t("studentlist.pending_title")' class=" d-flex mx-3 "> <span class=" bg-gradient-warning "
            id="stat-pending"></span>
          <p class="mx-1">{{ $t("studentlist.pending") }} </p>
        </div>
        <div :title='$t("studentlist.settled_title")' class="d-flex mx-3 "> <span class=" bg-gradient-success "
            id="stat-settled"></span>
          <p class="mx-1">{{ $t("studentlist.settled") }} </p>
        </div>
        <div :title='$t("studentlist.overdue_title")' class="d-flex mx-3 "> <span class=" bg-gradient-danger "
            id="stat-settled"></span>
          <p class="mx-1">{{ $t("studentlist.overdue") }} </p>
        </div>

      </div>
      <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
        <div>
          <span class="mt-4">
            <Spinner></Spinner>
          </span>
        </div>
      </spinner>

      <!-------------------------Table --------------------------->

      <div class="table-responsive position-relative" v-else-if="Students.length > 0" :key="refreshKey">
        <table class="table mt-4 align-items-center mb-7">
          <thead>
            <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">
              <th scope="col" class="text-sm border-0" style="transform: translateX(-10px); width: 50px">
                <input type="checkbox" v-model="selectAll" @change="toggleSelectAll" />
              </th>
              <th class="border-0 text-start w-15" style="transform: translateX(-15px); width: 80px">
                {{ $t("studentlist.photo") }}
              </th>
              <th scope="col " class="text-sm border-0 w-15 text-center" style="width: 120px">
                {{ $t("studentlist.table_matricul") }}
              </th>
              <th scope="col" class="text-sm border-0 ps-6">
                {{ $t("studentlist.table_name") }}
              </th>

              <th scope="col" class="text-sm  border-0">
                {{ $t("studentlist.schooling") }} ( XAF
                <span v-if="Students.length > 0"> {{ Number(Students[0].total_due).toLocaleString() }}) </span>
              </th>


              <th scope="col" class="text-sm text-end border-0">
                {{ $t("studentlist.other_payment") }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr id="tr-alert" v-for="student in paginatedStudents" :key="student.id"
              style=" border-bottom: 1px solid rgba(255, 255, 255, 0.1); border-0 ">
              <td class="border-0" style="padding-left: 15px; width:50px">
                <input class="border-0" type="checkbox" v-model="student.selected" @change="onCheckboxChange" />
              </td>
              <td class="border-0 ">
                <div class="d-flex py-1 border-0 image-studdents" :title="$t('classdetail.photo_title')">
                  <div>
                    <router-link v-if="student.registration_number" :to="{
                      name: 'student-details',
                      params: {
                        id: student.id,
                        classroom_id: classroom_id,
                      },
                    }">
                      <div id="avatar">
                        <img v-if="student.photo_url" :src="student.photo_url" class="avatar avatar-sm me-3"
                          :alt="student.photo_url" />
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBld3h7GuDkpTKb35HgCIgr-N7UXvSDJqbRO_RnDM&s"
                          class="avatar avatar-sm me-3" :alt="student.photo_url" />
                      </div>
                    </router-link>
                  </div>
                  <span class="icon-alert-dash" v-if="isLocal">
                    <i class="bi bi-lightning-charge"></i>
                  </span>
                </div>
              </td>
              <td class="text-xs font-weight-bold mb-0 text-center text-uppercase border-0">
                {{ student.registration_number }}
              </td>
              <td class="px-6 border-0">
                <router-link :to="{
                  name: 'student-details',
                  params: {
                    id: student.id,
                    classroom_id: classroom_id,
                  },
                }">
                  <span class="mb-0 fs-6">{{ student.last_name }}</span>
                  <br />
                  <span class="fs-6 text-secondary mb-0">
                    {{ student.first_name }}
                  </span>
                </router-link>
              </td>

              <td class="text-center d-flex border-0 w-150">
                <div class="mt-3 ms-3" id="see">
                  <div class="row">
                    <div class="cicle col-md-1" v-for="payment in regularPayments(student)" :key="payment.id">
                      <div v-if="payment.status === 'Pending' && payment['is_other_payment'] === false">
                        <span :title="getPendingTitle(payment)" v-if="isDueDatePast(payment.due_date)"
                          class="span3 bg-gradient-danger"></span>
                        <span :title="getPendingTitle(payment)" v-else class="span2 bg-gradient-warning"></span>
                      </div>

                      <div :title="getSettledTitle(payment)"
                        v-if="payment.status === 'Settled' && payment.is_other_payment === false" class="">
                        <span class="bg-gradient-success ms-1 span1"></span>
                      </div>

                      <div :title="getNoTitle(payment)"
                        v-if="payment['status'] === null && payment.is_other_payment === false">
                        <span class="span"></span>
                      </div>
                    </div>
                    <span v-if="student['remaining'] !== 0" class="text-xs mt-1 font-weight-bold mb-0 col-3"
                      style="color:#ff6a5c !important;">
                      - {{ Number(student["remaining"]).toLocaleString() }}
                    </span>
                  </div>
                </div>
              </td>



              <td class="text-end border-0 " id="td-alert">
                <div class="toggle-alert pb " v-if="isLocal">
                  <i class="bi bi-lightning-charge" @click="showParentInfo(student)" data-bs-toggle="modal"
                    data-bs-target="#studentParentModal"></i>

                </div>

                <div class=" ms-3 " id="see">
                  <div class="row">
                    <div class="cicle col-md-1" v-for="payment in otherPayment(student)" :key="payment.id">
                      <div v-if="payment.status === 'Pending' && payment.is_other_payment === true">
                        <span :title="getPendingTitle(payment)" v-if="isDueDatePast(payment.due_date)"
                          class="span3 bg-gradient-danger"></span>
                        <span :title="getPendingTitle(payment)" v-else class="span2 bg-gradient-warning"></span>
                      </div>

                      <div :title="getSettledTitle(payment)"
                        v-if="payment.status === 'Settled' && payment.is_other_payment === true" class="">
                        <span class="bg-gradient-success ms-1 span1"></span>
                      </div>

                      <div :title="getNoTitle(payment)"
                        v-if="payment['status'] === null && payment.is_other_payment === true">
                        <span class="span"></span>
                      </div>

                    </div>



                  </div>
                </div>

              </td>

            </tr>
            <tr></tr>
          </tbody>
        </table>
        <nav aria-label="...">
          <ul class="pagination">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <button class="page-link" @click="prevPage" :disabled="currentPage === 1"
                style="background: none; color: white">
                <i class="bi bi-chevron-left"></i>
              </button>
            </li>
            <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: currentPage === page }">
              <button class="page-link fw-bold" style="background: none" @click="setCurrentPage(page)">
                {{ page }}
              </button>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <button class="page-link" @click="nextPage" style="background: none; color: white"
                :disabled="currentPage === totalPages">
                <i class="bi bi-chevron-right"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div v-else>
        <div class="card empty else" style="transform: translateY(10px)">
          <div class="card-header pb-5">
            <div class="row">
              <div class="container">
                <div class="text text-center" style="margin-top: -70px">
                  <img class="image1" src="@/assets/img/classe.png" alt="" />
                  <h5 class="">{{ $t("studentlist.no_student_title") }}</h5>
                  <p class="mb-3 mt-4">
                    {{ $t("studentlist.no_student_text") }}
                  </p>

                  <router-link :to="{
                    name: 'new-student',
                    params: { classroomId: classroom_id },
                  }" class="btn text-center px-3 mb-2 mt-3 bg-gradient-success"
                    style="width: 300px; padding-top: 11px; height: 50px">
                    <span class="fa fa-plus m-2"></span>{{ $t("button.add") }}
                  </router-link>

                  <router-link :to="{
                    name: 'import-students',
                    params: { classroomId: classroom_id },
                  }" class="btn text-center px-3 mb-2 mt-3 bg-gradient-success" style="
                      width: 300px;
                      padding-top: 14px;
                      height: 50px;
                      margin-left: 10px;
                    " v-if="isLocal">
                    <i class="bi bi-file-spreadsheet m-2"></i>
                    {{ $t("button.import") }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  statutstudents,
  sendinvoice,
  getStudentClassrooms,
  applyotherpayment
} from "@/utils/services";
import apiConfig from "@/config";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "../components/Spinner.vue";
import Modalpending from "../modal/ModalPending";
import ModalpendingOther from "@/views/modal/ModalPending2.vue";
import Modalpendingsuccess from "../modal/ModalPendingSuccess";
import ModalpendingsuccessOther from "@/views/modal/ ModalpendingsuccessOther.vue";
import ModalSuccessSettled from "../modal/ModalSuccessSettled";
import ModalCurrent from "../modal/CurrentModal.vue"
import NoteModal from "../modal/notemodal.vue"
import SequenceModal from "../modal/sequencemodal.vue"
import StopcourseModal from "../modal/stopcoursemodal.vue"
import Modaldiscipline from "../modal/Modaldiscipline.vue";

export default {
  name: "student-table",
  components: {
    Spinner,
    ModalCurrent,
    Modalpending,
    ModalpendingOther,
    Modalpendingsuccess,
    ModalSuccessSettled,
    ModalpendingsuccessOther,
    NoteModal,
    SequenceModal,
    StopcourseModal,
    Modaldiscipline

  },
  data() {
    return {
      selectAll: false,
      isDropdownVisible: false,
      checkboxesChecked: false,
      selectedInstallment: "",
      payment_status: null,
      status: null,
      installmentApplied: false,
      isApplyingInstallment: false,
      currentDate: null,
      selectedStatussettled: "Settled",
      classroom: "",
      studentList: [],
      payments: [],
      classroom_id: this.$route.params.classId,
      registration_number_list: [],
      label: "",
      amount: "",
      due_date: "",
      number: "",
      selectedStudent: null,

      school_year: new Date().getFullYear(),
      type: "SchoolFees",
      settled: apiConfig.SETTLED,
      accepted: apiConfig.ACCEPTED,
      overdue: apiConfig.OVERDUE,
      pending: apiConfig.PENDING,
      paid: apiConfig.PAID,
      today: this.getCurrentDate(),
      // Propriétés pour les paiements
      selectedPayment: null,
      selectedPaymentId: null,
      showModalPayments: false,
      noSelectPayment: true,
      studentsPending: [],
      selectedStatus: "Pending",
      show_modal_installments: true,
      show_success_installment: false,
      show_status: true,
      show_sendmessage: false,
      showConfirmation: false,
      refreshKey: 0,
      modalinstallment: false,
      modalpending: false,
      school_slug: apiConfig.SERVICE_NAME,
      isLocal: window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1',
      loading: false,
      currentPage: 1,
      pageSize: 50,
      paymentDescription: '',
      paymentAmount: 0,
      paymentDate: '',
      paymentId: '',
      payment_type: 'SchoolFees'



    };
  },

  async beforeMount() {
    // Initial execution
    this.fetchData();

    // Set interval to run every minute (60000ms)
    setInterval(() => {
      this.fetchData();
    }, 300000);
  },
  mounted() {
    this.$emit("child-data", this.numberOfSettledStudents);
    console.log("other payment", this.otherPayments);

  },

  computed: {
    pendingStudents() {
      return this.getPendingStudents();
    },

    currentPaymentOfFirstPendingStudent() {
      return this.pendingStudents.length > 0
        ? this.pendingStudents[0].current_payment
        : null;
    },

    numberOfSettledStudents() {
      return this.getSettledStudents().length;
    },

    paginatedStudents() {
      // Calculate start and end indices for the current page
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;

      // Return the students for the current page
      return this.Students.slice(startIndex, endIndex);
    },
    totalPages() {
      // Calculate the total number of pages
      return Math.ceil(this.Students.length / this.pageSize);
    },
  },

  watch: {
    // Mettez à jour le tableau studentsPending lorsque pendingStudents change
    pendingStudents: {
      handler() {
        this.updatePendingStudents();
      },

      immediate: true,
    },
  },

  methods: {


    showParentInfo(student) {
      this.selectedStudent = student;
    },


    async fetchData() {
      try {
        this.loading = true;

        let params = {
          classroom_id: this.classroom_id,
          school_year: this.school_year,
        };

        let res = await getStudentClassrooms(params);
        this.studentList = res.data["students"];
        this.payments = this.studentList.payments;
        this.classroom = res.data["classroom"];
        console.log(this.studentList);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    otherPayment(student) {
      return student.payments.filter(payment => payment.is_other_payment);
    },


    regularPayments(student) {
      return student.payments.filter(payment => !payment.is_other_payment);
    },


    //============== title null   ===============
    getNoTitle(payment) {
      // Ajout du label de paiement s'il est défini
      let title = payment.label ? ` ${payment.label}\n` : '';

      // Ajout des informations de montant
      title += `Montant : ${this.getCurrency()} ${payment.amount.toLocaleString()}\n`;

      return title;
    },


    getPendingTitle(payment) {
      // Ajout du label de paiement s'il est défini
      let title = payment.label ? ` ${payment.label}\n` : '';

      // Ajout des informations générales de paiement en attente
      title += `En attente : ${this.getCurrency()} ${payment.amount.toLocaleString()}\n`;
      let splitTotal = 0;

      if (payment.splits && payment.splits.length > 0) {
        payment.splits.forEach(split => {
          splitTotal += split.amount;
        });

        // Affichage des montants versés
        title += 'Montants versés :\n';
        payment.splits.forEach((split, index) => {
          title += `${index + 1}. ${this.getCurrency()} ${split.amount.toLocaleString()} le ${split.on}\n`;
        });

        // Affichage du montant restant
        const remainingAmount = payment.amount - splitTotal;
        title += `Montant restant : ${this.getCurrency()} ${remainingAmount.toLocaleString()}\n`;
      } else {
        title += 'Aucun montant versé trouvé.\n';
      }

      return title;
    },



    getSettledTitle(payment) {
      // Ajout du label de paiement s'il est défini
      let title = payment.label ? `  ${payment.label}\n` : '';

      // Ajout des informations de paiement réglé
      title += `Réglé : ${this.getCurrency()} ${payment.amount.toLocaleString()}\n`;

      if (payment.splits && payment.splits.length > 0) {
        title += 'Montants versés :\n';

        // Affichage des montants versés
        payment.splits.forEach(split => {
          title += `${this.getCurrency()} ${split.amount.toLocaleString()} le ${split.on}\n`;
        });
      } else {
        title += '';
      }

      return title;
    },


    getOverdueTitle(payment) {
      // Ajout du label de paiement s'il est défini
      let title = payment.label ? ` ${payment.label}\n` : '';

      // Ajout des informations de paiement en retard
      title += `En retard : ${this.getCurrency()} ${payment.amount.toLocaleString()}\n`;
      let splitTotal = 0;

      if (payment.splits && payment.splits.length > 0) {
        payment.splits.forEach(split => {
          splitTotal += split.amount;
        });

        // Affichage du montant restant
        title += `Montant restant : - ${(payment.amount - splitTotal).toLocaleString()} ${this.getCurrency()}\n`;

        // Affichage des montants versés
        title += 'Montants versés :\n';
        payment.splits.forEach(split => {
          title += `${this.getCurrency()} ${split.amount.toLocaleString()} le ${split.on}\n`;
        });
      } else {
        title += 'Aucun montant versé trouvé.\n';
      }

      return title;
    },



    getCurrency() {
      return 'XAF';
    },

    //===================== date for statut overdue ===============


    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    isDueDatePast(dueDate) {
      const today = new Date(this.today);
      const paymentDate = new Date(dueDate);
      return paymentDate < today;
    },
    //===================== Pagination ===============

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },


    close1() {
      this.show_status = true;
      this.show_sendmessage = false;
      this.showConfirmation = false;
      this.refreshComponentData();
    },

    getSettledStudents() {
      // Utilisez la méthode filter pour obtenir la liste des étudiants avec le statut "settled"
      return this.studentList.filter(
        (student) => student.payment_status === "Settled"
      );
    },

    hideModal() {
      this.loading = true;
      this.showOverlay = false;
      setTimeout(() => {
        this.loading = false;
        window.location.reload(true);
      }, 1000);
    },
    getPendingStudents() {
      return this.Students.filter(
        (student) => student.payment_status === this.pending
      );
    },
    updatePendingStudents() {
      this.studentsPending = this.getPendingStudents().map((student) => ({
        registration_number: student.registration_number,
        current_payment: student.current_payment,
      }));
    },

    setSelectedInstallment(installment) {
      this.selectedInstallment = installment;
      this.label = installment.label;
      this.amount = installment.amount;
      this.due_date = installment.due_date;
      this.installmentNumber = installment.number;
    },

    openmodal(installment, installmentId) {


      this.selectedInstallmentId = null;

      if (installment) {
        this.no_select_installment = false;
        this.show_modal_installments = true;
        this.setSelectedInstallment(installment);
        this.selectedInstallmentId = installment.id || installmentId;
      } else {
        console.error("L'objet 'installment' est undefined");
      }
    },

    setSelectedPayment(payment) {
      this.selectedPayment = payment;
      this.paymentDescription = payment.description;
      this.paymentAmount = payment.amount;
      this.paymentDate = payment.due_date;
      this.paymentId = payment.id;

    },

    openPaymentModal(payment, paymentId) {
      this.selectedPaymentId = null;

      if (payment) {
        this.noSelectPayment = false;
        this.showModalPayments = true;
        this.setSelectedPayment(payment);
        this.selectedPaymentId = payment.id || paymentId;
      } else {
        console.error("L'objet 'payment' est undefined");
      }
    },




    onInstallmentChange() {
      if (this.selectedInstallment) {
        const { amount, due_date, label } = this.selectedInstallment;

        this.label = label;
        this.amount = amount;
        this.due_date = due_date;
      }
    },

    //========================= Selectionner une checkbox ===========================

    toggleSelectAll() {
      const selectedStudentIds = [];

      let allSelected = true;

      this.Students.forEach((student) => {
        student.selected = this.selectAll;

        // Si l'étudiant est sélectionné, ajoutez son ID à la liste
        if (this.selectAll) {
          selectedStudentIds.push(student.registration_number);
        } else {
          // Si une case individuelle est désélectionnée, mettez à jour le drapeau
          if (!student.selected) {
            allSelected = false;
            this.selectAll = false;
          }
        }
      });

      // Mettez à jour selectAll en fonction du drapeau allSelected
      this.selectAll = allSelected;

      this.isDropdownVisible = this.selectAll;
      this.registration_number_list = selectedStudentIds;


    },
    //========================= Selectionner une checkbox ===========================

    onCheckboxChange() {
      const selectedStudents = this.Students.filter(
        (student) => student.selected
      );

      const selectedStudentIds = selectedStudents.map(
        (student) => student.registration_number
      );

      this.registration_number_list = selectedStudentIds;

      // Si au moins un étudiant est désélectionné, désactivez la case "Select All"
      this.selectAll = selectedStudentIds.length === this.Students.length;

      this.isDropdownVisible = selectedStudents.length > 0;
    },

    //========================= refraiche data  ===========================

    refreshComponentData() {
      this.refreshKey++;

    },


    //========================= Changer le sattus ===========================

    async updatestudentstatut() {
      try {
        this.loading = true;
        this.button = "disabled";

        let data = {
          registration_number_list: this.registration_number_list,
          classroom_id: this.classroom_id,
          payment_status: this.selectedStatus,
          installment_id: this.selectedInstallmentId,
        };

        if (
          this.registration_number_list === "" ||
          this.registration_number_list === null ||
          this.classroom_id === "" ||
          this.classroom_id === null
        ) {
          this.required = "required";
          this.button = "";
        } else {
          let res = await statutstudents(data);

          if (!navigator.onLine) {
            const message = this.$t("message.notinternet");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (res.status === 200) {
            this.show_status = false;
            this.show_sendmessage = true;
            this.refreshComponentData();
          }
        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          // Afficher un message spécifique pour l'erreur 404
          toast.error("User not found. Please check the provided information", {
            autoClose: 3000,
          });
        } else {
          // Afficher un message d'erreur générique pour d'autres erreurs
          toast.error("An error occurred while creating the member", {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
        this.message = true;
      }
    },



    async updatestatutsettled() {
      try {
        this.loading = true;
        this.button = "disabled";

        let data = {
          registration_number_list: this.registration_number_list,
          classroom_id: this.classroom_id,
          payment_status: this.selectedStatussettled,
          installment_id: this.selectedInstallmentId,
        };


        if (
          this.registration_number_list === "" ||
          this.registration_number_list === null ||
          this.classroom_id === "" ||
          this.classroom_id === null
        ) {
          this.required = "required";
          this.button = "";
        } else {
          let res = await statutstudents(data);

          if (!navigator.onLine) {
            const message = this.$t("message.notinternet");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (res.status === 200) {
            this.show_status = false;
            this.show_sendmessage = false;
            this.showConfirmation = true;
            this.refreshComponentData();

          }
        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          // Afficher un message spécifique pour l'erreur 404
          toast.error("User not found. Please check the provided information", {
            autoClose: 3000,
          });
        } else {
          // Afficher un message d'erreur générique pour d'autres erreurs
          toast.error("An error occurred while creating the member", {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
        this.message = true;
      }
    },

    async applyothersettled() {
      try {
        this.loading = true;
        this.button = "disabled";

        let data = {
          registration_number_list: this.registration_number_list,
          classroom_id: this.classroom_id,
          status: this.selectedStatussettled,
          subscription_id: this.paymentId,
          payment_type: this.payment_type
        };


        if (
          this.registration_number_list === "" ||
          this.registration_number_list === null ||
          this.classroom_id === "" ||
          this.classroom_id === null
        ) {
          this.required = "required";
          this.button = "";
        } else {
          let res = await applyotherpayment(data);

          if (!navigator.onLine) {
            const message = this.$t("message.notinternet");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (res.status === 200) {
            this.show_status = false;
            this.show_sendmessage = false;
            this.showConfirmation = true;
            this.refreshComponentData();

          }
        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          // Afficher un message spécifique pour l'erreur 404
          toast.error("User not found. Please check the provided information", {
            autoClose: 3000,
          });
        } else {
          // Afficher un message d'erreur générique pour d'autres erreurs
          toast.error("An error occurred while creating the member", {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
        this.message = true;
      }
    },



    async applyotherstatut() {
      try {
        this.loading = true;
        this.button = "disabled";

        let data = {
          registration_number_list: this.registration_number_list,
          classroom_id: this.classroom_id,
          status: this.selectedStatus,
          subscription_id: this.paymentId,
          payment_type: this.payment_type
        };


        if (
          this.registration_number_list === "" ||
          this.registration_number_list === null ||
          this.classroom_id === "" ||
          this.classroom_id === null
        ) {
          this.required = "required";
          this.button = "";
        } else {
          let res = await applyotherpayment(data);

          if (!navigator.onLine) {
            const message = this.$t("message.notinternet");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (res.status === 200) {
            this.show_status = false;
            this.show_sendmessage = true;
            this.showConfirmation = false;
            this.refreshComponentData();

          }
        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          // Afficher un message spécifique pour l'erreur 404
          toast.error("User not found. Please check the provided information", {
            autoClose: 3000,
          });
        } else {
          // Afficher un message d'erreur générique pour d'autres erreurs
          toast.error("An error occurred while creating the member", {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
        this.message = true;
      }
    },


    //========================= Envoi de la facture ===========================

    async sendinvoice() {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        this.button = "disabled";

        let data = {
          registration_number_list: this.registration_number_list,
          school_slug: this.school_slug,
          installment_id: this.selectedInstallmentId,
        };



        if (
          this.registration_number_list === "" ||
          this.registration_number_list === null ||
          this.school_slug === "" ||
          this.school_slug === null
        ) {
          this.required = "required";
          this.button = "";
        } else {
          let res = await sendinvoice(data);

          if (!navigator.onLine) {
            const message = this.$t("message.notinternet");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (res.status === 200) {
            const message = this.$t("message.send_invoice");
            toast.success(message, {
              autoClose: 3000,
            });
            setTimeout(() => {
              this.refreshComponentData();
            }, 3000);
          }
        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          // Afficher un message spécifique pour l'erreur 404
          toast.error("User not found. Please check the provided information", {
            autoClose: 3000,
          });
        } else {
          // Afficher un message d'erreur générique pour d'autres erreurs
          toast.error("An error occurred while creating the member", {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
        this.message = true;
      }


    },

    async sendinvoiceother() {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        this.button = "disabled";

        let data = {
          registration_number_list: this.registration_number_list,
          school_slug: this.school_slug,
          installment_id: this.selectedPaymentId,
        };



        if (
          this.registration_number_list === "" ||
          this.registration_number_list === null ||
          this.school_slug === "" ||
          this.school_slug === null
        ) {
          this.required = "required";
          this.button = "";
        } else {
          let res = await sendinvoice(data);

          if (!navigator.onLine) {
            const message = this.$t("message.notinternet");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (res.status === 200) {
            const message = this.$t("message.send_invoice");
            toast.success(message, {
              autoClose: 3000,
            });
            setTimeout(() => {
              this.refreshComponentData();
            }, 3000);
          }
        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          // Afficher un message spécifique pour l'erreur 404
          toast.error("User not found. Please check the provided information", {
            autoClose: 3000,
          });
        } else {
          // Afficher un message d'erreur générique pour d'autres erreurs
          toast.error("An error occurred while creating the member", {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
        this.message = true;
      }


    },
  },

  props: {
    otherPayments: {
      type: Array,
      default: () => [],
    },
    Students: {
      type: Array,
      default: () => [],
    },
    configInstallments: {
      type: Array,
      default: () => [],
    },
  },

  created() {
    // Vérifiez si la configuration a déjà été appliquée lors de la création du composant
    const paymentConfigApplied = localStorage.getItem("paymentConfigApplied");
    if (paymentConfigApplied === "true") {
      this.showOverlay = false;
    }
    this.pendingStudentsPaymentsList = this.pendingStudentsPayments;
    this.currentDate = new Date().toISOString().slice(0, 10);
  },
};
</script>

<style scoped>
.main {
  position: relative;
  height: 100%;
}

#avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  transition: 300ms ease;
}

#avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: inherit;
  user-select: none;
  pointer-events: none;
}

.custom-select {
  position: relative;
}

.tabble-buttun {
  height: 40px;
  margin-top: 20px;
}

.tabble-buttun span i {
  font-size: 18px;
  cursor: pointer;
  transform: translateY(10px);
}

.table thead tr th {
  width: 150px;
}

.else .image1 {
  width: 200px;
}

.modal2 {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100%;
  height: 120%;
  z-index: 100;
  left: 0;
  top: -100px;
  display: none;
}

.modal-active2 {
  display: block;
  /* Styles spécifiques pour afficher la modale */
}

.modal2 .modal-content {
  width: 70%;
  margin-left: 13%;
  margin-right: auto;
  text-align: center;
  background: none;
}

.modal2 .modal-content .card {
  padding-top: 5%;
  margin-top: 15%;
}

.modal2 .modal-content .card .card-body p {
  margin-top: 10%;
  font-size: 20px;
}

.pagination {
  display: flex;
  transform: translateY(-40px);
}

.pagination button {
  margin: 0 5px;
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 20px;
}

.pagination button:hover {
  font-weight: 900;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

/*---================ filter ========-------*/

#filter {
  width: max-content;
  margin-top: 30px;
  margin-left: auto;
  transform: translateX(-70px);
  z-index: 100;

}

#filter .dropdown {
  position: relative;
  transform: translateX(73px);
  margin-top: -30px;
}

#filter .dropdown .dropdown-menu {
  width: 250px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0;
  border-radius: 0;


}

#filter .dropdown .dropdown-menu li {
  padding: 10px 20px;
  width: auto;
  color: white;

}

#filter .dropdown .icon-filter {

  transform: translateX(-10px);
  font-weight: bold;

}

#filter .dropdown .dropdown-menu .li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: 0.3s;
}

#filter .dropdown .dropdown-menu li:hover {
  background: rgba(255, 255, 255, 0.1);
}

#filter .dropdown .btn {
  height: 55px;
  width: 250px;
  margin-top: -30px;
  font-size: 15px;
  font-weight: bold;
  transform: translateX(-95px);
  background: #2ecec2;

}

#filter1 {
  width: max-content;
  margin-top: 10px;
  margin-left: 30%;
  z-index: 100;

}

#filter1 .dropdown {
  position: relative;

}

#filter1 .dropdown .dropdown-menu {
  width: 270px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0;
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

#filter1 .dropdown .dropdown-menu li {
  padding: 10px 20px;
  width: auto;
  color: white;

}

#filter1 .dropdown .icon-filter {

  transform: translateX(-10px);
  font-weight: bold;
}

#filter1 .dropdown .dropdown-menu .li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: 0.3s;
}

#filter1 .dropdown .dropdown-menu li:hover {
  background: rgba(255, 255, 255, 0.1);
}

#filter1 .dropdown .btn {
  height: 50px;
  width: max-content;
  margin-top: -30px;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid rgba(255, 255, 255, 0.6);
}

/*---================ bouton send ========-------*/
#send {
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;

}

#send:hover {
  border: 2px solid rgba(255, 255, 255, 0.6);
  padding: 11px;
}

#see {
  text-align: center;
  width: max-content;
  margin-left: auto;
  margin-right: auto;

}

#see .cicle {
  margin-left: auto;
  margin-right: auto;

}

#see .span {
  content: "";
  display: block;
  background: #858585;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  border: 2px solid #b8b6b6;
}

#see .span1 {
  content: "";
  display: block;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  border: 2px solid #6cf7d9;
}

#see .span2 {
  content: "";
  display: block;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  border: 2px solid #f7a066;
}

#see .span3 {
  content: "";
  display: block;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  border: 2px solid #ffa0a0;
}

#stat-null,
#stat-settled,
#stat-pending {

  content: "";
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 5px;
  transform: translateY(6px);
}

#stat-null {
  background: #858585;

}

#separateur {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  text-align: center;
  width: 100%;

}

.li-alert:hover {
  background: white;
  color: black;
  opacity: 0.8;
}

.icon-alert {
  background: white;
  color: black;
  padding: 1px 4px;
  border-radius: 50%;



}

.icon-alert-dash {
  background: white;
  color: black;
 padding-bottom: 20px;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  position: absolute;
  bottom: -3px;
  left: 20px
}

.icon-alert-dash i {
  font-size: 13px;
  margin-left: 3px;
   position: absolute;
}

.image-studdents {

  position: relative;
}

.image-studdents .icon-alert {
  position: absolute;

  opacity: 0.9;
  left: 25px;
  bottom: 0px;
  height: 20px;
  width: 20px;

}

.image-studdents .icon-alert i {
  font-size: 13px;

}

#tr-alert {
  transition: 0.3s ease;
  position: relative;
}

.toggle-alert {
  right: 10px;
  top: 40px;
  display: none;
  transition: 0.5s;
  position: absolute;
  width: max-content;
}

#tr-alert:hover .toggle-alert {
  display: block;
  transform: translateY(-30px);
}

.toggle-alert i {
  background: white;
  color: black;
  border-radius: 50%;
  padding: 1px 4px;
}
</style>