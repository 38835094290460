<template>
 
  <div>
    <div ref="editor" class="quill-editor" style="border: 1px solid rgba(250, 250, 250, 0.4)"></div>
    <div class="caractere-counter" :class="{ 'limite-atteinte': estLimiteAtteinte }">
      {{ nombreCaracteres }}/3000 caractères
      
    </div>
   
  </div>
  <div v-if="bool" class="message-erreur-container">
        Limite de caractères atteinte ! Veuillez réduire votre texte.
    </div>
</template>


<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default {
  name: 'RichTextEditor',
  props: {
    modelValue: String
  },
  data() {
    return {
      nombreCaracteres: 0,
      bool:false
    }
  },
  emits: ['update:modelValue', 'validationStatus'],
  mounted() {
    this.editor = new Quill(this.$refs.editor, {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['link', 'image'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }]
        ]
      }
    });

    if (this.modelValue) {
      this.editor.root.innerHTML = this.modelValue;
      this.compterCaracteres();
    }
     
    this.editor.on('text-change', () => {
      const texte = this.editor.getText();
      this.nombreCaracteres = texte.length;
      
      if (texte.length > 3000) {
       
        this.bool = true;
        this.editor.history.undo();
        this.$emit('validationStatus', false);
        setTimeout(() => {
        this.bool = false;
      }, 5000);  
      } else {
        this.estLimiteAtteinte = false;
        this.$emit('validationStatus', true);
        this.$emit('update:modelValue', this.editor.root.innerHTML);
      }
    });
  },
  methods: {
    compterCaracteres() {
      this.nombreCaracteres = this.editor.getText().length;
    }
  },
  watch: {
    modelValue(newValue) {
      if (newValue !== this.editor.root.innerHTML) {
        this.editor.root.innerHTML = newValue;
        this.compterCaracteres();
      }
    }
  }
};
</script>

<style>
 .quill-editor{
  height: 400px;
 }

.caractere-counter {
  margin-top: 8px;
  text-align: right;
  color: #666;
  font-size: 0.9em;
}
.ql-toolbar.ql-snow {
  border: 1px  solid rgba(250, 250, 250, 0.4) !important;
}

 

.message-erreur {
  color: #ff4444;
  font-weight: bold;
  margin-left: 10px;
  opacity: 0.6;
}

.message-erreur-container {
 opacity: 0.6;
  color: #d32f2f;
  padding: 8px;
  margin-top: 8px;
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
  border: 1px solid #d32f2f;
}

.limite-atteinte {
  color: #d32f2f;
  font-weight: bold;
}
</style>
