<template>
    

  <div class="mb-4 card" >
    <div class="p-3 card-body text-center">
      <div v-if="loading" class="spinner-border text-light text-center mx-auto" role="status">
      <span class="sr-only">Loading...</span>
      </div>

      <div class="d-flex text-start" v-else-if="dashboardData"  :class="directionReverse ? reverseDirection : ''">
        <div>
          <div class=" icon icon-shape text-center"
          
           :class="`${iconBackground} ${this.$store.state.isRTL ? 'border-radius-md' : 'border-radius-2xl'}`">
            <i class="text-xl opacity-10" :class="iconClass" aria-hidden="true"></i>
          </div>
        </div>
        <div :class="contentClass">
          <div class="numbers">
            <p class="mb-0  text-uppercase font-weight-bold" :class="titleColor" style="font-size: 14px;">{{ title }}</p>
            <h6  class="font-weight-bolder" :class="valueColor">{{value}}</h6>
            <span class="mb-0 text-sm text-uppercase font-weight-bold"  :class="Colorspan">{{ number_students }}</span>
            {{detail}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'xschool-card',
  data () {
    return {
      reverseDirection: 'flex-row-reverse justify-content-between',
      loading:false,
    }
  },
  watch: {
  dashboardData: {
    handler(newValue) {
      if (newValue && newValue.length > 0) {
        this.loading = false;
      }
    },
    immediate: true
  }
},
  props: {
    directionReverse: Boolean,
    title: {
      type: String,
      required: true
    },
    dashboardData:{
      type: Array,
      required:true
      
    },
    number_students: {
      type: String,
      required: true
     },
    detail: {
      type: String,
      required: true
    },
     
    titleColor: {
      type: String
    },
    Colorspan: {
      type: String,
      required:true
    },
    value: {
      required: true
    },
     
    valueColor: {
      type: String,
      required:true
    },
    percentage: String,
    iconClass: {
      type: String,
      required: true
    },
    percentageColor: {
      type: String,
      default: 'text-success'
    },
    iconBackground: {
      type: String,
      default: 'bg-red'
    },
    contentClass: {
      type: String
    }
  },
  methods:{
    simulateLoading() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 2000); // Simule 2 secondes de chargement
  },
  },

  mounted() {
    this.customIconBackground = this.iconBackground;
    this.simulateLoading();  
  },
}
</script>
