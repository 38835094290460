<template>
  <div class="top" style="width: 99%; padding-left: 15px">
    <div class="card mx-3 mt-7">
      <div class="card-body px-0 pt-0 mt-4">
        <div class="row">
          <div class="col-md-7" style="padding-left: 20px"> 
          </div>
          <div class="col-md-5 text-end" style="padding-right: 30px"></div>
        </div>
        <div class="table-responsive p-0 mt-5 mb-5">
          <spinner
            class="text-center mt-4 mb-8"
            v-if="loading"
            style="height: 200px"
          >
            <div>
              <span class="mt-4"><Spinner></Spinner></span>
            </div>
          </spinner>

          <div
            class="table-responsive p-0 mt-5 mb-5"
            v-else-if="table.length > 0"
          >
            <table class="table align-items-center mb-0">
              <thead>
                <tr  style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">
                  <th scope="col" style="text-transform: none" class="border-0 ">
                    Nom
                  </th>
                  <th scope="col" style="text-transform: none" class="border-0 ">
                    Prénom
                  </th>
                  <th scope="col" class="border-0 ">Photo</th>
                  <th style="transform:translateX(-70px)" scope="col" class="border-0 ">Genre</th>
                  <th scope="col" class="border-0 text-end">Matière</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(list, index) in paginatedTable"
                  :key="index"
                  style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)"
                >
                  <td class="px-4 border-0">
                     
                    {{ list.firt_name }} 
                  </td>
                  <td class="px-4 border-0">
                    
                    {{ list.last_name }}
                  </td>
                  <td class="border-0 ">
                    <img 
                    id="img" 
                    title="Cliquer pour visualiser"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop33"
                    src="@/assets/img/team-1.jpg" alt="">
                  </td>
                  <td style="transform:translateX(-60px)" class="border-0 text-start ">
                    {{ list.genre }}
                  </td>
                  <td class="px-4 border-0 text-end">{{ list.matiere }}</td>
                   
                </tr>
                <tr></tr>
              </tbody>
            </table>

            <nav aria-label="...">
              <ul class="pagination">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <button
                    class="page-link"
                    @click="prevPage"
                    :disabled="currentPage === 1"
                    style="background: none; color: white"
                  >
                    <i class="bi bi-chevron-left"></i>
                  </button>
                </li>
                <li
                  v-for="page in totalPages"
                  :key="page"
                  class="page-item"
                  :class="{ active: currentPage === page }"
                >
                  <button
                    class="page-link fw-bold"
                    style="background: none"
                    @click="setCurrentPage(page)"
                  >
                    {{ page }}
                  </button>
                </li>
                <li
                  class="page-item"
                  :class="{ disabled: currentPage === totalPages }"
                >
                  <button
                    class="page-link"
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                    style="
                      background: none;

                      color: white;
                    "
                  >
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          <div class="else text-center pb-5" v-else>
            <div class="container">
              <div class="text mt-3">
                <img class="image" src="@/assets/img/paiementlist.png" alt="" />
                <h5 class="mt-4">Aucun Reçus envoyés.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  

   <!--========================= Photo =================================== -->

   <div
    class="modal fade"
    id="staticBackdrop33"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style=" border-bottom:1px solid rgba(255, 255, 255, 0.4);">
            <h5 class="modal-title" id="staticBackdropLabel3"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0 ">
            <i class="bi bi-x-lg"></i>
             <img id="img-modal" src="@/assets/img/team-1.jpg" alt="">
          </div>
           
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "../components/Spinner.vue";

export default {
  name: "Teacher_List",

  data() {
    return {
      classroom_id: this.$route.params.classId,
      
      table: [
        {
        "firt_name": "Naoussi Talla",
        "last_name": "Léo Martial",
        "genre":"Masculin",
        "matiere": "Svt",
        },
        {
        "firt_name": "Foumane",
        "last_name": "Paul",
        "matiere": "Mathématique",
        "genre":"Masculin",
        },
        {
        "firt_name": "Mouna Touré",
        "last_name": "Boubakani",
        "matiere": "Angliais",
        "genre":"Masculin",
        },
         
      ],
      loading: false,

      currentPage: 1,
      itemsPerPage: 50,
    };
  },

  components: {
    Spinner,
  },
  computed: {
    paginatedTable() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.table.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.table.length / this.itemsPerPage);
    },
  },

  methods: {
     


 

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
  },
};
</script>

<style scoped>
.main {
  position: relative;
}
.card {
  min-height: 48vh;
}

#avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  transition: 300ms ease;
}

.modal-dialog  .card{
  width: max-content;
}

#avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: inherit;
  user-select: none;
  pointer-events: none;
}

#avatar:hover {
  padding: 10px;
  transform: rotate(10deg);
}
.custom-select {
  position: relative;
}
.tabble-buttun {
  height: 40px;
  margin-top: 20px;
}
.tabble-buttun span i {
  font-size: 18px;
  cursor: pointer;
  transform: translateY(10px);
}

.pagination {
  margin-top: 30px;
  display: flex;
  width: max-content;
}

.pagination button {
  margin: 0 5px;
  cursor: pointer;
  background-color: #081739;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 20px;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

#img{
  width: 50px;
  border-radius: 50%;
  transform: translateX(15px);
}
#img-modal{
  width: 400px;
  border:1px solid red;
}
 
</style>
