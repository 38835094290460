<template>
  <main class="mt-0 main-content">
     
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <argon-alert color="danger" v-if="failedLogin">
                <small>{{ failedLogin }}</small>
              </argon-alert>
              <argon-alert v-if="successLogin">
                <small>{{ successLogin }}</small>
              </argon-alert>
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">
                    Enter your username and password to sign in
                  </p>
                </div>
                <div class="card-body">
                  <form role="form">
                    <div class="mb-3">
                      <argon-input v-model="username" name="username" placeholder="Username" size="lg" type="text" />
                    </div>
                    <div class="mb-3 position-relative">
                      <argon-input v-model="password" name="password" placeholder="Password" size="lg"
                        :type="showPassword ? 'text' : 'password'" />
                      <span
                        class="password-toggle-icon position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                        @click="togglePasswordVisibility">
                        <i v-if="showPassword" class="text-dark bi bi-eye-fill"></i>
                        <i v-else class="text-dark bi bi-eye-slash-fill"></i>
                      </span>
                    </div>
                    <argon-switch id="rememberMe">Remember me</argon-switch>

                    <div class="text-center">
                      <argon-button class="mt-4" color="success" fullWidth size="lg" variant="gradient"
                        :loading="loading" :active="buttonActive || showConfirmation" :clickEvent="button"
                        v-on:click="login">Sign in
                      </argon-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <!-- Uncomment if needed
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <a
                      href="javascript:;"
                      class="text-success text-gradient font-weight-bold"
                    >Sign up</a>
                  </p>
                  -->
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(&quot;https://static.ikwen.com/ikwenwebsite/images/scolafleet.png&quot;);
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                ">
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4 class="mt-5 text-white font-weight-bolder position-relative">
                  "Attention is the new currency"
                </h4>
                <p class="text-white position-relative">
                  The more effortless the writing looks, the more effort the
                  writer actually put into the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
 

import { loginUser } from "@/utils/auth";
import ArgonAlert from "@/components/ArgonAlert";
import { getConfig } from "@/utils/auth";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "xschool-signin",
  components: {
    ArgonAlert,
    ArgonInput,
    ArgonSwitch,
    ArgonButton,
   
  },
  data() {
    return {
      button: "",
      username: "",
      password: "",
      failedLogin: "",
      successLogin: "",
      loading: false,
      buttonActive: false,
      showConfirmation: false,
      showPassword: false,
      isOffline: !navigator.onLine,
    };
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
     
  },

  async created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    document.body.classList.remove("bg-gray-100");  
  },
  
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    document.body.classList.add("bg-gray-100");  
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
   
  },
  
  methods: {
    updateOnlineStatus() {
      this.isOffline = !navigator.onLine;
    },

    

    
    
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    async login() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.button = "disabled";

      if (!this.username || !this.password) {
        const message = this.$t("message.requiredfile");
        toast.info(message, {
          autoClose: 3000,
        });

        this.loading = false;
        this.button = "";
        return;
      }

      try {
        let data = await loginUser(this.username, this.password);
        

        if (data.status === 404) {
          const message = this.$t("message.user404");
          toast.error(message, {
            autoClose: 3000,
          });
        } else if (data.status === 400) {
          const message = this.$t("signin.login400");
          toast.error(message, {
            autoClose: 4000,
          });

          this.loading = false;
          this.button = "";
          return;
        } else if (data.status === 200) {
          localStorage.setItem("username", this.username);
          

          if (!this.showConfirmation) {
            this.showConfirmation = true;


            const message = this.$t("signin.login200");
            toast.success(message, {
              autoClose: 3000,
            });

          }

          let config = await getConfig();
          if (config["is_verified"] === true) {
            localStorage.setItem("training_type", config["training_type"]);
            localStorage.setItem("education_grade", config["education_grade"]);
            localStorage.setItem(
              "education_system",
              config["education_system"]
            );
            setTimeout(() => {
              this.$router.push({ name: "welcome" });
            }, 4000);
          } else {
            this.$router.push("/Parem");
          }
        } else {
          this.button = "";
          this.failedLogin = data.message;
          setTimeout(() => {
            this.showConfirmation = false;
          }, 3000);
        }
      } catch (err) {
        console.log(`Error: ${err}`);
      } finally {
        this.loading = false;
        this.button = "";
      }
    },
  },

};
</script>

<style scoped>
/* Ajoutez des styles spécifiques ici si nécessaire */
</style>
