<template>
  <div class="modal-dialog">
    <div class="card">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
          <h5 class="modal-title" id="staticBackdropLabel10"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close1"></button>
        </div>
        <div class="modal-body text-center pt-4 pb-4">
          <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>

          <h6>{{ $t("modal_pending.title") }}</h6>

          <p class="text-justify">
            {{ $t("modal_pending.description") }}
          </p>
          <div class="checkbox">
            <div class="row mt-4">
              <div class="col-12 text-start mb-3">
                <div class="row px-4">
                  <div class="form-check col-6" style="padding-left: 38px">
                    <input class="form-check-input" style="margin-left:30%;" type="radio" name="flexRadioDefault"
                      id="flexRadioDefault2" @change="togglePending" ref="pendingRadio" />
                    <label style="font-size: 0.75rem; font-weight: 700" class="form-check-label"
                      for="flexRadioDefault2">
                      {{ $t("modal_pending.status_pending") }}
                    </label>
                  </div>
                  <div class="form-check col-6">
                    <input class="form-check-input" style="margin-left:25%;" type="radio" name="flexRadioDefault"
                      id="flexRadioDefault1" @change="toggleSettled" ref="settledRadio" />
                    <label style="font-size: 0.75rem; font-weight: 700" class="form-check-label"
                      for="flexRadioDefault1">
                      {{ $t("modal_pending.status_settled") }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <p v-if="showPending" id="pending">
              {{ $t("modal_pending.status_pending_description") }}
            </p>
            <p v-if="showSettled" id="settled">
              {{ $t("modal_pending.status_settled_description") }}
            </p>
          </div>
          <div class="table-tranche mt-4 mb-5">
            <table class="table" style="border: 1px solid rgba(255, 255, 255, 0.4)">
              <thead>
                <tr>
                  <th scope="col" style="border-right: 1px solid rgba(255, 255, 255, 0.4)">
                    <span v-if="installmentNumber"> {{ $t("modal_pending.installment") }}</span>
                    <span v-if="paymentDescription">Designation</span>

                  </th>
                  <th scope="col" style="border-right: 1px solid rgba(255, 255, 255, 0.4)">
                    {{ $t("modal_pending.amount") }}
                  </th>
                  <th scope="col">{{ $t("modal_pending.due_date") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border-right: 1px solid rgba(255, 255, 255, 0.4);">

                    <span v-if="paymentDescription">{{ paymentDescription }}</span>
                    <span v-if="installmentNumber">{{ installmentNumber }} </span>
                  </td>
                  <td style="border-right: 1px solid rgba(255, 255, 255, 0.4)">

                    <span v-if="paymentAmount"> XAF {{ Number(paymentAmount).toLocaleString() }} </span>
                    <span v-if="amount"> XAF {{ Number(amount).toLocaleString() }} </span>

                  </td>
                  <td>


                    <span v-if="due_date">{{ due_date }}</span>
                    <span v-if="paymentDate"> {{ formatDate(paymentDate) }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="showAlert" class="alert " id="alert-modal" role="alert">
            <i class="bi bi-exclamation-triangle-fill text-warning fs-4 mx-3"></i>
            {{ $t("modal_pending.alert") }}
          </div>
        </div>
        <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
          <argon-button type="button" class="btn" color="danger" variant="gradient" title="Fermer"
            data-bs-dismiss="modal" style="width: 200px; height: 50px; padding-top: 15px" @click="annuler">
            <span>
              {{ $t("button.cancel") }}
            </span>
          </argon-button>
          <argon-button type="button" class="btn btn-custom" color="success" variant="gradient"
            style="width: 200px; height: 50px; padding-top: 15px" @click="confirmAction"
            :disabled="!isChoiceMade || loading || buttonDisabled">
            <span v-if="!loading">
              {{ $t("button.confirm") }}
            </span>
            <span v-else>
              <i class="fas fa-spinner fa-spin"></i>
            </span>
          </argon-button>

        </div>
      </div>
    </div>
  </div>
</template>




<script>
import ArgonButton from "@/components/ArgonButton";

export default {
  name: 'Modal-Pending',
  components: {
    ArgonButton
  },
  props: {
    amount: {
      type: Number,
      required: true
    },
    paymentDescription: {
      type: String,
      required: true
    },
    paymentDate: {
      type: String,
      required: true
    },
    paymentAmount: {
      type: Number,
      required: true
    },
    installmentNumber: {
      type: Number,
      required: true
    },
    due_date: {
      type: String,
      required: true
    },
    paymentId: {
      type: String,
      required: true
    },
    updatestudentstatut: {
      type: Function,
      required: true
    },
    updatestatutsettled: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      showPending: false,
      showSettled: false,
      selectedStatus: '',
      isChoiceMade: false,
      loading: false,
      showAlert: false,
      buttonDisabled: false // État pour gérer la désactivation du bouton
    };
  },
  methods: {
    confirmAction() {
      if (this.isChoiceMade && !this.buttonDisabled) {
        this.showAlert = false;
        this.loading = true;
        this.buttonDisabled = true; // Désactiver le bouton après le premier clic

        if (this.selectedStatus === 'pending') {
          this.ParentUpdatestudentstatut();
        } else if (this.selectedStatus === 'settled') {
          this.Parentupdatestatutsettled();
        }

        // Simuler une requête asynchrone pour réinitialiser le spinner après l'action
        setTimeout(() => {
          this.loading = false; // Désactiver le spinner après l'action
          this.buttonDisabled = false; // Réactiver le bouton après l'action
          // Fermer le modal ici si nécessaire
        }, 2000); // Remplacer par le temps nécessaire pour compléter l'action
      } else if (!this.isChoiceMade) {
        this.showAlert = true;
      }
    },
    annuler() {
      this.showPending = false;
      this.showSettled = false;
      this.$refs.pendingRadio.checked = false;
      this.$refs.settledRadio.checked = false;
      this.isChoiceMade = false;
      this.showAlert = false;
      this.buttonDisabled = false; // Réinitialiser l'état du bouton
    },
    togglePending() {
      this.showPending = true;
      this.showSettled = false;
      this.selectedStatus = 'pending';
      this.isChoiceMade = true;
      this.showAlert = false;
    },
    toggleSettled() {
      this.showPending = false;
      this.showSettled = true;
      this.selectedStatus = 'settled';
      this.isChoiceMade = true;
      this.showAlert = false;
    },
    ParentUpdatestudentstatut() {
      this.updatestudentstatut();
    },
    Parentupdatestatutsettled() {
      this.updatestatutsettled();
    }
  }
};

</script>



<style>
#pending,
#settled {
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  border: 1px solid green;
}

#alert-modal {
  border: 1px solid #83454d;
  color: #83454d;
  background: rgba(0, 0, 0, 0.6)
}
</style>
