<template>
    <div class="modal fade" :class="{ show: isVisible }" :style="{ display: isVisible ? 'block' : 'none' }" tabindex="-1" aria-labelledby="smallInstallmentModalLabel" aria-modal="true" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content" style="background: #111c44;">
                <div class="modal-header" style="border-bottom:1px solid rgba(250, 250, 250, 0.6);">
                    <h6 class="modal-title" id="supportFeesModalLabel"> </h6>
                    <button type="button" class="btn-close" @click="hideModal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-center">
                    <div class="modal-body text-center pt-4 pb-4">
                        <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                        <h6> Êtes-vous sûr de vouloir bloquer les paiements en petites tranches?</h6>
                        <p class=" px-6">Les parents ne  pourront pas  payer à leur rythme tant qu’ils restent dans les délais.  
                          </p>
                    </div>
                </div>
                <div class="modal-footer" style="border-top:1px solid rgba(255, 255, 255, 0.4);">
                    <argon-button type="button" class="btn bg-transparent" @click="closeModal" aria-label="Close"
                        style="width: 150px; height: 50px; padding-top: 15px ;border:1px solid rgba(255, 255, 255, 0.4);">
                        {{ $t("button.cancel") }}
                    </argon-button>
                    <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="danger" variant="gradient"
                        type="button" class="mt-1" style="width: 150px; height: 50px; padding-top: 15px"
                         >
                        {{ $t("button.confirm") }}
                    </argon-button>
                </div>
            </div>
      </div>
    </div>
  </template>
  
  <script>
  import ArgonButton from "@/components/ArgonButton";
  export default {
    name: 'SmallInstallmentModal',
    components:{
      ArgonButton
    },
    props: ['isVisible'],
    methods: {
      hideModal() {
        this.$emit('update:isVisible', false);
      },
      closeModal() {
      this.$emit('update:isVisible', true);
      this.$emit('close');
    },
    },
  };
  </script>
  
  <style scoped>
  .modal.show {
    display: block;
  }
  </style>
  