<template>
  

  <div class="top" style="width: 99%">
    <div class="card mx-3" style="margin-top: 94px">
      <div class="card-body px-0 pt-0 mt-4">
        <div
          class="row"
          v-if="
            classroomReport &&
            classroomReport.payment_overdue.students.length > 0
          "
        >
          <div class="col-md-7" style="padding-left: 35px"></div>
          <div class="col-md-5 text-end" style="padding-right: 30px">
            <h6>
              Total :<span class="opacity-6 mx-2" style="font-size: 18px"
                >XAF
                {{
                  Number(
                    getTotalPaymentsAmount()
                  ).toLocaleString()
                }}
              </span>
            </h6>
          </div>
        </div>
        <spinner
          class="text-center mt-8 mb-8"
          v-if="loading"
          style="height: 200px"
        >
          <div>
            <span class="mt-4"><Spinner></Spinner></span>
          </div>
        </spinner>
        <div
          class="table-responsive p-0 mt-5 mb-5"
          v-else-if="
            classroomReport &&
            classroomReport.payment_overdue.students.length > 0
          "
        >
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th scope="col" style="text-transform: none">Nom et prénom</th>
                <th scope="col">Matricule</th>
                <th scope="col">Montant</th>
                <th scope="col" class="text-end">Status</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(student, index) in classroomReport.payment_overdue
                  .students"
                :key="index"
              >
                <td class="px-4">
                  {{ student.last_name }} {{ student.first_name }}
                </td>
                <td class="px-4">{{ student.registration_number }}</td>
                <td v-if="student.payments && student.payments.length > 0">
                  <!-- Boucle sur les paiements de l'étudiant -->

                  <span
                    v-for="(payment, index) in student.payments"
                    :key="index"
                  >
                    {{ payment.amount }} XAF
                  </span>
                </td>
                <td
                  class="text-end px-4 text-light"
                  v-if="student.payments && student.payments.length > 0"
                >
                  <!-- Boucle sur les paiements de l'étudiant -->

                  <span
                  style="width:80px;" class="badge badge-sm bg-gradient-danger"
                    v-for="(payment, index) in student.payments"
                    :key="index"
                  >
                    {{ payment.status }}
                  </span>
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="else text-center pb-5" v-else>
          <div class="container">
            <div class="text mt-3">
              <img
                style="transform: translateX(-40px)"
                class="image"
                src="@/assets/img/paiementlist.png"
                alt=""
              />
              <h5 class="mt-4">{{ $t("duedate_payment.no_payment") }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiConfig from "@/config";
import { getclassroomreport } from "@/utils/services";
import Spinner from "../components/Spinner.vue";

export default {
  name: "Overdue-List",
  components: { Spinner },
  data() {
    return {
      classroom_id: this.$route.params.classId,
      table: [],
      loading: false,
      settled: apiConfig.SETTLED,
      accepted: apiConfig.ACCEPTED,
      overdue: apiConfig.OVERDUE,
      pending: apiConfig.PENDING,
      paid: apiConfig.PAID,
    };
  },

  mounted() {
    this.fetchClassroomReport(this.classroom_id);
  },

  methods: {



    getTotalPaymentsAmount() {
    let totalAmount = 0;

    // Parcours de tous les étudiants
    this.classroomReport.payment_overdue.students.forEach(student => {
      // Parcours de tous les paiements de l'étudiant
      student.payments.forEach(payment => {
        // Ajout du montant du paiement au total
        totalAmount += payment.amount;
      });
    });

    return totalAmount;
  },
    async fetchClassroomReport(classroom_id) {
      try {
        this.loading = true;
        // Appeler la fonction pour récupérer les informations de la classe
        const response = await getclassroomreport(classroom_id);
        if (response) {
          this.classroomReport = response;
          

           
        }
      } catch (error) {
        console.error("Error fetching classroom report:", error);
      } finally {
        this.loading = false;
      }
    },
  },

  props: {
    Students: {
      type: Array,
      default: () => [],
    },
    configInstallments: {
      type: Array,
      default: () => [],
    },
  },

  created() {
    // Vérifiez si la configuration a déjà été appliquée lors de la création du composant
    const paymentConfigApplied = localStorage.getItem("paymentConfigApplied");
    if (paymentConfigApplied === "true") {
      this.showOverlay = false;
    }
    this.pendingStudentsPaymentsList = this.pendingStudentsPayments;
  },
};
</script>

<style scoped>
.main {
  position: relative;
}
.card {
  min-height: 48vh;
}

#avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  transition: 300ms ease;
}

#avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: inherit;
  user-select: none;
  pointer-events: none;
}

#avatar:hover {
  padding: 10px;
  transform: rotate(10deg);
}
.custom-select {
  position: relative;
}
.tabble-buttun {
  height: 40px;
  margin-top: 20px;
}
.tabble-buttun span i {
  font-size: 18px;
  cursor: pointer;
  transform: translateY(10px);
}
</style>
