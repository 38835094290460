 
// src/utils/noteAccess.js

export const canAccessNotes = {
  get() {
    return localStorage.getItem('canAccessNotes') === 'true';
  },
  set(value) {
    localStorage.setItem('canAccessNotes', value);
  }
};

