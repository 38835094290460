<template>
    <div class="container-fluid ">
      <div class="content text-center">
  
        <img class="image mb-5" src="@/assets/img/ima2.png" alt="" />
  
        <h5 class="fw-bold" >Messagerie enrichie sur Eska </h5>
  
        <p class="w-70 mx-auto mt-5 mb-5" >
           Envoyer des messages enrichis aux parents directement sur l'application Eska. 
           C'est beaucoup moins cher que les SMS, il n'y a pas
            la limite de 160 caractères et vous pouvez y intégrer les photos et les videos.
        </p>
  
        <button type="button" class="btn btn-lg bg-gradient-success"  >
           <a target="_blank" href="#">Commencer</a>
        </button>
  
      </div>
    </div>
  
  
  </template>
  
  <script>
  export default {
    name: "Start_Message",
  
    data() {
      return {};
    },
  };
  </script>
  
  <style>
  /**=========body-card============ */
  </style>