<template>
    <div class="modal-dialog">
        <div class="card">
            <div class="modal-content">
                <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
                    <h5 class="modal-title" id="staticBackdropLabel10"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="close1"></button>
                </div>
                <div class="modal-body text-center pt-4 pb-4">

                    <div class="container mt-5">
                        <h6>Remplissez les informations de passage de l'évaluation</h6>
                        <form class="mt-5 mb-4">
                            <div class="mx-auto" style="width: 84%;">

                                <div class="mb-3 text-start ">
                                <label for="sequences " style="font-size:14px" class="form-label ">
                                    Sélectionnez la séquence <em style="color: #f60000">*</em>
                                </label>
                                <div class="row mt-3 ps-3">
                                    <div v-for="sequence in sequences" :key="sequence.id" class="form-check col-3 mt-2">
                                        <input class="form-check-input" type="radio" name="sequence"
                                            :id="`sequence-${sequence.id}`" :value="sequence.name"
                                            v-model="selectedSequence">
                                        <label class="form-check-label" :for="`sequence-${sequence.id}`">
                                            {{ sequence.name }}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3 text-start ">
                                <label for="matieres " style="font-size:14px" class="form-label ">
                                    Sélectionnez la ou les matières dont les notes sont disponibles <em
                                        style="color: #f60000">*</em>
                                </label>

                                <div class="row mt-3 ps-3">

                                    <div v-for="matiere in matieres" :key="matiere.id" class="form-check  col-3 mt-2 ">
                                        <input class="form-check-input" type="checkbox" :id="`matiere-${matiere.id}`"
                                            :value="matiere.name" v-model="selectedMatieres">
                                        <label class="form-check-label" :for="`matiere-${matiere.id}`">
                                            {{ matiere.name }}
                                        </label>
                                    </div>

                                </div>

                                <!-- Liste de matières avec des checkbox -->

                            </div>

                                <div class="row">
                                    <!-- End date -->
                                    
                                    <div class="mb-3 col-6 text-start">
                                        <label for="startDate" class="form-label">Date de début <em
                                                style="color: #f60000">*</em></label>
                                        <input style="height:50px;" type="date" class="form-control" id="startDate"
                                            v-model="startDate">
                                    </div>
                                    <div class="mb-3 col-6 text-start">
                                        <label for="endDate" class="form-label">Date de fin <em
                                                style="color: #f60000">*</em></label>
                                        <input style="height:50px;" type="date" class="form-control" id="endDate"
                                            v-model="endDate">
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <!-- Sélecteur pour le niveau (classe du lycée) -->
                                    <div class="mb-3 col-6 text-start">
                                        <label for="niveau" class="form-label">Niveau (Classe) <em
                                                style="color: #f60000">*</em></label>
                                        <select style="height:50px;" v-model="selectedNiveau" class="form-select"
                                            aria-label="Default select example" id="niveau">
                                            <option disabled value="">Sélectionnez un niveau</option>
                                            <option v-for="niveau in niveaux" :key="niveau.id" :value="niveau.name">{{
                                                niveau.name }}</option>
                                        </select>
                                    </div>

                                    <!-- Sélecteur pour la spécialité -->
                                    <div class="mb-3 col-6 text-start">
                                        <label for="specialite" class="form-label">Spécialité <em
                                                style="color: #f60000">*</em></label>
                                        <select style="height:50px;" v-model="selectedSpecialite" class="form-select"
                                            aria-label="Default select example" id="specialite">
                                            <option disabled value="">Sélectionnez une spécialité</option>
                                            <option v-for="specialite in specialites" :key="specialite.id"
                                                :value="specialite.name">{{ specialite.name }}</option>
                                        </select>
                                    </div>

                                </div>

                            </div>
                        </form>
                    </div>

                </div>
                <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
                    <argon-button type="button" class="btn" title="Fermer" data-bs-dismiss="modal" style="width: 200px; height: 50px; padding-top: 15px; background: none;
                        border: 1px solid rgba(255, 255, 255, 0.4);">
                        <span>
                            {{ $t("button.cancel") }}
                        </span>
                    </argon-button>
                    <argon-button type="button" class="btn me-7 " color="success" variant="gradient"
                        style="width: 200px; height: 50px; padding-top: 15px" :disabled="!isChoiceMade">
                        {{ $t("button.send") }}
                    </argon-button>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import ArgonButton from "@/components/ArgonButton";

export default {
    name: 'Sequence-Modal',
    components: {
        ArgonButton
    },
    data() {
        return {
            selectedSequence: '',
            startDate: '',
            endDate: '',
            sequences: [
                { id: 1, name: "Séquence 1" },
                { id: 2, name: "Séquence 2" },
                { id: 3, name: "Séquence 3" },
                { id: 4, name: "Séquence 4" },
                { id: 5, name: "Séquence 5" },
                { id: 6, name: "Séquence 6" }
            ],

            // Liste des matières disponibles
            matieres: [
                { id: 1, name: "Toute les matieres" },
                { id: 2, name: "Mathématiques" },
                { id: 3, name: "Physique" },
                { id: 4, name: "Chimie" },
                { id: 5, name: "Histoire" },
                { id: 6, name: "Géographie" }
            ],
            selectedMatieres: [],

            selectedNiveau: '',  // Pour stocker le niveau sélectionné
            selectedSpecialite: '',  // Pour stocker la spécialité sélectionnée
            niveaux: [
                { id: 1, name: "Seconde" },
                { id: 2, name: "Première" },
                { id: 3, name: "Terminale" }
            ],
            specialites: [
                { id: 1, name: "Scientifique" },
                { id: 2, name: "Littéraire" },
                { id: 3, name: "Économique et social" },
                { id: 4, name: "Technologique" }
            ],
            isChoiceMade: false,
            loading: false,
            classroomId: null,
            showAlert: false,
        };
    },
    methods: {
        confirmAction() {
            if (this.isChoiceMade) {
                this.showAlert = false;
                // Logique pour confirmer l'action
            } else {
                this.showAlert = true;
            }
        },
        close1() {
            // Logique pour fermer la modal
        }
    }
};
</script>



<style>
#pending,
#settled {
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    border: 1px solid green;
}

#alert-modal {
    border: 1px solid #83454d;
    color: #83454d;
    background: rgba(0, 0, 0, 0.6)
}
</style>