<template>


    <div class="modal-dialog">
           <div class="card">
            <div class="modal-content">
                <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
                    <h5 class="modal-title" id="staticBackdropLabel10"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="close1"></button>
                </div>
                <div class="modal-body text-center pt-4 pb-4">
                    <div class="container">
                        <i style="color: #f1c40f; font-size: 70px" class="bi bi-check2-circle"></i>

                        <h6> {{ $t("modal_settled_success.title") }} </h6>
                        <p class="px-7 mb-5">
                            {{ $t("modal_settled_success.description") }}
                        </p>
                    </div>
                </div>
                <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
                    <argon-button type="button" class="btn" color="danger" variant="gradient" title="Fermer"
                        @click="Parentclose1" data-bs-dismiss="modal" style="width: 200px; height: 50px; padding-top: 15px">
                        <span>
                          {{$t("button.close")}}
                        </span>
                    </argon-button>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";

export default {
    name: 'Modal-Success-Settled',


    components: {
        ArgonButton
    },
    props: {
        amount: {
            type: Boolean,
            required: true
        },

        installmentNumber: {
            type: Boolean,
            required: true
        },

        due_date: {
            type: Boolean,
            required: true
        },
        close1: {
            type: Function,
            required: true,
        }
    },
    methods:{

        Parentclose1() {
            this.close1();
            window.location.reload();
        },

    }
}
 
</script>