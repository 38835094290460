<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="card">
        <div class="modal-header" style="border-bottom: 1px solid rgba(250,250, 250, 0.4);">
          <h6 class="modal-title">{{ $t("navbar.add_student") }}</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="container text-center mt-3">
            <h6>{{ $t("navbar.select_class") }} </h6>
            <div v-if="loading" class="text-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <div class="classroom mt-5 mb-5" v-else>
              <div class="row">
                <div class="col-3 mb-3" v-for="classroom in schoolList" :key="classroom.id">

                  <div class="card-class py-3" data-bs-dismiss="modal" >
                    <router-link class="border-radius-md" :to="{
                      name: 'new-student',
                      params: { classroomId: classroom.id },
                    }">
                      <span class>{{ classroom.name }}</span>

                    </router-link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
          <argon-button type="button" class="btn me-4" color="danger" variant="gradient" title="Fermer"
            data-bs-dismiss="modal" style="width: 150px; height: 50px; padding-top: 15px" @click="annuler">
            <span>
              {{ $t("button.close") }}
            </span>
          </argon-button>


        </div>



      </div>
    </div>
  </div>

</template>

<script>
import ArgonButton from "@/components/ArgonButton";
import { getGradeCode } from '@/utils/services'; // Make sure to import your API function

export default {
  name: "SearchModal",
  components: {
    ArgonButton
  },
  data() {
    return {
      loading: false,
      schoolList: [],
      has_class: false
    };
  },
  async created() {
    try {
      this.loading = true;
      let classrooms = await getGradeCode();
      this.schoolList = classrooms.data;
      this.schoolList.forEach((classroom) => {
        if (classroom["classrooms"].length) {
          this.has_class = true;
          return;
        }
      });
      return this.schoolList;
    } catch (err) {
      console.error("Failed to get Grades with classrooms", err);
    } finally {
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.card-class {
  border: 1px solid rgba(250, 250, 250, 0.4);

  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
}

.card-class:hover {
  background: rgba(250, 250, 250, 0.4);


}
</style>
