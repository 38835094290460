<template>
 <SponsorEska/>
 <Eskatable v-if="sponsor" />
</template>



<script>

import Eskatable from '../components/Eskatable.vue';
import SponsorEska from './SponsorEska.vue'

export default {
    name: "Eska_vue",
   
    data(){
    return{
        sponsor:false
    }
    },
      
    components: {
        Eskatable,
        SponsorEska
    }
}

</script>