<template>


<a target="_blank"  href="#" :title="$t('help.help_title')"     v-if="currentPage === 'welcome'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>


<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'classrooms'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'class-details'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>


<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'payment-config'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>


<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'accounts'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>



<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'new-account'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>


<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'create-payment-config'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>



<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'detail-config'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>




<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'update-config'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'new-class'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'new-student'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'list-payment'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>


<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'settled-students'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>



<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'classroom-receipts'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'classes-students-payment-settled'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'classes-students-payment-overdue'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>


<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'classes-students-payment-pending'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>


<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'historique-transaction'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'only-payment-form'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'notes'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'sendnote'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'transfer'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>


<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'recurent-payment-form'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>


<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'update-only-payment'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'update-recurent-payment'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>
<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'message'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>
<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'start-message'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'import-students'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'pending-students'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'overdue-students'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>


<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'other-payment'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>

<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'notifications'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>


<a target="_blank"  href="#" :title="$t('help.help_title')"   v-if="currentPage === 'message'"  class="px-3 py-2 fixed-plugin-button text-dark position-fixed">
      <i class=" py-2 bi bi-question-lg"></i>
</a>



   
</template>
  
  
  
  
  
  <script>
  
  
  
  export default {
    name: "Help-button",
    data() {
      
    },

    props: {
            currentPage: {
                type: String,
                required: true,
            },
        },
  
    
  
    }
  
  
   
     
  </script>
  
  
  
  
  <style lang="scss" scoped>



  </style>
   
  