html
Copier le code
<template>
  <div class="py-4 container">
    <div class="row mt-3 mb-4 text-center">
      <h5 class="mb-5">
        {{ $t("update_class.class") }} {{ grade_code }} {{ speciality }} {{ number }}
      </h5>
      <div class="row mt-3">
        <div class="col-md-6 col-xs-6 col-lg-7 content text-start">
          <div class="p-3 card w-">
            <div class="card-header pb-0"></div>
            <div class="card-body px-0 pt-0 pb-2">
              <!-- Grade -->
              <div class="mb-4">
                <p class="small">{{ $t("update_class.grade") }}</p>
                <select class="form-control form-select" style="height: 50px" v-model="grade_code">
                  <option v-for="item in GRADE_CODE" :key="item.code" :value="item.name">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <!-- Speciality -->
              <div class="mb-4">
                <p class="small">{{ $t("update_class.speciality") }}</p>
                <select class="form-control form-select" style="height: 50px" v-model="speciality">
                  <option v-for="item in SPECIALITY_CODE" :key="item.code" :value="item.short_name">
                    {{ item.short_name }} - {{ item.title }}
                  </option>
                </select>
              </div>
              <!-- Classroom number -->
              <div class="mb-4">
                <p class="small">{{ $t("update_class.number") }}</p>
                <argon-input required type="text" placeholder="eg: 6EME 1 or 6EME A" v-model="number"></argon-input>
              </div>
              <!-- Student count -->
              <div class="mb-4">
                <p class="small">{{ $t("update_class.student_count") }}</p>
                <argon-input required type="number" v-model="student_count"></argon-input>
              </div>
              <!-- Save button -->
              <div class="mt-5">
                <div class="row">
                   
                  <div class="col-6">
                    <argon-button data-bs-toggle="modal" data-bs-target="#staticBackdrop8" fullWidth color="success"
                      variant="gradient" class="my-4 mb-2" :clickEvent="button" :disabled="loading" size="lg"
                      @click="showModal">
                      {{ $t("button.save") }}
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--========================= modal update =================================== -->

  <div class="modal fade" id="staticBackdrop8" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 1px solid rgba(250, 250, 250, 0.6);">
            <h5 class="modal-title" id="staticBackdropLabel8"> </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center pt-4 pb-5">
            <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
            <h6 class="mt-3 mb-5">{{ $t('update_class.title_modal') }}</h6>
            <table class="table">
              <thead class="border-0">
                <tr>
                  <th style="text-transform: none; font-size: 14px; opacity: 0.8 ; border: 1px solid rgba(250, 250, 250, 0.6);" scope="col">{{ $t('update_class.grade') }}</th>
                  <th style="text-transform: none; font-size: 14px; opacity: 0.8; border: 1px solid rgba(250, 250, 250, 0.6);" scope="col">{{ $t('update_class.speciality') }}</th>
                  <th style="text-transform: none; font-size: 14px; opacity: 0.8; border: 1px solid rgba(250, 250, 250, 0.6);"  scope="col">{{ $t('update_class.number') }}</th>
                  <th style="text-transform: none; font-size: 14px; opacity: 0.8; border: 1px solid rgba(250, 250, 250, 0.6);" scope="col">{{ $t('update_class.student_count') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border: 1px solid rgba(250, 250, 250, 0.6)" >{{ grade_code }}</td>
                  <td style="border: 1px solid rgba(250, 250, 250, 0.6)" >{{ speciality }}</td>
                  <td style="border: 1px solid rgba(250, 250, 250, 0.6)">{{ number }}</td>
                  <td style="border: 1px solid rgba(250, 250, 250, 0.6)">{{ student_count }}</td>
                   
                </tr>
              </tbody>
            </table>
         </div>
          <div class="modal-footer" style="border-top: 1px solid rgba(250, 250, 250, 0.6)">
            <argon-button type="button" class="btn bg-transparent"   data-bs-dismiss="modal"
              style="width: 150px; height: 50px; padding-top: 15px; border: 1px solid rgba(250, 250, 250, 0.4)">
              {{ $t('button.cancel') }}
            </argon-button>

            <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="danger" variant="gradient" type="button"
              class="mt-1" style="width: 150px; height: 50px; padding-top: 15px" @click="updateClassroom">
              {{ $t('button.confirm') }}
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
import ArgonInput from "@/components/ArgonInput";
import {
  getClassroomById,
  updateClassroom,
  getGradeCode,
  getSpeciality,
} from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "UpdateClass",
  components: { ArgonButton, ArgonInput },
  data() {
    return {
      GRADE_CODE: [],
      SPECIALITY_CODE: [],
      grade_code: "",
      grade_code1: "",
      speciality: "",
      number: "",
      student_count: 0,
      loading: false,
      buttonActive: false,
      showConfirmation: false,
      detailclassroom: {
        id: "",
      },
    };
  },

  methods: {
    gotoclass() {
      this.$router.push({
        path: "/classes",
        query: { forceReload: true },
      });
    },
    async getData() {
      try {
        let grades = await getGradeCode();
        this.GRADE_CODE = grades.data;
        this.grade_code = this.GRADE_CODE[0].name;

        let specialities = await getSpeciality();
        this.SPECIALITY_CODE = specialities.data;

        const classId = this.$route.params.classId;
        const classroom = await getClassroomById(classId);

        if (classroom) {
         
          this.grade_code = classroom.grade_code;
          this.speciality = classroom.speciality_code;
          this.number = classroom.number;
          this.student_count = classroom.student_count;
        } else {
          console.error("Les données de la classe sont indisponibles.");
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données de la classe :",
          error
        );
      }
    },

    showModal() {
      // Here we don't need to do anything special, data is already bound to the template
    },

    async updateClassroom() {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;

        const classId = this.$route.params.classId;
        const data = {
          grade_code: this.grade_code,
          speciality_code: this.speciality,
          number: this.number,
          student_count: this.student_count,
        };

        const response = await updateClassroom(classId, data);

        if (response.status === 200) {
          if (!this.showConfirmation) {
            this.showConfirmation = true;
            const message = this.$t("update_class.message_success");
            toast.success(message, {
              autoClose: 3000,
            });

            document.body.classList.remove("modal-open");
            const modalBackdrops =
              document.getElementsByClassName("modal-backdrop");
            for (const backdrop of modalBackdrops) {
              backdrop.parentNode.removeChild(backdrop);
            }

            setTimeout(() => {
              this.showConfirmation = false;
              this.$router.push({ path: "/classes", query: { forceReload: true } });
            }, 4000);
          }
        } else {
          const message = this.$t("message.updateFailed");
          toast.error(message, {
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour de la classe :", error);
      } finally {
        this.loading = false;
      }
    },
  },

  created() {
    this.getData();
  },
};
</script>

<style scoped>
.content {
  margin-left: auto;
  margin-right: auto;
}
</style>