<template>
  <div class="container-fluid">
    <div class="title mt-7 mb-3">
      <h5>Vous pouvez souscrire à votre forfait</h5>
    </div>

    <div class="row mt-5">
      <div class="col-md-3">
        <div class="body-card pt-4 pb-4 text-center">
          <div class="number">
            <h6>
              <span class="px-3"> 0</span> <span>-</span>
              <span class="px-3">10 000</span>
            </h6>
            <p class="student">Elèves</p>
          </div>

          <div class="amount mt-6">
            <h6>20 000 <span> XAF</span></h6>
            <p>par élève/année</p>
          </div>
          <div class="total-amount mt-6 px-3">
            <div class="total-text">Votre total:</div>
            <div class="amount">2 000 000 XAF</div>
          </div>
          <div class="button px-3 mt-3">
            <argon-button
               
              type="button"
              class="mt-1"
              style="
                width: 100%;
                height: 40px;
                border-radius: 5px;
                padding-top: 10px;
                background: #0d9b5e;
              "
            >
              Souscrire
            </argon-button>
          </div>
        </div>
      </div>

   <!--------------------------card---------------------->

   <div class="col-md-3 text-center" style="margin-top:-25px;">
         <span
         style="opacity:0.9;
         font-weight: bold;
         "
         >Forfait adapté pour vous</span>
        <div class="body-card body-card1 pt-4 pb-4 text-center">
          <div class="number">
            <h6>
              <span class="px-3">10 000 </span> <span>-</span>
              <span class="px-3">15 000</span>
            </h6>
            <p class="student">Elèves</p>
          </div>
          <div class="amount mt-6">
            <h6><span class="number">20 000 </span><span> XAF</span></h6>
            <p>par élève/année</p>
          </div>
          <div class="total-amount mt-6 px-3">
            <div class="total-text">Votre total:</div>
            <div class="amount">2 000 000 XAF</div>
          </div>
          <div class="button px-3 mt-3">
            <argon-button
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop28"
              type="button"
              class="mt-1"
              style="
                width: 100%;
                height: 40px;
                border-radius: 5px;
                padding-top: 10px;
                background: #0d9b5e;
              "
            >
              Souscrire
            </argon-button>
          </div>
        </div>
      </div>



   <!--------------------------card---------------------->


      <div class="col-md-3">
        <div class="body-card pt-4 pb-4 text-center">
          <div class="number">
            <h6>
              <span class="px-3">10 000 </span> -
              <span class="px-3">150 000</span>
            </h6>
            <p class="student">Elèves</p>
          </div>
          <div class="amount mt-6">
            <h6>20 000 <span> XAF</span></h6>
            <p>par élève/année</p>
          </div>
          <div class="total-amount mt-6 px-3">
            <div class="total-text">Votre total:</div>
            <div class="amount">2 000 000 XAF</div>
          </div>
          <div class="button px-3 mt-3">
            <argon-button
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop28"
              type="button"
              class="mt-1"
              style="
                width: 100%;
                height: 40px;
                border-radius: 5px;
                padding-top: 10px;
                background: #0d9b5e;
              "
            >
              Souscrire
            </argon-button>
          </div>
        </div>
      </div>

  <!--------------------------card---------------------->



      <div class="col-md-3">
        <div class="body-card pt-4 pb-4 text-center">
          <div class="number">
            <h6>
              <span class="px-3">10 000 </span> -
              <span class="px-3">150 000</span>
            </h6>
            <p class="student">Elèves</p>
          </div>
          <div class="amount mt-6">
            <h6>20 000 <span> XAF</span></h6>
            <p>par élève/année</p>
          </div>
          <div class="total-amount mt-6 px-3">
            <div class="total-text">Votre total:</div>
            <div class="amount">2 000 000 XAF</div>
          </div>
          <div class="button px-3 mt-3">
            <argon-button
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop28"
              type="button"
              class="mt-1"
              style="
                width: 100%;
                height: 40px;
                border-radius: 5px;
                padding-top: 10px;
                background: #0d9b5e;
              "
            >
              Souscrire
            </argon-button>
          </div>
        </div>
      </div>








    </div>
  </div>

  
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
export default {
  name: "Offers_Vue",
  components: {
    ArgonButton,
  },
  data() {
    return {};
  },
};
</script>

<style>

/**=========body-card============ */

.body-card {
  border: 1px solid #21846c;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
}
.body-card:hover{
  transform:translateY(-5px)
}
.body-card .number h6 span {
  font-weight: bold;
  font-size: 18px;
 

}
.body-card .number .student {
  color: #565b6d;
  transform: translateY(-10px);
}
.body-card .amount h6 {
  font-size: 25px;
  font-weight: bold;
   
}
.body-card .amount h6 span {
  color: #565b6d;
  font-size: 15px;
}
.body-card .amount p {
  color: #21846c;
  font-size: 15px;
  transform: translateY(-15px);
}
.body-card .total-amount {
  display: flex;
  justify-content: space-between;
}
.body-card .total-amount .total-text {
  font-size: 15px;
  color: #5985cd;
}
.body-card .total-amount .amount {
  font-size: 15px;
  color: #5985cd;
}

#body-card{
  border: 1px solid  rgba(250, 250, 250, 0.6);
  padding-top: 10px;
  height: 50px;
  margin-bottom:50px;

}

/**=========body-card1============ */

.body-card1 {
  border:2px solid white;
  background: white;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
}

.body-card1 .number h6 span {
  font-weight: bold;
  font-size: 18px;
  color:#5985cd;
}
.body-card1 .amount .number {
  font-size: 25px;
  font-weight: bold;
  color:#5985cd;
}
.body-card1 .total-amount .total-text {
  font-size: 15px;
  color: #5985cd;
}
.body-card1 .total-amount .amount {
  font-size: 15px;
  color: #5985cd;
   
}
</style>
