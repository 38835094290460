<template>
  <div class="p-5 container-fluid">
    <div class="description">
      <div class="student-form-title">
        <h2>{{ $t("account.new_installment") }}</h2>
      </div>
      <div class="student-form">
        <form class="form col-9 border">
          <div class="row">
            <div class="col-md-4">
              <div class="mb-3">
                <label>
                  {{ $t("account.installment_number") }}
                  <em style="color: #f60000">*</em>
                </label>
                <argon-input
                  :placeholder="$t('account.installment_number')"
                  is-required
                  v-model="number"
                ></argon-input>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label>
                  {{ $t("account.installment_amount") }}
                  <em style="color: #f60000">*</em>
                </label>
                <argon-input
                  :placeholder="$t('account.installment_amount')"
                  is-required
                  v-model="amount"
                ></argon-input>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label>
                  {{ $t("account.due_date") }}
                  <em style="color: #f60000">*</em>
                </label>
                <argon-input
                  :placeholder="$t('account.due_date')"
                  is-required
                  v-model="due_date"
                  type="date"
                ></argon-input>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div>
                <label>
                  {{ $t("account.installment_label") }}
                  <em style="color: #f60000">*</em>
                </label>
                <argon-input
                  :placeholder="$t('account.installment_label')"
                  is-required
                  v-model="label"
                ></argon-input>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label>
                  {{ $t("account.installment_label") }}
                  <em style="color: #f60000">*</em>
                </label>
                <argon-input
                  :placeholder="$t('account.installment_label')"
                  is-required
                  v-model="formData.payment_config_id"
                ></argon-input>
              </div>
            </div>
          </div>

          <div class="row p-1 mt-5 g-3">
            <div class="col-lg-6">
              <argon-button
                size="lg"
                color="warning"
                full-width
                @click="resetForm"
              >
                {{ $t("account.cancel") }}
              </argon-button>
            </div>
            <div class="col-lg-6">
              <argon-button
                :loading="loading"
                :active="buttonActive || showConfirmation"
                size="lg"
                color="success"
                full-width
                @click="createInstallment"
                :clickEvent="button"
              >
                <span>{{ $t("account.save") }}</span>
              </argon-button>

              <argon-alert color="success" v-if="successMessage">
                <small>{{ successMessage }}</small>
              </argon-alert>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput";
import ArgonButton from "@/components/ArgonButton";
import ArgonAlert from "@/components/ArgonAlert.vue";

import { createInstallment } from "@/utils/services";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "New-Account",
  components: { ArgonButton, ArgonInput, ArgonAlert },
  data() {
    return {
      formData: {
        number: "",
        amount: "",
        due_date: "",
        label: "",
        showNumberInput: true,
      },
      payment_config_id: this.$route.params.configId,

      loading: false,
      failedMessage: "",
      successMessage: "",
      showModal: true,
      buttonActive: false,
      showConfirmation: false,
    };
  },

  mounted() {
    this.formData.payment_config_id = this.$route.params.configId;
    this.loadFormData();
  },
  watch: {
    // Observer les changements dans les propriétés de données et déclencher la fonction d'enregistrement
    formData: {
      handler: "enregistrerFormulaire",
      deep: true,
    },
  },
  methods: {
    hideModal() {
      this.showModal1 = false;
      const message = this.$t("message.installment201");
      this.message = toast.success(message, {
        autoClose: 3000,
      });

      setTimeout(() => {
        this.successMessage = "";
        this.$router.push("/payment-config");
      }, 3000);
    },

    resetForm() {
      this.number = "";
      this.amount = "";
      this.due_date = "";
      this.amount_label = "";
    },

    enregistrerFormulaire() {
      // Sauvegarder les données du formulaire dans le stockage local
      localStorage.setItem("formData", JSON.stringify(this.formData));
    },
    loadFormData() {
      // Charger les données du formulaire depuis le stockage local
      const savedFormData = localStorage.getItem("formData");
      if (savedFormData) {
        this.formData = JSON.parse(savedFormData);
      }
    },

    async createInstallment() {
      try {
        this.loading = true;
        this.button = "disabled";
        this.buttonActive = true;

        let data = {
          number: this.number,
          amount: this.amount,
          due_date: this.due_date,
          label: this.label,
          payment_config_id: this.payment_config_id,
        };

        if (
          this.number === "" ||
          this.number === null ||
          this.amount === "" ||
          this.amount === null
        ) {
          this.showConfirmation = true;
          const message = this.$t("message.requiredfile");
          toast.info(message, {
            autoClose: 3000,
          });
          setTimeout(() => {
            this.showConfirmation = false;
          }, 3000);
          this.button = "";
        } else {
          let res = await createInstallment(data);

          if (!navigator.onLine) {
            const message = this.$t("message.notinternet");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (res.status === 404) {
            const message = this.$t("message.user404");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (this.number > 5) {
            const message = "leo";
            toast.error(message, {
              autoClose: 3000,
            });
            return;
          } else if (res.status === 201) {
            this.showModal1 = true;
          }
        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          // Afficher un message spécifique pour l'erreur 404
          toast.error("User not found. Please check the provided information", {
            autoClose: 3000,
          });
        } else {
          // Afficher un message d'erreur générique pour d'autres erreurs
          toast.error("An error occurred while creating the member", {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
        this.buttonActive = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.student-form {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

.student-form-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info {
  color: #dc3545;
  font-size: 14px;
  margin-left: 5px;
}

.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-active {
  display: block;
  /* Styles spécifiques pour afficher la modale */
}

.modal-content {
  width: 60%;
  margin-left: 30%;
  margin-right: auto;
  text-align: center;
  margin-top: 10%;
  background: none;
}
.modal-content .card {
  height: 40vh;
}
.modal-content .card .card-body p {
  margin-top: 10%;
}
</style>
