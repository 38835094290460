<template>
  <div
    v-show="this.$store.state.layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="`${this.$store.state.darkMode ? 'bg-transparent' : 'bg-success'}`"
  />
  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="`${
      this.$store.state.isRTL
        ? 'me-3 rotate-caret fixed-end'
        : 'fixed-start ms-3'
    }
    ${
      this.$store.state.layout === 'landing'
        ? 'bg-transparent shadow-none'
        : ' '
    } ${this.$store.state.sidebarType}`"
    id="sidenav-main"
  >
    <div class="sidenav-header mb-5">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <router-link class="m-0 navbar-brand" to="/">
        <img
          :src="
            this.$store.state.darkMode ||
            this.$store.state.sidebarType === 'bg-default'
              ? logoWhite
              : logo
          "
          class="navbar-brand-img h-100"
          alt="main_logo"
        />
        <span class="ms-2 font-weight-bold me-2 fs-4">Scolarfleet</span>
      </router-link>

      <spinner
        class="text-center"
        v-if="loading"
        
      >
        <div>
          <span class=""><Spinner></Spinner></span>
        </div>
      </spinner>

      <p
        v-else-if="schoolInfo"
        class=""
        style="
          margin-left: auto;
          margin-right: auto;
          display: block;
          text-align: center;
          margin-left: 15px;
          opacity: 0.8;
        "
      >
        {{ schoolInfo[0] ? schoolInfo[0].name : "" }}
      </p>
    </div>
    <hr class="mt-7 horizontal dark" />
    <sidenav-list :cardBg="custom_class" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-ct-dark.png";
import logoWhite from "@/assets/img/logo-ct.png";
import api_config from "@/config";
import { getConfig } from "@/utils/auth";
import { getSchoolinfo } from "@/utils/services";
import Spinner from "../../components/Spinner.vue";


export default {
  name: "school-index",

  props: {
    serviceName: {
      type: String,
      default: api_config.SERVICE_NAME,
    },
    custom_class: {
      type: String,
    },
    layout: {
      type: String,
    },
  },
  components: {
    SidenavList,
    Spinner,
  },
  data() {
    return {
      logo,
      logoWhite,
      schoolInfo: {},
      loading:false
    };
  },

  methods: {
    async getAppConfig() {
      try {
        let data = await getConfig();
        this.configId = data.id;
        console.log("Valeurs dans EDUCATION_SYSTEM :", data);

        this.training_type = data.training_type;
        this.education_system = data.education_system;
        this.education_grade = data.education_grade;
        this.currency_code = data.currency_code;
        this.welcome = true;

        // Récupérez school_id
        const schoolId = data[0].school_id;

        // Appelez getSchoolInfo avec school_id
        await this.getSchoolInfo(schoolId);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la configuration :",
          error
        );
      }
    },

    async getSchoolInfo(schoolId) {
      try {
         this.loading= true;
        const response = await getSchoolinfo(schoolId);

        if (response.status === 200) {
          this.schoolInfo = response.data;  
          console.log("schoolInfo:", this.schoolInfo);  
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des informations de l'école :",
          error
        );
      } finally{
        this.loading= false;
      }
    },
  },
  created() {
    this.getAppConfig();
  },
};
</script>

<style scoped>
#sidenav-main::-webkit-scrollbar {
  background: transparent;
}
</style>
