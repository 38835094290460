<template>
  <a class="btn mb-0" :class="[getClasses(variant, color, size, fullWidth), { 'disabled': loading || active }]" @click="handleClick">
    <span v-if="loading || active" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <slot />
  </a>
</template>

<script>
export default {
  name: 'argon-button',
  props: {
    color: {
      type: String,
      default: 'info'
    },
    size: {
      type: String,
      default: 'mdlg'
    },
    variant: {
      type: String,
      default: 'fill'
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    clickEvent: {
      type: String,
      default: ''
    }
  },
  methods: {
    getClasses: (variant, color, size, fullWidth) => {
      let colorValue = 0
      let sizeValue = 0
      let fullWidthValue = 0

      // Setting the button variant and color
      if (variant === 'gradient') {
        colorValue = `bg-gradient-${color}`
      } else if (variant === 'outline') {
        colorValue = `btn-outline-${color}`
      } else {
        colorValue = `btn-${color}`
      }

      sizeValue = size ? `btn-${size}` : null

      fullWidthValue = fullWidth ? 'w-100' : null

      return `${colorValue} ${sizeValue} ${fullWidthValue}`
    },
    handleClick() {
      if (!this.loading && !this.active) {
         
        this.$emit('click');
      }
    }
  }
}
</script>
