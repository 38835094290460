<template>
    <div class="modal-dialog">
        <div class="card">
            <div class="modal-content">
                <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
                    <h5 class="modal-title" id="staticBackdropLabel10"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" @click="annuler"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body text-center pt-4 pb-4">
                    <div class="container mt-3 mb-5">
                        <form class="mx-auto " style="width:80%;">
                            <div class="mb-3 text-start ">
                                <label for="matieres " style="font-size:14px" class="form-label ">
                                    Sélectionnez la ou les matières dont les notes sont disponibles <em
                                        style="color: #f60000">*</em>
                                </label>

                                <div class="row mt-3 ps-3">

                                    <div v-for="matiere in matieres" :key="matiere.id" class="form-check  col-3 mt-2 ">
                                        <input class="form-check-input" type="checkbox" :id="`matiere-${matiere.id}`"
                                            :value="matiere.name" v-model="selectedMatieres">
                                        <label class="form-check-label" :for="`matiere-${matiere.id}`">
                                            {{ matiere.name }}
                                        </label>
                                    </div>

                                </div>

                                <!-- Liste de matières avec des checkbox -->

                            </div>

                            <div class="mb-3 text-start ">
                                <label for="sequences " style="font-size:14px" class="form-label ">
                                    Sélectionnez la séquence <em style="color: #f60000">*</em>
                                </label>
                                <div class="row mt-3 ps-3">
                                    <div v-for="sequence in sequences" :key="sequence.id" class="form-check col-3 mt-2">
                                        <input class="form-check-input" type="radio" name="sequence"
                                            :id="`sequence-${sequence.id}`" :value="sequence.name"
                                            v-model="selectedSequence">
                                        <label class="form-check-label" :for="`sequence-${sequence.id}`">
                                            {{ sequence.name }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
                    <argon-button @click="annuler" type="button" class="btn" title="Fermer" data-bs-dismiss="modal"
                        style="width: 200px; height: 50px; padding-top: 15px; background: none; border: 1px solid rgba(255, 255, 255, 0.4);">
                        <span>
                            {{ $t("button.cancel") }}
                        </span>
                    </argon-button>
                    <argon-button type="button" class="btn me-7" color="success" variant="gradient"
                        style="width: 200px; height: 50px; padding-top: 15px" :disabled="!isChoiceMade">
                        {{ $t("button.send") }}
                    </argon-button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import ArgonButton from "@/components/ArgonButton";

export default {
    name: 'Note-Modal',
    components: {
        ArgonButton
    },
    props: {

    },
    data() {
        return {



            loading: false,


            // Liste des matières disponibles
            matieres: [
                { id: 1, name: "Toute les matieres" },
                { id: 2, name: "Mathématiques" },
                { id: 3, name: "Physique" },
                { id: 4, name: "Chimie" },
                { id: 5, name: "Histoire" },
                { id: 6, name: "Géographie" }
            ],
            sequences: [
                { id: 1, name: "Sequence 1" },
                { id: 2, name: "Sequence 2" },
                { id: 3, name: "Sequence 3" },
                { id: 4, name: "Sequence 4" },
                { id: 5, name: "Sequence 5" },
                { id: 6, name: "Sequence 6" }
            ],
            selectedSequence: '',

            // Matières sélectionnées
            selectedMatieres: []
        };
    },
    beforeMount() {
        this.classroomId = this.$route.params.classId;
    },
    methods: {

        annuler() {
            this.selectedMatieres = [];
            this.selectedSequence = '';
        }

    }
}
</script>


<style>
#pending,
#settled {
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    border: 1px solid green;
}

#alert-modal {
    border: 1px solid #83454d;
    color: #83454d;
    background: rgba(0, 0, 0, 0.6)
}
</style>