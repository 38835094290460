import decode from 'jwt-decode'
import axios from 'axios'
import api_config from "@/config";

const REST_ENDPOINT = api_config.API_ENDPOINT

const AUTH_TOKEN_KEY = 'authToken'

export async function loginUser(username, password) {
    let data = {}
    try {
        let res = await axios({
            url: REST_ENDPOINT + '/auth/token/',
            method: 'POST',
            data: {
                username: username,
                password: password
            }
        })
        if (res.status === 200 && res.data.token) {
            setAuthToken(res.data.token)
            const token = getAuthToken()
            if (token){
                data.status = res.status
                data.message = 'Successful login'
            }else{
                data.message = 'Retry connexion'
            }
        }
    } catch (err) {
        data.status = err.response.status
        data.message = err.response.data['non_field_errors'][0]
        if (process.env.NODE_ENV !== 'production') {
            console.log(err.response.data['non_field_errors'][0])
            console.error('Caught an error during login:', err)
        }
    }
    return data
}

 


 


export async function getConfig() {
    let response;
    try {
      const res = await axios({

        url: `${REST_ENDPOINT}/api/config/`,
        method: "GET",
      });

      console.log("res.data",res.data);
  
      if (res.status === 200) {
        response = res.data;
      }
    } catch (err) {
      response = err.response;
      console.error('Caught an error during get config:', err)
    }
    return response;
  }

  

export function logoutUser() {
    clearAuthToken()
    window.location.reload()
}

export function setAuthToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    localStorage.setItem(AUTH_TOKEN_KEY, token)
}

export function getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY)
}

export function clearAuthToken() {
    axios.defaults.headers.common['Authorization'] = ''
    localStorage.removeItem(AUTH_TOKEN_KEY)
}

export function isLoggedIn() {
    let authToken = getAuthToken()
    return !!authToken && !isTokenExpired(authToken)
}

export function getUserInfo() {
    if (isLoggedIn()) {
        return decode(getAuthToken())
    }
}

function getTokenExpirationDate(encodedToken) {
    let token = decode(encodedToken)
    if (!token.exp) {
        return null
    }

    let date = new Date(0)
    date.setUTCSeconds(token.exp)

    return date
}

function isTokenExpired(token) {
    let expirationDate = getTokenExpirationDate(token)
    return expirationDate < new Date()
}



 
