<template>
  <div class="card">
    <div class="pb-0 card-header mb-0">
      <div class="row">
        <div class="col-md-6">
          <h6>{{ title }}</h6>
          <p class="text-sm">
            <i class="fa fa-arrow-up text-success"></i>
            <span class="font-weight-bold">{{ detail1 }}</span>
            {{ detail2 }}
          </p>
        </div>
        <div class="col-md-6 text-end">
             <ul class=" liste">
                 <li> <span class="send mx-1 "></span> Devoirs envoyés</li>
                 <li style="transform:translateX(-18px)"> <span class="receive mx-1"></span>Devoirs reçus</li>
             </ul>
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas id="chart-line" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "gradient-line-chart",

  props: {
    title: {
      type: String,
      default: "Devoirs sur Eska",
    },
    detail1: {
      type: String,
      default: "75% réalisés",
    },
    detail2: {
      type: String,
      default: "",
    },
  },

  mounted() {
    var ctx1 = document.getElementById("chart-line").getContext("2d");

    var gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);

    gradientStroke1.addColorStop(1, "rgba(94, 114, 228, 0.2)");
    gradientStroke1.addColorStop(0.2, "rgba(94, 114, 228, 0.0)");
    gradientStroke1.addColorStop(0, "rgba(94, 114, 228, 0)");
    new Chart(ctx1, {
      type: "line",
      data: {
        labels: [
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "Jan",
          "Feb",
          "Mar",
          "Apri",
          "Jun",
        ],
        datasets: [
          {
            label: "Devoirs envoyés",
            tension: 0.4,
            pointRadius: 0,
            borderColor: "#f3623e",
            backgroundColor: gradientStroke1,
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 3,
            fill: true,
            data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
            maxBarThickness: 6,
          },
          {
            label: "Devoirs reçus",
            tension: 0.4,
            pointRadius: 0,
            borderColor: "#60ce8a",
            backgroundColor: gradientStroke1,
            borderWidth: 3,
            fill: true,
            data: [200, 150, 300, 250, 400, 180, 100, 220, 300], // Exemple de données
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#fbfbfb",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#ccc",
              padding: 20,
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    });
  },
};
</script>

<style>

.liste{
  
  margin-left:auto;
  list-style: none
}
.liste li{
  display: flex;
  font-size:14px;
  opacity:0.6;
  font-weight: 400;
  width:max-content;
  margin-left:auto;
  margin-right: 5%;
}
.liste .send{
  height: 20px;
  width:20px;
  content:"";
  display: block;
  background:#f3623e ;
}
.liste .receive{
  height: 20px;
  width:20px;
  content:"";
  display: block;
  background:#60ce8a;
}

</style>
